import { createSlice } from "@reduxjs/toolkit";

interface IutilsReducer {
  popups: {
    improve: boolean;
    mys_incomplete: boolean;
  };
  mys_details: {
    recent_exit_point: null | 1 | 2 | 3 | 4;
    recent_grade_id: null | number;
    recent_grade_name: null | number;
    trade_type: null | "OFFER" | "ORDER" | "HYBRID";
    offer_id: null | number;
    order_id: null | number;
  };
}

const mys_details = {
  recent_exit_point: null,
  recent_grade_id: null,
  recent_grade_name: null,
  trade_type: null,
  offer_id: null,
  order_id: null,
};
const initialState: IutilsReducer = {
  popups: {
    improve: false,
    mys_incomplete: false,
  },
  mys_details,
};

export const UtilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    updateImprovePopup: (state, action) => {
      state.popups.improve = action.payload;
    },
    updateMysIncompletePopup: (state, action) => {
      state.popups.mys_incomplete = action.payload;
    },
    updateRecentGrade: (state, action) => {
      const { id = null, name = null } = action.payload;
      state.mys_details.recent_grade_id = id;
      state.mys_details.recent_grade_name = name;
    },
    updateExitPoint: (state, action) => {
      state.mys_details.recent_exit_point = action.payload;
    },
    updateTradeType: (state, action) => {
      state.mys_details.trade_type = action.payload;
    },
    updateUtilsMysDetails: (state, action) => {
      state.mys_details = { ...state.mys_details, ...action.payload };
    },
    resetUtilsMysDetails: (state) => {
      state.mys_details = { ...mys_details };
    },
    resetUtilsReducer: () => initialState,
  },
});

export const {
  updateImprovePopup,
  updateMysIncompletePopup,
  updateRecentGrade,
  updateExitPoint,
  resetUtilsReducer,
  updateUtilsMysDetails,
  updateTradeType,
  resetUtilsMysDetails,
} = UtilsSlice.actions;
export default UtilsSlice.reducer;
