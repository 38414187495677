import React from "react";
import { useAppSelector } from "../hooks/reduxHooks";
import { Navigate } from "react-router-dom";
import { ModalLoader } from "../components/Loader/Loader";

const IndexPage = () => {
  const { offers_list_home, offers_list_home_loaded } = useAppSelector(
    (state) => state.OffersReducer
  );
  let indexpage = "index";
  if (offers_list_home.length === 0) {
    indexpage = "grades";
  }
  if (!offers_list_home_loaded)
    return (
      <>
        <ModalLoader show={false} />
      </>
    );

  return <Navigate to={indexpage} />;
};
export default IndexPage;
