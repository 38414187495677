import { createSlice } from "@reduxjs/toolkit";
import { DELETE, GET, POST } from "../../services/api";
import {
  IMYSdeliverySchedule,
  IMYSdeliveryScheduleCustom,
  IMYSformOneValue,
  IMYSpickupaddressList,
  IMYSportList,
  IMYStermialListData,
  ImakeyoursaleDetails,
  ImakeyoursaleReducer,
  ImysSaleResponse,
  ImysTradeDetails,
} from "../../interfaces/ImakeYourSale";
import {
  MYSImageListForDraft,
  MYSLoadTermsApiData,
  MYSformOneInitialValues,
  MYSgetCachedKey,
  MYSinitialShowDom,
  MYSsaleFlowApiType,
  MYSsaleFlowType,
  MYSshipmentTypes,
  MYSweightFormatter,
  mysGetAddressFormatter,
} from "../../pages/MakeYourSale/MYSconst";
import dayjs from "dayjs";

const initialState: ImakeyoursaleReducer = {
  loader: true,
  pricing_details: {} as ImakeyoursaleDetails,
  image_upload_complete: true,
  show_dom: {
    enter_weight: false,
    sell_option: false,
    freight_option: false,
    material_type: false,
    load_terms: false,
    weight_load: false,
    port: false,
    pickup: false,
    delivery: false,
    image: false,
    notes: false,
    new_pickup: false,
  },
  show_chat_creation: false,
  decision_based_value: {
    sale_flow: "",
    sell_option: "",
    freight_option: "",
    load_terms: "",
    load_count: "",
    min_weight: "",
    unit: "",
    port: "",
  },
  weight_per_load: [],
  pickup_address_list: [],
  pickup_address_list_formatter: {},
  pickup_address_list_map: {},
  form_one: {} as IMYSformOneValue,
  form_one_response: {} as ImysTradeDetails,
  form_two: {
    flow_type: "ORDER",
    form_type: null,
    propose_price_amount: null,
  },
  form_three: {
    delivery_schedule: [],
    grade_images: [],
    notes: "",
  },
  port_list: [],
  port_terminal_map: [],
  show_mys_draft: false,
  port_name_with_terminal_id: {},
  terminal_list: {},
  sale_info: {} as ImysSaleResponse,
  recent_dialog_id: null,
  disable_submit: false,
};

export const makeyoursaleSlice = createSlice({
  name: "makeyoursale_reducer",
  initialState,
  reducers: {
    setImageUploadComplete: (state, action) => {
      state.image_upload_complete = action.payload;
    },
    updatePricingDetailsShowFlow: (state, action) => {
      state.show_dom = {
        ...state.show_dom,
        ...action.payload,
      };
    },
    toggleMYSChatCreation: (state, action) => {
      state.show_chat_creation = action.payload;
    },
    updateMYSDecisionBased: (state, action) => {
      state.decision_based_value = {
        ...state.decision_based_value,
        ...action.payload,
      };
    },
    updateFormTwoFlowType: (state, action) => {
      state.form_two.flow_type = action.payload;
    },
    updateFormOneResponse: (state, action) => {
      state.form_one_response = action.payload;
    },
    updateMYSFormOne: (state, action) => {
      state.form_one = action.payload;
    },
    updateMYSFormOneLoads: (state, action) => {
      state.form_one.min_weight_load = action.payload;
    },
    updateMYSFormTwo: (state, action) => {
      state.form_two = { ...state.form_two, ...action.payload };
    },
    updateMYSFormThree: (state, action) => {
      state.form_three = { ...state.form_three, ...action.payload };
    },
    updateSaleInfo: (state, action) => {
      state.sale_info = action.payload;
    },
    toggleMYSDraft: (state, action) => {
      state.show_mys_draft = action.payload;
    },
    updateDailogId: (state, action) => {
      state.recent_dialog_id = action.payload;
    },
    updateMYSLoader: (state, action) => {
      state.loader = action.payload;
    },
    resetMYSforms: (state) => {
      state.show_dom = { ...MYSinitialShowDom };
      state.form_one = {} as IMYSformOneValue;
      state.form_one_response = {} as ImysTradeDetails;
      state.form_two = {
        flow_type: "ORDER",
        form_type: null,
        propose_price_amount: null,
      };
      state.form_three = {
        delivery_schedule: [],
        grade_images: [],
        notes: "",
      };
    },
    updateMYSDisableBtn: (state, action) => {
      state.disable_submit = action.payload;
    },
    resetMakeYourSaleReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("makeyoursale/details", "").fulfilled,
      (state, action) => {
        state.pricing_details = action.payload.data;
        state.loader = false;
      }
    );
    builder.addCase(GET("makeyoursale/details", "").pending, (state) => {
      state.loader = true;
    });

    builder.addCase(POST("addNewPickupLocation", "").pending, (state) => {
      state.loader = true;
    });
    builder.addCase(POST("makeyoursale/form_one", "").pending, (state) => {
      state.loader = true;
    });

    builder.addCase(POST("makeyoursale/final-submit", "").pending, (state) => {
      state.loader = true;
    });
    builder.addCase(
      POST("makeyoursale/final-submit", "").fulfilled,
      (state, action) => {
        state.sale_info = action.payload.data;
      }
    );
    builder.addCase(POST("makeyoursale/final-submit", "").rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(
      POST("makeyoursale/form_one", "").fulfilled,
      (state, action) => {
        state.form_one_response = action.payload.data.trade_details;

        state.loader = false;
      }
    );
    builder.addCase(
      POST("addNewPickupLocation", "").fulfilled,
      (state, action) => {
        state.pickup_address_list = [
          ...state.pickup_address_list,
          action.payload.data,
        ];

        state.pickup_address_list_formatter[action.payload.data.id] =
          mysGetAddressFormatter(action.payload.data);
        state.pickup_address_list_map[action.payload.data.id] =
          action.payload.data;
        state.loader = false;
      }
    );

    builder.addCase(
      GET("makeyoursale/pickupLocationList", "").fulfilled,
      (state, action) => {
        state.pickup_address_list = action.payload.data;
        state.pickup_address_list_formatter = action.payload.data.reduce(
          (
            prev: {
              [id: number]: string;
            },
            el: IMYSpickupaddressList
          ) => {
            prev[el.id] = mysGetAddressFormatter(el);
            return prev;
          },
          {}
        );
        state.pickup_address_list_map = action.payload.data.reduce(
          (
            prev: {
              [id: number]: IMYSpickupaddressList;
            },
            el: IMYSpickupaddressList
          ) => {
            prev[el.id] = el;
            return prev;
          },
          {}
        );
      }
    );
    builder.addCase(POST("grades/create", "").pending, (state) => {
      state.loader = true;
    });
    builder.addCase(DELETE("makeyoursale/removefav", "").pending, (state) => {
      state.loader = true;
    });
    builder.addCase(DELETE("makeyoursale/removefav", "").fulfilled, (state) => {
      state.pricing_details.is_preferred = false;
      state.loader = false;
    });
    builder.addCase(POST("makeyoursale/makefav", "").pending, (state) => {
      state.loader = true;
    });
    builder.addCase(POST("makeyoursale/makefav", "").fulfilled, (state) => {
      state.pricing_details.is_preferred = true;
      state.loader = false;
    });
    builder.addCase(POST("makeyoursale/draft", "").pending, (state) => {
      state.loader = true;
    });

    builder.addCase(
      GET("makeyoursale/ports", "").fulfilled,
      (state, action) => {
        state.port_list = action.payload.data;
        state.terminal_list = action.payload.data.reduce(
          (prev: IMYStermialListData, el: IMYSportList) => {
            state.port_name_with_terminal_id[el.terminals[0].id] = el.name;
            state.port_terminal_map[el.id] = el.terminals[0].id;
            prev[el.id] = el.terminals[0];
            return prev;
          },
          {}
        );
      }
    );

    builder.addCase(GET("mysDraft/details", "").pending, (state) => {
      state.loader = true;
    });
    builder.addCase(GET("mysDraft/details", "").rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(GET("mysDraft/details", "").fulfilled, (state, action) => {
      const draftData = action.payload.data;
      let form_one: IMYSformOneValue = { ...MYSformOneInitialValues };

      if (draftData.sale_flow_type) {
        form_one.sale_type = MYSsaleFlowApiType[draftData.sale_flow_type];
      }

      if (draftData.material_packing_type)
        form_one.material_type = draftData.material_packing_type;

      if (draftData.sell_type)
        form_one.sell_option = draftData.sell_type.toLowerCase();

      if (draftData.shipment_type)
        form_one.freight_option = draftData.shipment_type;

      if (draftData.load_info) {
        form_one.load_terms = MYSLoadTermsApiData[draftData.load_info];
      }

      if (draftData.shipping_location_id) form_one.port = null;

      if (draftData.min_weight)
        form_one.min_weight = MYSweightFormatter("" + draftData.min_weight);

      if (draftData.supplier_weight_unit) form_one.min_weight_unit = "lb";

      if (draftData.shipping_location.preferred_location_id) {
        if (
          draftData.shipment_type &&
          [MYSshipmentTypes.FOB_DOMESTIC, MYSshipmentTypes.FOB_EXPORT].includes(
            draftData.shipment_type
          )
        ) {
          form_one.pickup_address =
            draftData.shipping_location.preferred_location_id;
        }
      }

      if (
        draftData.shipment_type &&
        MYSshipmentTypes.FAS_EXPORT === draftData.shipment_type
      ) {
        if (draftData?.shipping_location?.port_id)
          form_one.port = draftData.shipping_location.port_id;
      }

      if (draftData.sale_flow_type === MYSsaleFlowType.WEIGHT_PER_LOAD) {
        if (draftData.loads) form_one.min_weight_load = draftData.loads;
      }
      if (
        draftData.shipping_location &&
        draftData?.shipping_location?.preferred_location_id === null &&
        draftData?.shipping_location?.port_id === null
      ) {
        const { shipping_location } = draftData;
        if (shipping_location.zip_code && shipping_location.zip_code !== "")
          form_one.new_zip_code = shipping_location.zip_code;
        if (shipping_location.state_id && shipping_location.state_id !== "")
          form_one.new_state = shipping_location.state_id;
        if (shipping_location.address)
          form_one.new_address = shipping_location.address;
        if (shipping_location.city) form_one.new_city = shipping_location.city;
      }
      let delivery_schedule: any = null;
      if (draftData?.shipping_location?.shipping_schedule) {
        if (draftData?.shipping_location?.shipping_schedule.length > 0) {
          delivery_schedule =
            draftData?.shipping_location?.shipping_schedule.reduce(
              (
                prev: IMYSdeliveryScheduleCustom[],
                el: IMYSdeliverySchedule
              ) => {
                const currentDate = dayjs();
                const draftDate = dayjs(el.shipping_day);
                if (draftDate.diff(currentDate, "day") >= 0) {
                  prev.push({
                    delivery_loads: el.loads,
                    drop_off_date: el.shipping_day,
                  });
                }

                return prev;
              },
              []
            );
        }
      }
      let form_three = {
        delivery_schedule: delivery_schedule,
        grade_images: MYSImageListForDraft(draftData.grade_images),
        notes: draftData.additional_info,
      };
      state.form_one = form_one;
      state.form_three = form_three;

      localStorage.setItem(
        MYSgetCachedKey(
          1,
          draftData.grade_id,
          draftData.preferred_location.zip_code
        ),
        JSON.stringify(form_one)
      );
      localStorage.setItem(
        MYSgetCachedKey(
          3,
          draftData.grade_id,
          draftData.preferred_location.zip_code
        ),
        JSON.stringify(form_three)
      );
    });
  },
});

export default makeyoursaleSlice.reducer;
export const {
  setImageUploadComplete,
  updatePricingDetailsShowFlow,
  resetMakeYourSaleReducer,
  toggleMYSChatCreation,
  updateMYSFormOne,
  updateMYSDecisionBased,
  updateMYSFormTwo,
  updateFormOneResponse,
  updateFormTwoFlowType,
  updateMYSFormThree,
  updateSaleInfo,
  toggleMYSDraft,
  updateDailogId,
  updateMYSLoader,
  resetMYSforms,
  updateMYSDisableBtn,
  updateMYSFormOneLoads,
} = makeyoursaleSlice.actions;
