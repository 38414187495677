import { Button, Modal } from "antd";
import React from "react";

import styled from "styled-components";
import { fontMedium14, fontNormal14 } from "../../utils/styleConsts";
import useScreenType from "../../hooks/useScreenType";
import { SCREEN_TYPE } from "../../utils/constant";
import HubbitDrawer from "../HubbitDrawer/HubbitDrawer";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { updateShowApprovedDetails } from "../../store/features/OffersActions.action";

const Wrapper = styled.div`
  p {
    ${fontNormal14}
    color:var(--NEUTRAL-4);
  }
  .desc {
    margin: 1.6rem 0 2.5rem;
  }
  .action-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    button {
      min-height: 4.4rem;
      min-width: 18rem;
      ${fontMedium14}
    }
  }
`;

const beforeApproveCopyText = {
  desc: "Please click below to approve the price proposed by your GPR Trader.",
  sub_desc: "Once approved, you'll receive a PO within one hour via email.",
};
const approveCopyText = {
  desc: "You will receive a PO via email.",
  sub_desc:
    "Please check spam folder if you do not see the email in your inbox within 3 hours or contact your Trader",
};
const ApprovePricePopup = ({
  open = false,
  onPopupClose = () => {},
  title = "",
  onApprove = () => {},
}) => {
  const screenType = useScreenType();
  const dispatch = useAppDispatch();
  const { show_approved_popup_detail } = useAppSelector(
    (state) => state.OffersActionsReducer
  );
  const desktop =
    screenType === SCREEN_TYPE.DESKTOP || screenType === SCREEN_TYPE.LAPTOP;
  const copyText = show_approved_popup_detail
    ? approveCopyText
    : beforeApproveCopyText;

  const onApproveOffer = () => {
    onApprove();
  };
  const onClose = () => {
    dispatch(updateShowApprovedDetails(false));
    onPopupClose();
  };
  const content = (
    <>
      <Wrapper>
        <p className="desc">{copyText.desc}</p>
        <p className="sub-desc">{copyText.sub_desc}</p>
        {!show_approved_popup_detail && (
          <div className="action-btn">
            <Button type="primary" onClick={onApproveOffer} block={!desktop}>
              Approve Price
            </Button>
          </div>
        )}
      </Wrapper>
    </>
  );

  const mobileView = (
    <HubbitDrawer
      title={title}
      open={open}
      height={"auto"}
      onClose={onClose}
      showchatbot={false}
    >
      {content}
    </HubbitDrawer>
  );

  const desktopView = (
    <Modal
      centered
      title={title}
      open={open}
      width={580}
      footer={[]}
      onCancel={onClose}
    >
      {content}
    </Modal>
  );

  return desktop ? desktopView : mobileView;
};

export default ApprovePricePopup;
