import styled from "styled-components";
import { status } from "../../utils/constant";

const TagWrapper = styled.div<{ name: any; flow?: string }>`
  border-radius: 1.6rem;
  padding: 0.6rem 1.5rem;
  width: fit-content;
  height: 2.8rem;
  font-size: ${(props) => (props.flow === "user" ? "1.4rem" : `1rem`)};
  text-transform: capitalize;
  display: flex;
  white-space: pre;
  justify-content: center;
  color: ${(props) =>
    props.flow !== "user"
      ? `var(--TAG-TEXTCOLOR-${props.name})`
      : `var(--WHITE);`};
  background-color: ${(props) => `var(--TAG-${props.name})`};
  font-family: "GothamMedium";
  font-style: normal;
  font-weight: 350;
  line-height: 140%;
  align-items: center;
`;

const ordersMap = new Map([
  ["PAYMENT IN PROGRESS", "LIGHT-GREEN"],
  ["DELIVERY IN PROGRESS", "LIGHT-GREY"],
  ["CANCELED", "GREY"],
  ["COMPLETED", "GREEN"],
  ["NEW", "GREEN"],
  ["PAYMENT PROCESSED", "GREY"],
  ["DELIVERED", "GREEN"],
]);

const offersMap = new Map([
  ["Waiting for Trader", "LIGHT-BLUE"],
  ["Waiting for Supplier", "RED"],
  ["Declined", "GREY"],
  ["Canceled", "GREY"],
  ["Expired", "GREY"],
  ["Accepted", "GREEN"],
  ["Action Needed", "RED"],
]);

const Tag = (props: { content: string; flow: string }) => {
  const { flow, content } = props;
  switch (flow) {
    case "orders":
      return (
        <TagWrapper name={ordersMap.get(content.toUpperCase())} flow={flow}>
          {content.replace(/_/g, " ").toUpperCase()}
        </TagWrapper>
      );
    case "offers":
      return (
        <>
          <TagWrapper name={offersMap.get(content)} flow={flow}>
            {content === status.waiting_for_supplier
              ? status.action_needed.replace(/_/g, " ").toUpperCase()
              : content.replace(/_/g, " ").toUpperCase()}
          </TagWrapper>
        </>
      );
    default:
      return <></>;
  }
};
export default Tag;
