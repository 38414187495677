import styled from "styled-components";

export const OrderDetailsWrapper = styled.section`
  position: relative;
  font-family: Gotham;
  display: grid;
  
   &.error {
    grid-template-columns: 1fr;
  }
  @media (min-width: 787px) {

    grid-template-columns: 75% auto;
    .right-pane {
      border-left: 1px solid var(--NEUTRAL-2);
      padding-left: 20px;
    }
    .order_info {
      grid-template-columns: 50% 50%;
    }
    .actions {
      flex-direction: column;
    }
    .offerchatblockresponsive {
      display: none;
    }
  }
  .offerchatblockresponsive {
    margin-top: -2rem;
  }
  gap: 2rem;
  .order_info {
    display: grid;
    border-bottom: 1px solid var(--NEUTRAL-2);
    padding: 2rem 0;
    .chat_block {
      display: flex;
      gap: 2rem;
    }
  }
  @media (max-width: 786px) {
    padding: 1.6rem;
    .right-pane {
      padding-top: 1rem;
      border-top: 1px solid var(--NEUTRAL-2);
    }
    .actions {
      flex-direction: column-reverse;
    }
    .offerchatblock {
      display: none;
    }
  }
  .actions {
    display: flex;
    gap: 2rem;
    overflow-y: auto;
    @media(max-width:786px){
      margin-bottom:2rem;
    }
  }
  .modalheight {
    height: 24.8rem;
  }
}
.grade-to-sell-btn {
  width: 100%;
  position: fixed;
  background-color: var(--WHITE);
  border-top: 1px solid var(--NEUTRAL-2);
  text-align: center;
  padding: 1.2rem 1.5rem;
  bottom: 0;
  left: 0;
  z-index: 10;

  button {
    font-family: GothamMedium;
    height: auto;
    padding: 1.2rem 2rem;
    box-shadow: none;
    border-radius: 1rem;
    width: 100%;
    font-size: 1.6rem;
  }
}
`;
