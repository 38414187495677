import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/api";
import { ApiURL, SHIPMENT_TYPE } from "../../utils/constant";
import {
  IOfferOrderForm,
  IOfferOrderPayload,
  IOfferOrderStaticData,
  IOfferOrderWeightPrice,
  IUnitPriceChangeParameters,
  IUnitPriceUpdateMessage,
} from "../../interfaces/OfferOrderForm";
import { UploadFile } from "antd";
import { MYSImageListForDraft } from "../../pages/MakeYourSale/MYSconst";

const initialState: {
  offerOrderForm: IOfferOrderForm;
  offerOrderStaticData: IOfferOrderStaticData;
  offerOrderTotalPrice: IOfferOrderWeightPrice;
  offerOrderPayload: IOfferOrderPayload;
  unitPriceChangeParameters: IUnitPriceChangeParameters;
  submittedForReview: boolean;
  filledFormValues: any;
  selectedLocationDropDown?: string | undefined;
  selectedTerminalDropDown?: string | undefined;
  unitPriceUpdateMessage?: IUnitPriceUpdateMessage;
  newAddressAdded: boolean;
  noPriceModal: boolean;
  newDestinationId: boolean;
  counterOfferChanges: {
    proposed_price: number;
    notes: string;
  };
  offerOrderSubmitted: boolean;
  counterOfferPayload: {
    price: number;
    offer_id: number;
  };
  pathFrom: string;
  loads_input: number;
  selectedPickUpCity: string;
  imageList: UploadFile[] | [];
  isNewAddress: boolean;
  newAddressState: string;
  uploadComplete: boolean;
  newAddressSaved: boolean;
} = {
  offerOrderForm: {},
  offerOrderStaticData: {
    grade_id: 0,
    grade_name: "",
    grade_location: "",
    shipment_type: "",
    min_weight: 0,
    unit_price: 0,
    unit: "",
    loads_left: 0,
    grade_location_id: 0,
    draft_id: 0,
    isMetal: false,
    cityName: "",
    cityNameExtended: "",
    isPricedGrade: "No",
  },
  offerOrderTotalPrice: {
    total_price: 0,
    total_weight: 0,
  },
  offerOrderPayload: {
    grade_id: 0,
    shipment_type: "",
    min_weight: 0,
    unit_price: 0,
    total_price: 0,
    load_info: "",
    notes: "",
    loads: 0,
    region_id: 1,
    terminal_id: 0,
    grade_images: [],
    shipping_schedule: [],
    is_draft: false,
    shipping_location: {
      address: "",
      address_2: null,
      address_3: null,
      city: "",
      state_id: 0,
      zip_code: 0,
    },
    preferred_pickup_location_id: 0,
    price: 0,
    destination_id: null,
  },
  unitPriceChangeParameters: {
    unit_zip_code: 0,
    unit_port_id: 0,
    unit_destination_id: null,
  },
  submittedForReview: false,
  filledFormValues: {},
  selectedLocationDropDown: "",
  selectedTerminalDropDown: "",
  unitPriceUpdateMessage: {
    from: "",
    to: "",
  },
  newAddressAdded: false,
  newDestinationId: false,
  noPriceModal: false,
  counterOfferChanges: {
    proposed_price: 0,
    notes: "",
  },
  offerOrderSubmitted: false,
  counterOfferPayload: {
    price: 0,
    offer_id: 0,
  },
  pathFrom: "",
  loads_input: 0,
  selectedPickUpCity: "",
  imageList: [],
  isNewAddress: false,
  newAddressState: "",
  uploadComplete: true,
  newAddressSaved: false,
};

const shippingMap = new Map([
  ["FOB_EXPORT", SHIPMENT_TYPE.FOB],
  ["FAS_EXPORT", SHIPMENT_TYPE.FAS],
  ["FOB_DOMESTIC", SHIPMENT_TYPE.FOBD],
  ["FAS_DOMESTIC", SHIPMENT_TYPE.DAP],
]);
export const CreateOfferOrderFormSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setWeightPrice: (state, action: PayloadAction<IOfferOrderWeightPrice>) => {
      state.offerOrderTotalPrice = action.payload;
    },
    setImageList: (state, action: PayloadAction<UploadFile>) => {
      const currentData: UploadFile[] = state.imageList;
      currentData.push(action.payload);
      state.imageList = currentData;
    },
    setImageListFromLocalStorage: (
      state,
      action: PayloadAction<UploadFile[]>
    ) => {
      state.imageList = action.payload;
    },
    setImageListArray: (state, action: PayloadAction<UploadFile[]>) => {
      state.imageList = action.payload;
    },
    resetImageList: (state) => {
      state.imageList = [];
    },

    setOfferOrderFormStaticData: (
      state,
      action: PayloadAction<IOfferOrderStaticData>
    ) => {
      state.offerOrderStaticData = action.payload;
    },
    setOrderFormStaticDataForDraft: (state, action: PayloadAction<any>) => {
      const d = action.payload;
      const {
        min_weight,
        shipment_type,
        unit_price,
        preferred_location,
        grade,
      } = d;
      const details: IOfferOrderStaticData = {
        min_weight,
        shipment_type:
          shipment_type === "FAS Domestic" ? SHIPMENT_TYPE.DAP : shipment_type,
        unit: grade?.unit,
        unit_price,
        grade_location: preferred_location?.city,
        grade_location_id: preferred_location?.id,
        grade_name: grade?.name,
        grade_id: grade?.id,
        draft_id: d.id,
        selectedLocationZip: preferred_location?.zip_code,
        isMetal: grade?.category_name === "Metals",
      };
      state.offerOrderStaticData = {
        ...state.offerOrderStaticData,
        ...details,
      };
    },
    setOfferFormStaticDataForDraft: (state, action: PayloadAction<any>) => {
      const d = action.payload;
      const {
        id,
        min_weight,
        shipment_type,
        preferred_location,
        grade,
        offer_details,
      } = d;
      const details: IOfferOrderStaticData = {
        min_weight,
        shipment_type: shippingMap.get(shipment_type) || "",
        unit: grade?.unit,
        unit_price: offer_details?.price,
        price: offer_details?.price,
        grade_location: preferred_location?.city,
        grade_location_id: preferred_location?.id,
        grade_name: grade?.name,
        grade_id: grade?.id,
        draft_id: id,
        selectedLocationZip: preferred_location?.zip_code,
        isMetal: grade?.category_name === "Metals",
      };
      state.offerOrderStaticData = {
        ...state.offerOrderStaticData,
        ...details,
      };
    },
    setOfferOrderFormLocation: (state, action: PayloadAction<any>) => {
      const d = action.payload;
      const details: IOfferOrderStaticData = {
        ...state.offerOrderStaticData,
        grade_location: d?.city,
        grade_location_id: d?.id,
        selectedLocationZip: d?.zip_code,
      };
      state.offerOrderStaticData = details;
    },
    setOfferOrderForm: (state, action: PayloadAction<IOfferOrderForm>) => {
      state.offerOrderForm = action.payload;
    },
    setFormShipmentType: (state, action: PayloadAction<any>) => {
      const d = action.payload;
      const details: IOfferOrderStaticData = {
        ...state.offerOrderStaticData,
        unit_price: d?.price_details?.price,
        shipment_type: d.label,
        loads_left: d?.price_details?.loads_left,
      };
      state.offerOrderStaticData = details;
    },
    setUnitPriceChangeParameters: (
      state,
      action: PayloadAction<IUnitPriceChangeParameters>
    ) => {
      state.unitPriceChangeParameters = {
        ...state.unitPriceChangeParameters,
        ...action.payload,
      };
    },

    setOfferOrderPayload: (
      state,
      action: PayloadAction<IOfferOrderPayload>
    ) => {
      state.offerOrderPayload = action.payload;
    },

    setSubmittedForReview: (state, action: PayloadAction<boolean>) => {
      state.submittedForReview = action.payload;
    },
    setFilledFormValues: (state, action: PayloadAction<any>) => {
      state.filledFormValues = action.payload;
    },
    setUnitPriceUpdateMessage: (
      state,
      action: PayloadAction<IUnitPriceUpdateMessage>
    ) => {
      state.unitPriceUpdateMessage = action.payload;
    },
    setNewAddressAdded: (state, action: PayloadAction<boolean>) => {
      state.newAddressAdded = action.payload;
    },
    setNewAddressSaved: (state, action: PayloadAction<boolean>) => {
      state.newAddressSaved = action.payload;
    },
    setNoPriceModal: (state, action: PayloadAction<boolean>) => {
      state.noPriceModal = action.payload;
    },
    setCounterOfferChanges: (
      state,
      action: PayloadAction<{ proposed_price: number; notes: string }>
    ) => {
      state.counterOfferChanges = action.payload;
    },
    setOfferOrderSubmitted: (state, action: PayloadAction<boolean>) => {
      state.offerOrderSubmitted = action.payload;
    },
    setCounterOfferPayload: (
      state,
      action: PayloadAction<{ price: number; offer_id: number; notes: string }>
    ) => {
      state.counterOfferPayload = action.payload;
    },
    setPathFrom: (state, action: PayloadAction<string>) => {
      state.pathFrom = action.payload;
    },
    setLoadsInput: (state, action: PayloadAction<number>) => {
      state.loads_input = action.payload;
    },
    setSelectedPickUpCity: (state, action: PayloadAction<string>) => {
      state.selectedPickUpCity = action.payload;
    },
    setIsMetal: (state, action: PayloadAction<boolean>) => {
      state.offerOrderStaticData.isMetal = action.payload;
    },
    resetOfferOrderFormStaticData: (state) => {
      state.offerOrderStaticData = initialState.offerOrderStaticData;
    },
    setNewDestinationId: (state, action: PayloadAction<boolean>) => {
      state.newDestinationId = action.payload;
    },
    setIsNewAddress: (state, action: PayloadAction<boolean>) => {
      state.isNewAddress = action.payload;
    },
    setNewAddressState: (state, action: PayloadAction<string>) => {
      state.newAddressState = action.payload;
    },
    setUploadComplete: (state, action: PayloadAction<boolean>) => {
      state.uploadComplete = action.payload;
    },
    setIsPricedGrade: (state, action: PayloadAction<string>) => {
      state.offerOrderStaticData.isPricedGrade = action.payload;
    },
    convertOrderToOffer: (
      state,
      action: PayloadAction<IOfferOrderStaticData>
    ) => {
      const {
        grade_id,
        grade_name,
        grade_location,
        shipment_type,
        unit,
        loads_left,
        grade_location_id,
        isMetal,
        selectedLocationZip,
        unit_price,
      } = action.payload;
      const data = {
        ...initialState.offerOrderStaticData,
        grade_id,
        grade_name,
        grade_location,
        shipment_type,
        unit,
        loads_left,
        grade_location_id,
        isMetal,
        selectedLocationZip,
        unit_price,
      };
      state.offerOrderStaticData = data;
    },
    resetCreateOfferOrderFormReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("grades/gradeDetails", "").fulfilled,
      (state, action) => {
        const d = action.payload.data;
        const details: IOfferOrderStaticData = {
          ...state.offerOrderStaticData,
          min_weight: d?.min_weight,
          grade_name: d?.name,
          grade_id: d?.id,
          unit: d?.unit,
          loads_left: d?.loads_left,
        };
        state.offerOrderStaticData = details;
      }
    );
    builder.addCase(GET("grades/infoUpdate", "").fulfilled, (state, action) => {
      const d = action.payload.data;
      const details: IOfferOrderStaticData = {
        ...state.offerOrderStaticData,
        unit_price:
          d.pricing[state.offerOrderStaticData.shipment_type].price_details
            .price,
        loads_left: d.loads_left,
      };
      state.offerOrderStaticData = details;
    });
    builder.addCase(
      POST("createOrder", `${ApiURL.CREATE_ORDER}`).fulfilled,
      (state, action) => {}
    );
    builder.addCase(
      POST("createOrder", `${ApiURL.CREATE_ORDER}`).rejected,
      (state, action) => {}
    );
    builder.addCase(
      POST("createOffer", `${ApiURL.CREATE_OFFER}`).fulfilled,
      (state, action) => {}
    );
    builder.addCase(
      POST("createOffer", `${ApiURL.CREATE_OFFER}`).rejected,
      (state, action) => {}
    );
    builder.addCase(
      POST("createOrderDraft", `${ApiURL.CREATE_ORDER_DRAFT}`).fulfilled,
      (state, action) => {}
    );
    builder.addCase(
      POST("createOrderDraft", `${ApiURL.CREATE_ORDER_DRAFT}`).rejected,
      (state, action) => {}
    );
    builder.addCase(
      POST("createOfferDraft", `${ApiURL.CREATE_OFFER_DRAFT}`).fulfilled,
      (state, action) => {}
    );
    builder.addCase(
      POST("createOfferDraft", `${ApiURL.CREATE_OFFER_DRAFT}`).rejected,
      (state, action) => {}
    );
    builder.addCase(
      POST("updateOfferDraft", `${ApiURL.CREATE_OFFER_DRAFT}`).fulfilled,
      (state, action) => {}
    );
    builder.addCase(
      POST("updateOfferDraft", `${ApiURL.CREATE_OFFER_DRAFT}`).rejected,
      (state, action) => {}
    );
    builder.addCase(
      POST("updateOrderDraft", `${ApiURL.CREATE_ORDER_DRAFT}`).fulfilled,
      (state, action) => {}
    );
    builder.addCase(
      POST("updateOrderDraft", `${ApiURL.CREATE_ORDER_DRAFT}`).rejected,
      (state, action) => {}
    );
    builder.addCase(
      GET("updateUnitPrice", `${ApiURL.CREATE_OFFER}`).fulfilled,
      (state, action) => {
        if (
          state.offerOrderStaticData.unit_price === null &&
          (action?.payload?.data?.pricing === null ||
            action?.payload?.data?.pricing[
              state.offerOrderStaticData.shipment_type
            ]?.price_details === null ||
            action?.payload?.data?.pricing[
              state.offerOrderStaticData.shipment_type
            ]?.price_details?.price === null)
        ) {
          state.offerOrderStaticData.unit_price = 0;
        } else if (
          state.offerOrderStaticData.unit_price === 0 &&
          (action?.payload?.data?.pricing === null ||
            action?.payload?.data?.pricing === null ||
            action?.payload?.data?.pricing[
              state.offerOrderStaticData.shipment_type
            ]?.price_details?.price === null)
        ) {
          state.offerOrderStaticData.unit_price = null;
        } else
          state.offerOrderStaticData.unit_price =
            action?.payload?.data?.pricing === null ||
            action?.payload?.data?.pricing[
              state.offerOrderStaticData.shipment_type
            ]?.price_details === null ||
            action?.payload?.data?.pricing[
              state.offerOrderStaticData.shipment_type
            ]?.price_details?.price === null
              ? null
              : action?.payload?.data?.pricing[
                  state.offerOrderStaticData.shipment_type
                ]?.price_details?.price;

        if (
          action?.payload?.data?.pricing &&
          action?.payload?.data?.pricing[
            state.offerOrderStaticData.shipment_type
          ]?.price_details?.loads_left
        ) {
          state.offerOrderStaticData.loads_left =
            action?.payload?.data?.pricing[
              state.offerOrderStaticData.shipment_type
            ]?.price_details?.loads_left;
        } else {
          state.offerOrderStaticData.loads_left = 0;
        }
      }
    );
    builder.addCase(
      GET("updateUnitPrice", `${ApiURL.CREATE_OFFER}`).rejected,
      (state, action) => {}
    );
    builder.addCase(POST("mysDraft/details", "").fulfilled, (state, action) => {
      state.imageList = action.payload.data?.grade_images
        ? MYSImageListForDraft(action.payload.data.grade_images)
        : [];
    });
  },
});

export default CreateOfferOrderFormSlice.reducer;
export const {
  setWeightPrice,
  setOfferOrderForm,
  setFormShipmentType,
  setOfferOrderFormLocation,
  setOfferOrderFormStaticData,
  setOrderFormStaticDataForDraft,
  setOfferOrderPayload,
  setOfferFormStaticDataForDraft,
  setUnitPriceChangeParameters,
  setFilledFormValues,
  setSubmittedForReview,
  setUnitPriceUpdateMessage,
  setNewAddressAdded,
  setNewAddressSaved,
  setNoPriceModal,
  setCounterOfferChanges,
  setOfferOrderSubmitted,
  setCounterOfferPayload,
  setPathFrom,
  setLoadsInput,
  setSelectedPickUpCity,
  resetOfferOrderFormStaticData,
  setIsMetal,
  setImageList,
  resetImageList,
  setNewDestinationId,
  setImageListArray,
  setIsNewAddress,
  setNewAddressState,
  setUploadComplete,
  convertOrderToOffer,
  resetCreateOfferOrderFormReducer,
  setIsPricedGrade,
  setImageListFromLocalStorage,
} = CreateOfferOrderFormSlice.actions;
