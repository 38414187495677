import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ICognitoSignupAttributes,
  ICognitoUserAmplify,
  ILoginForm,
} from "../../interfaces/authentication";
import { Auth } from "@aws-amplify/auth";

export const signIn = createAsyncThunk(
  "auth/signIn",
  async ({ email, password }: ILoginForm) => {
    const cognitoUser: ICognitoUserAmplify = await Auth.signIn(email, password);
    return cognitoUser;
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email }: { email: string }) => {
    return await Auth.forgotPassword(email);
  }
);

export const forgotPasswordSubmit = createAsyncThunk(
  "auth/forgotPasswordSubmit",
  async ({
    email,
    code,
    password,
  }: {
    email: string;
    code: string;
    password: string;
  }) => {
    const data = await Auth.forgotPasswordSubmit(email, code, password);
    return data;
  }
);

export const resendOtpCode = createAsyncThunk(
  "auth/resendOtp",
  async ({ email }: { email: string }) => {
    return await Auth.forgotPassword(email);
  }
);

export const signout = createAsyncThunk("auth/signout", async () => {
  return await Auth.signOut();
});

export const updatePassword = createAsyncThunk(
  "auth/changePassword",
  async ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) => {
    const user = await Auth.currentAuthenticatedUser();

    return await Auth.changePassword(user, oldPassword, newPassword);
  }
);

export const completePassword = createAsyncThunk(
  "auth/completePassword",
  async ({
    user,
    newPassword,
  }: {
    user: ICognitoUserAmplify | null;
    newPassword: string;
  }) => {
    return await Auth.completeNewPassword(user, newPassword, []);
  }
);

export const mapUserToCognitoModel = (user: any) => {
  const {
    email,
    firstName,
    lastName,
    phone,
    company,
    address,
    city,
    state,
    stateId,
    zipcode,
    is_company_verified,
  } = user;
  let attributeList: ICognitoSignupAttributes = {
    email,
    given_name: firstName,
    family_name: lastName,
    phone_number: phone,
    address: address,
    "custom:user_role": `["Supplier"]`,
    "custom:company_name": company,
    "custom:city": city,
    "custom:state": state,
    "custom:state_id": stateId,
    "custom:zip_code": zipcode,
    "custom:is_company_verified": is_company_verified,
  };

  return attributeList;
};

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (signupUserDetails: ICognitoSignupAttributes | null) => {
    let attributeList = mapUserToCognitoModel(signupUserDetails);
    await Auth.signUp({
      username: `${signupUserDetails?.email}`,
      password: `${signupUserDetails?.password}`,
      attributes: attributeList,
    });
  }
);

export const confirmSignUp = createAsyncThunk(
  "auth/confirmSignUp",
  async (registeredUserDetails: { email: string; code: string } | null) => {
    await Auth.confirmSignUp(
      `${registeredUserDetails?.email}`,
      `${registeredUserDetails?.code}`
    );
  }
);

export const resendSignupCode = createAsyncThunk(
  "auth/resendSignupCode",
  async ({ username }: { username: string }) => {
    return await Auth.resendSignUp(username);
  }
);
