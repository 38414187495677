import { MessageDialogListInterface } from "../../interfaces/MessagesInterface";
import {
  updateChatHistory,
  updateLoadMoreChat,
} from "../../store/features/Messages.slice";
import store from "../../store/store";
import { QB } from "../QuickBlox_initiation";

const QB_retriveChatHistory = (props: MessageDialogListInterface) => {
  const { _id, limit = 100, skip = 0 } = props;
  const params = {
    chat_dialog_id: _id,
    sort_desc: "date_sent",
    limit: limit,
    skip: skip,
  };

  QB.chat.message.list(params, function (error: any, messages: any) {
    const noMore = messages.items.length === 0;
    store.dispatch(
      updateChatHistory({
        id: _id,
        chats: messages.items,
        noMore: noMore,
      })
    );
    store.dispatch(updateLoadMoreChat(false));
  });
};

export default QB_retriveChatHistory;
