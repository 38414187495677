import React from "react";
import { LargeCrossIcon } from "../Icons/Icons";
import Drawer, { DrawerProps } from "antd/es/drawer";
import ChatBotResponsive from "../ChatBox/ChatBotResponsive";
import styled from "styled-components";
const Wrapper = styled(Drawer)`
  .ant-drawer-body {
    padding: 0rem !important;
  }
  .content-holder {
    padding: 1.6rem;
  }
`;
type DrawerType = DrawerProps & { showchatbot?: boolean };
const HubbitDrawer = (props: DrawerType) => {
  const { height, title, onClose, open, className, showchatbot = true } = props;
  return (
    <Wrapper
      height={height}
      title={title}
      closable={true}
      onClose={onClose}
      open={open}
      closeIcon={<LargeCrossIcon />}
      placement="bottom"
      className={className}
    >
      {showchatbot && <ChatBotResponsive />}

      <div className="content-holder">{props.children}</div>
    </Wrapper>
  );
};

export default HubbitDrawer;
