import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IappAlerts,
  IBackButtonParams,
  ImessageQuestionnaire,
  IstateSelect,
  Itooltiptype,
} from "../../interfaces/ICommonData";
import { GET, POST, PUT } from "../../services/api";

import { ApiURL } from "../../utils/constant";
import { sideBarSteps } from "../../components/HubbitTour/HubbitTourConfigs";
import {
  chatbot_const_text,
  no_finder,
  yes_finder,
} from "../../components/ChatBox/ChatBoxConst";

const web_app_alert_initial_state = {
  show: false,
  title: "",
  content: "",
};

const initialState: {
  sidebar_active: string;
  web_app_alert: IappAlerts[];
  active_web_app_alert: IappAlerts;
  header_name: string;
  toggle_side_bar: boolean;
  loader: boolean;
  firstLastChar: string;
  fullname: string;
  company_list: any[];
  state_list: IstateSelect[];
  state_list_id: { [id: number]: string };
  showToast: boolean;
  toastMessage: string;
  toastType: string;
  isBlocked: boolean;
  saveChanges: boolean;
  total_weight: number;
  total_price: number;
  zip_code_rejection: string;
  backButtonParams: IBackButtonParams;
  hasServerError: boolean;
  backButtonData: {
    page: string;
    path: string;
  };
  show_tour: boolean;
  active_tooltip: null | Itooltiptype;
  show_chatbot: boolean;
  show_chatbot_typing: boolean;
  minimize_chatbot: boolean;
  message_question: ImessageQuestionnaire;
  chatbot_const_text: {
    intro: string;

    category_option: {
      line_1: string;
      line_2: string;
    };
    helpful_note: string;
    thank_note: string;
    info_note: string;
    unknown_note: string;
    yes: {
      line_1: string;
      line_2: string;
    };
    no: {
      line_1: string;
    };
    contact_trader: string;
    explore_note: string;
  };
} = {
  sidebar_active: "dashboard",
  header_name: "dashboard",
  web_app_alert: [],
  active_web_app_alert: web_app_alert_initial_state,
  toggle_side_bar: false,
  // toggle_side_bar: tourCompleted ? false : false,
  loader: false,
  fullname: "",
  firstLastChar: "",
  company_list: [],
  state_list: [],
  state_list_id: {},
  showToast: false,
  toastMessage: "",
  toastType: "",
  isBlocked: false,
  saveChanges: false,
  total_weight: 0,
  total_price: 0,
  zip_code_rejection: "",
  backButtonParams: {
    show: false,
  },
  hasServerError: false,
  backButtonData: {
    page: "",
    path: "",
  },
  show_tour: false,
  active_tooltip: null,
  show_chatbot: false,
  show_chatbot_typing: false,
  minimize_chatbot: false,
  message_question: {} as ImessageQuestionnaire,
  chatbot_const_text: chatbot_const_text,
};
const CommonSlice = createSlice({
  name: "common_reducer",
  initialState,
  reducers: {
    updateSidebarActive: (state, action: PayloadAction<string>) => {
      state.sidebar_active = action.payload;
    },
    updateHeaderName: (state, action: PayloadAction<string>) => {
      state.header_name = action.payload;
    },
    updateBlocked: (state, action: PayloadAction<boolean>) => {
      state.isBlocked = action.payload;
    },
    updateActiveToolTip: (
      state,
      action: PayloadAction<null | Itooltiptype>
    ) => {
      state.active_tooltip = action.payload;
    },
    setSaveChanges: (state, action: PayloadAction<boolean>) => {
      state.saveChanges = action.payload;
    },
    updateToggleLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    updateToggleSidebar: (state) => {
      if (state.show_tour === false)
        state.toggle_side_bar = !state.toggle_side_bar;
    },
    setShowBackButton: (state, action: PayloadAction<IBackButtonParams>) => {
      state.backButtonParams = action.payload;
    },
    setBackButtonData: (
      state,
      action: PayloadAction<{ page: string; path: string }>
    ) => {
      state.backButtonData = action.payload;
    },
    updatePriceWeight: (
      state,
      action: PayloadAction<{ total_weight: number; total_price: number }>
    ) => {
      state.total_weight = action.payload.total_weight;
      state.total_price = action.payload.total_price;
    },
    toggleChatbotTyping: (state, action) => {
      state.show_chatbot_typing = action.payload;
    },
    setToastMessage: (
      state,
      action: PayloadAction<{
        showToast: boolean;
        toastMessage: string;
        toastType: string;
      }>
    ) => {
      state.showToast = action.payload.showToast;
      state.toastMessage = action.payload.toastMessage;
      state.toastType = action.payload.toastType;
    },
    setServerErrSt: (state, action: PayloadAction<boolean>) => {
      state.hasServerError = action.payload;
    },
    resetCompanyList: (state) => {
      state.company_list = [];
    },
    resetCommonReducer: () => initialState,
    updateTourCompleted: (state, action) => {
      state.show_tour = action.payload;
    },
    closeSidebar: (state) => {
      state.toggle_side_bar = false;
    },
    openSidebar: (state) => {
      state.toggle_side_bar = true;
    },
    toggleShowChatBot: (state, action) => {
      state.show_chatbot = action.payload;
    },
    toggleMinimizeChatBot: (state) => {
      state.minimize_chatbot = !state.minimize_chatbot;
    },
    updateMinimizeChatBot: (state, action) => {
      state.minimize_chatbot = action.payload;
    },
    updateAppAlert: (state, action: PayloadAction<number>) => {
      if (state.web_app_alert[action.payload])
        state.active_web_app_alert = state.web_app_alert[action.payload];
      else state.active_web_app_alert = web_app_alert_initial_state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("companylist", `${ApiURL.COMPANY_LIST}`).fulfilled,
      (state, action) => {
        state.company_list = action.payload.data;
      }
    );
    builder.addCase(
      GET("companylist", `${ApiURL.COMPANY_LIST}`).rejected,
      (state, action) => {
        state.company_list = [];
      }
    );
    builder.addCase(
      GET("stateList", `${ApiURL.STATE_LIST}`).fulfilled,
      (state, action) => {
        state.state_list = action.payload.data;
        state.state_list_id = action.payload.data.reduce(
          (
            prev: { [id: number]: string },
            el: { id: number; name: string }
          ) => {
            prev[el.id] = el.name;
            return prev;
          },
          {} as { [id: number]: string }
        );
      }
    );
    builder.addCase(
      GET("stateList", `${ApiURL.STATE_LIST}`).rejected,
      (state, action) => {
        state.state_list = [];
      }
    );
    builder.addCase(
      POST("zipCodeValidation", ApiURL.ZIP_CODE_VERIFICATION).fulfilled,
      (state, action) => {}
    );

    builder.addCase(
      POST("zipCodeValidation", ApiURL.ZIP_CODE_VERIFICATION).rejected,
      (state, action) => {}
    );
    builder.addCase(
      PUT("removeTrackerID", ApiURL.GTM_TRACK_LIST).rejected,
      (state, action) => {}
    );

    builder.addCase(
      GET("profileDetails", ApiURL.PROFILE_DETAILS).fulfilled,
      (state, action) => {
        const viewed_web_walkthrough =
          action.payload.data.viewed_web_walkthrough;
        if (
          viewed_web_walkthrough === null ||
          viewed_web_walkthrough === false
        ) {
          let tourStep = localStorage.getItem("tour_current_step");
          let currentStep = 0;
          if (tourStep !== null) currentStep = +tourStep + 1;
          if (tourStep === null) currentStep = 1;
          if (sideBarSteps.includes(currentStep)) state.toggle_side_bar = true;
          if (currentStep <= 6) state.show_tour = true;
        }
      }
    );

    builder.addCase(
      POST("messageQuestion", ApiURL.MESSAGE_QUESTIONS).fulfilled,
      (state, action) => {
        state.chatbot_const_text =
          action.payload.data.static_text || state.chatbot_const_text;
        const categoriesFinder: { [id: string]: number } =
          action.payload.data?.categories.reduce(
            (
              prev: { [id: string]: number },
              current: {
                id: number;
                label: string;
              }
            ) => {
              if (current.label.toLowerCase().trim() === "contact trader")
                return prev;
              prev[current.label.toLowerCase().trim()] = current.id;
              return prev;
            },
            {} as { [id: string]: number }
          );
        state.message_question = {
          ...action.payload.data,
          helpful: [
            { id: 1, label: "Yes" },
            { id: 2, label: "No" },
          ],

          categories_finder: categoriesFinder,
          yes_finder: yes_finder,
          no_finder: no_finder,
          contact_trader: action.payload.data.categories || [
            { id: 1, label: "Contact Trader" },
            { id: 2, label: "Categories" },
          ],
        };
      }
    );
    builder.addCase(
      PUT("common/appAlerts", ApiURL.GTM_TRACK_LIST).fulfilled,
      (state, action) => {
        state.web_app_alert = action.payload.data.web_app_alerts.filter(
          (el: IappAlerts) => el.show
        );

        if (state.web_app_alert.length > 0) {
          state.active_web_app_alert = state.web_app_alert[0];
        } else {
          state.active_web_app_alert = web_app_alert_initial_state;
        }
      }
    );
  },
});
export default CommonSlice.reducer;
export const {
  updateSidebarActive,
  updateHeaderName,
  updateToggleLoader,
  updateToggleSidebar,
  updateBlocked,
  setToastMessage,
  setSaveChanges,
  updatePriceWeight,
  setShowBackButton,
  setServerErrSt,
  setBackButtonData,
  resetCompanyList,
  resetCommonReducer,
  updateTourCompleted,
  closeSidebar,
  openSidebar,
  updateActiveToolTip,
  toggleShowChatBot,
  toggleMinimizeChatBot,
  updateMinimizeChatBot,
  toggleChatbotTyping,
  updateAppAlert,
} = CommonSlice.actions;
