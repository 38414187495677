import { SCREEN_TYPE } from "../../utils/constant";
const desktopConfig = {
  maxWidth: "42.4rem",
  minHeight: "17.2rem",
};
const tabletConfig = {
  maxWidth: "34.3rem",
  minHeight: "17.2rem",
};
const mobileConfig = {
  maxWidth: "34.3rem",
  minHeight: "17.2rem",
};
const smallerMobileConfig = {
  maxWidth: "29rem",
  minHeight: "17.2rem",
};

const desktopConfiguration = {
  position: {
    deals: "rightTop",
    grades: "rightTop",
    pricing: "rightTop",
    messages: "rightTop",
    profile: "bottom",
    notification: "bottomRight",
    help: "rightTop",
  },
  styles: desktopConfig,
};

const tabletConfiguration = {
  position: {
    deals: "rightTop",
    grades: "rightTop",
    pricing: "rightTop",
    messages: "rightTop",
    profile: "bottom",
    notification: "bottom",
    help: "rightTop",
  },
  styles: tabletConfig,
};

const mobileConfiguration = {
  position: {
    deals: "bottom",
    grades: "bottom",
    pricing: "bottom",
    messages: "bottom",
    profile: "bottom",
    notification: "bottom",
    help: "bottom",
  },
  styles: mobileConfig,
};

const smallerMobileConfiguration = {
  position: {
    deals: "bottom",
    grades: "bottom",
    pricing: "bottom",
    messages: "bottom",
    profile: "bottom",
    notification: "bottom",
    help: "bottom",
  },
  styles: smallerMobileConfig,
};
export const sideBarMenu = ["pricing", "deals", "messages", "grades", "help"];

export const tourDescriptions = {
  deals: `Find personalized up-to-the-minute competitive pricing directly from GP Recycling on 50+ material grades. Click on your grade to find more details on pricing and start your sale!`,
  grades: `Can't find the grade you're looking for on the Deals page? Click Grades to search for your material for pricing or submit your price request!`,
  pricing: `Browse or Search for pricing from GP Recycling on all materials/grades here.`,
  messages: `Have a question about your sale/price request or a general query? Send a message directly to your GP Trader. Messages can even be tied to specific sales for better clarity.`,
  profile: `Click here to view profile, add locations and favorite grades, update your password and enable notifications.`,
  notification: `Find all your notifications on updated pricing, new market demand from GPR, and updates on your sales/price requests that you might have missed.`,
  help: `Confused or stuck? Click here to find help and FAQ topics on all aspects of the app and how they work!`,
};

export const tourTotalSteps = 5;
export const tourCustomConfigs = {
  [SCREEN_TYPE.DESKTOP]: desktopConfiguration,
  [SCREEN_TYPE.LAPTOP]: desktopConfiguration,
  [SCREEN_TYPE.TABLET]: tabletConfiguration,
  [SCREEN_TYPE.MOBILE]: mobileConfiguration,
  [SCREEN_TYPE.SMALLER_MOBILE]: smallerMobileConfiguration,
};
export const sideBarSteps = [1, 2, 6];

//old tour
// export const tourNavigatorlinks: { [id: number]: string } = {
//   1: "deals",
//   2: "grades",
//   3: "messages",
//   4: "grades",
//   5: "grades",
//   6: "grades",
// };

export const tourNavigatorlinks: { [id: number]: string } = {
  1: "pricing",
  2: "messages",
  3: "grades",
  4: "grades",
  5: "grades",
};

export const tourheaderName: { [name: string]: string } = {
  help: "Help & FAQ",
  profile: "Settings & Personalization",
  notification: "Notifications",
};
