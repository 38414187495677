export const HomeIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 20V12.6C7 12.0399 7 11.7599 7.10899 11.546C7.20487 11.3578 7.35785 11.2049 7.54601 11.109C7.75992 11 8.03995 11 8.6 11H11.4C11.9601 11 12.2401 11 12.454 11.109C12.6422 11.2049 12.7951 11.3578 12.891 11.546C13 11.7599 13 12.0399 13 12.6V20M9.0177 1.764L2.23539 7.03912C1.78202 7.39175 1.55534 7.56806 1.39203 7.78886C1.24737 7.98444 1.1396 8.20478 1.07403 8.43905C1 8.70352 1 8.9907 1 9.56505V16.8C1 17.9201 1 18.4801 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4801 19 17.9201 19 16.8V9.56505C19 8.9907 19 8.70352 18.926 8.43905C18.8604 8.20478 18.7526 7.98444 18.608 7.78886C18.4447 7.56806 18.218 7.39175 17.7646 7.03913L10.9823 1.764C10.631 1.49075 10.4553 1.35412 10.2613 1.3016C10.0902 1.25526 9.9098 1.25526 9.73865 1.3016C9.54468 1.35412 9.36902 1.49075 9.0177 1.764Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const DealsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 15.9729C6.21367 18.3543 8.18431 20.0443 11.1843 20.2629V22H12.6901V20.2629C15.9673 20.0071 18 18.2086 18 15.5486C18 13.2771 16.6328 11.9629 13.7324 11.2229L12.6901 10.9557V5.52429C14.3099 5.68143 15.4028 6.54429 15.6785 7.83286H17.8008C17.5611 5.54714 15.5775 3.90714 12.6901 3.72571V2H11.1843V3.76143C8.38499 4.09 6.46343 5.86429 6.46343 8.27C6.46343 10.3471 7.85804 11.8171 10.3051 12.4371L11.1858 12.6686V18.4271C9.52695 18.1843 8.38499 17.2843 8.10924 15.9729H6ZM10.8956 10.4929C9.3898 10.1171 8.58566 9.31429 8.58566 8.18429C8.58566 6.83571 9.60202 5.84 11.1843 5.57286V10.5657L10.8956 10.4929ZM13.1925 13.1671C15.0505 13.6286 15.8662 14.3943 15.8662 15.6943C15.8662 17.2614 14.6737 18.3057 12.6901 18.4643V13.0429L13.1925 13.1657V13.1671Z"
      fill="white"
    />
  </svg>
);
export const BellIcon = ({
  active = false,
  width = "2.6rem",
  height = "2.4rem",
  strokeWidth = 2,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.35395 21C10.0591 21.6224 10.9853 22 11.9998 22C13.0142 22 13.9405 21.6224 14.6456 21M17.9998 8C17.9998 6.4087 17.3676 4.88258 16.2424 3.75736C15.1172 2.63214 13.5911 2 11.9998 2C10.4085 2 8.88235 2.63214 7.75713 3.75736C6.63192 4.88258 5.99977 6.4087 5.99977 8C5.99977 11.0902 5.22024 13.206 4.34944 14.6054C3.6149 15.7859 3.24763 16.3761 3.2611 16.5408C3.27601 16.7231 3.31463 16.7926 3.46155 16.9016C3.59423 17 4.19237 17 5.38863 17H18.6109C19.8072 17 20.4053 17 20.538 16.9016C20.6849 16.7926 20.7235 16.7231 20.7384 16.5408C20.7519 16.3761 20.3846 15.7859 19.6501 14.6054C18.7793 13.206 17.9998 11.0902 17.9998 8Z"
      stroke="#464D56"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {active && (
      <circle
        cx="19.6366"
        cy="4.36364"
        r="3.86364"
        fill="#64C492"
        stroke="white"
      />
    )}
  </svg>
);

export const MessagesIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 7.5H10M6 11H13M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 11.1971 1.23374 12.3397 1.65806 13.3845C1.73927 13.5845 1.77988 13.6845 1.798 13.7653C1.81572 13.8443 1.8222 13.9028 1.82221 13.9839C1.82222 14.0667 1.80718 14.1569 1.77711 14.3374L1.18413 17.8952C1.12203 18.2678 1.09098 18.4541 1.14876 18.5888C1.19933 18.7067 1.29328 18.8007 1.41118 18.8512C1.54589 18.909 1.73218 18.878 2.10476 18.8159L5.66265 18.2229C5.84309 18.1928 5.9333 18.1778 6.01613 18.1778C6.09715 18.1778 6.15566 18.1843 6.23472 18.202C6.31554 18.2201 6.41552 18.2607 6.61549 18.3419C7.6603 18.7663 8.80286 19 10 19Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const GradesIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.44444 2.05556H6.06667C4.29317 2.05556 3.40642 2.05556 2.72903 2.4007C2.13318 2.7043 1.64875 3.18874 1.34515 3.78459C1 4.46197 1 5.34872 1 7.12222V14.9333C1 16.7068 1 17.5936 1.34515 18.271C1.64875 18.8668 2.13318 19.3513 2.72903 19.6549C3.40642 20 4.29317 20 6.06667 20H13.8778C15.6513 20 16.538 20 17.2154 19.6549C17.8113 19.3513 18.2957 18.8668 18.5993 18.271C18.9444 17.5936 18.9444 16.7068 18.9444 14.9333V11.5556M11.5556 15.7778H5.22222M13.6667 11.5556H5.22222M19.0725 1.9275C20.3092 3.16416 20.3092 5.16918 19.0725 6.40584C17.8358 7.6425 15.8308 7.6425 14.5942 6.40584C13.3575 5.16918 13.3575 3.16416 14.5942 1.9275C15.8308 0.690835 17.8358 0.690835 19.0725 1.9275Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const MyTradeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 14.7778H8M8 14.7778L10 12.5556M8 14.7778L10 17M8 9.22222H16M16 9.22222L14 7M16 9.22222L14 11.4444"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const LegalIcon = () => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 10.5001L8 12.5001L12.5 8.00011M17 11.0001C17 15.9086 11.646 19.4785 9.69799 20.615C9.4766 20.7442 9.3659 20.8087 9.20968 20.8422C9.08844 20.8682 8.91156 20.8682 8.79032 20.8422C8.6341 20.8087 8.5234 20.7442 8.30201 20.615C6.35396 19.4785 1 15.9086 1 11.0001V6.21772C1 5.4182 1 5.01845 1.13076 4.67482C1.24627 4.37126 1.43398 4.10039 1.67766 3.88564C1.9535 3.64255 2.3278 3.50219 3.0764 3.22146L8.4382 1.21079C8.6461 1.13283 8.75005 1.09385 8.85698 1.07839C8.95184 1.06469 9.04816 1.06469 9.14302 1.07839C9.24995 1.09385 9.3539 1.13283 9.5618 1.21079L14.9236 3.22146C15.6722 3.50219 16.0465 3.64255 16.3223 3.88564C16.566 4.10039 16.7537 4.37126 16.8692 4.67482C17 5.01845 17 5.4182 17 6.21772V11.0001Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const HelpIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.76667 7.66667C8.02789 6.92408 8.5435 6.2979 9.22217 5.89904C9.90084 5.50018 10.6988 5.35438 11.4746 5.48746C12.2505 5.62054 12.9542 6.02392 13.4612 6.62614C13.9682 7.22837 14.2456 7.99058 14.2444 8.77778C14.2444 11 10.9111 12.1111 10.9111 12.1111M11 16.5556H11.0111M6.33333 21H15.6667C17.5335 21 18.4669 21 19.18 20.6367C19.8072 20.3171 20.3171 19.8072 20.6367 19.18C21 18.4669 21 17.5335 21 15.6667V6.33333C21 4.46649 21 3.53307 20.6367 2.82003C20.3171 2.19282 19.8072 1.68289 19.18 1.36331C18.4669 1 17.5335 1 15.6667 1H6.33333C4.46649 1 3.53307 1 2.82003 1.36331C2.19282 1.68289 1.68289 2.19282 1.36331 2.82003C1 3.53307 1 4.46649 1 6.33333V15.6667C1 17.5335 1 18.4669 1.36331 19.18C1.68289 19.8072 2.19282 20.3171 2.82003 20.6367C3.53307 21 4.46649 21 6.33333 21Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const OffersIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4154 0.833984H3.58203C2.85269 0.833984 2.15321 1.12372 1.63749 1.63944C1.12176 2.15517 0.832031 2.85464 0.832031 3.58398V16.4173C0.832031 17.1467 1.12176 17.8461 1.63749 18.3619C2.15321 18.8776 2.85269 19.1673 3.58203 19.1673H16.4154C17.1447 19.1673 17.8442 18.8776 18.3599 18.3619C18.8756 17.8461 19.1654 17.1467 19.1654 16.4173V3.58398C19.1654 2.85464 18.8756 2.15517 18.3599 1.63944C17.8442 1.12372 17.1447 0.833984 16.4154 0.833984ZM17.332 16.4173C17.332 16.6604 17.2355 16.8936 17.0635 17.0655C16.8916 17.2374 16.6585 17.334 16.4154 17.334H3.58203C3.33892 17.334 3.10576 17.2374 2.93385 17.0655C2.76194 16.8936 2.66536 16.6604 2.66536 16.4173V3.58398C2.66536 3.34087 2.76194 3.10771 2.93385 2.9358C3.10576 2.76389 3.33892 2.66732 3.58203 2.66732H16.4154C16.6585 2.66732 16.8916 2.76389 17.0635 2.9358C17.2355 3.10771 17.332 3.34087 17.332 3.58398V16.4173Z"
        fill="white"
      />
      <path
        d="M6.33203 14.584H13.6654C13.9251 14.584 14.1426 14.496 14.318 14.32C14.494 14.1446 14.582 13.927 14.582 13.6673C14.582 13.4076 14.494 13.1897 14.318 13.0137C14.1426 12.8383 13.9251 12.7506 13.6654 12.7506H6.33203C6.07231 12.7506 5.85445 12.8383 5.67845 13.0137C5.50306 13.1897 5.41536 13.4076 5.41536 13.6673C5.41536 13.927 5.50306 14.1446 5.67845 14.32C5.85445 14.496 6.07231 14.584 6.33203 14.584ZM6.33203 7.25065H9.08203C9.34175 7.25065 9.55961 7.16265 9.73561 6.98665C9.911 6.81126 9.9987 6.59371 9.9987 6.33398C9.9987 6.07426 9.911 5.8564 9.73561 5.6804C9.55961 5.50501 9.34175 5.41732 9.08203 5.41732H6.33203C6.07231 5.41732 5.85445 5.50501 5.67845 5.6804C5.50306 5.8564 5.41536 6.07426 5.41536 6.33398C5.41536 6.59371 5.50306 6.81126 5.67845 6.98665C5.85445 7.16265 6.07231 7.25065 6.33203 7.25065ZM6.33203 10.9173H13.6654C13.9251 10.9173 14.1426 10.8293 14.318 10.6533C14.494 10.4779 14.582 10.2604 14.582 10.0006C14.582 9.74093 14.494 9.52307 14.318 9.34707C14.1426 9.17168 13.9251 9.08398 13.6654 9.08398H6.33203C6.07231 9.08398 5.85445 9.17168 5.67845 9.34707C5.50306 9.52307 5.41536 9.74093 5.41536 10.0006C5.41536 10.2604 5.50306 10.4779 5.67845 10.6533C5.85445 10.8293 6.07231 10.9173 6.33203 10.9173Z"
        fill="white"
      />
    </svg>
  );
};
export const OrdersIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1673 5.93191C19.1612 5.90761 19.1612 5.88248 19.1673 5.85818C19.162 5.83691 19.162 5.81494 19.1673 5.79366V5.71071L19.1022 5.57246C19.0757 5.53474 19.0428 5.50057 19.0045 5.47108L18.9068 5.39735H18.8525L14.5751 3.10239L10.5366 0.982548C10.4431 0.919625 10.3362 0.872676 10.2217 0.844297H10.1349C10.0378 0.830547 9.9388 0.830547 9.84176 0.844297H9.7332C9.60709 0.867977 9.48619 0.908414 9.37494 0.964114L1.26529 5.24988L1.16758 5.31439L1.06988 5.38813L0.961312 5.45265L0.907031 5.50795L0.841893 5.6462V5.78445C0.831348 5.84557 0.831348 5.90766 0.841893 5.96878V14.015C0.841524 14.1716 0.88818 14.3257 0.977457 14.4628C1.06673 14.5999 1.19569 14.7154 1.35214 14.7984L9.49436 19.0749L9.6572 19.1302H9.74405C9.92772 19.1797 10.1249 19.1797 10.3086 19.1302H10.3954L10.5583 19.0749L18.6354 14.8629C18.7918 14.7799 18.9208 14.6644 19.01 14.5273C19.0993 14.3902 19.146 14.2361 19.1456 14.0795V6.0333C19.1456 6.0333 19.1673 5.96878 19.1673 5.93191ZM9.95032 2.84432L11.8827 3.85816L5.81407 7.04713L3.8708 6.0333L9.95032 2.84432ZM8.86469 16.6694L2.89373 13.5726V7.68309L8.86469 10.8168V16.6694ZM9.95032 9.19463L7.87677 8.1347L13.9454 4.93651L16.0298 6.0333L9.95032 9.19463ZM17.0069 13.5449L11.036 16.697V10.8168L17.0069 7.68309V13.5449Z"
        fill="white"
      />
    </svg>
  );
};
export const DraftsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4173 0.833984H3.58398C2.85464 0.833984 2.15517 1.12372 1.63944 1.63944C1.12372 2.15517 0.833984 2.85464 0.833984 3.58398V16.4173C0.833984 17.1467 1.12372 17.8461 1.63944 18.3619C2.15517 18.8776 2.85464 19.1673 3.58398 19.1673H16.4173C17.1467 19.1673 17.8461 18.8776 18.3619 18.3619C18.8776 17.8461 19.1673 17.1467 19.1673 16.4173V3.58398C19.1673 2.85464 18.8776 2.15517 18.3619 1.63944C17.8461 1.12372 17.1467 0.833984 16.4173 0.833984ZM17.334 16.4173C17.334 16.6604 17.2374 16.8936 17.0655 17.0655C16.8936 17.2374 16.6604 17.334 16.4173 17.334H3.58398C3.34087 17.334 3.10771 17.2374 2.9358 17.0655C2.76389 16.8936 2.66732 16.6604 2.66732 16.4173V3.58398C2.66732 3.34087 2.76389 3.10771 2.9358 2.9358C3.10771 2.76389 3.34087 2.66732 3.58398 2.66732H16.4173C16.6604 2.66732 16.8936 2.76389 17.0655 2.9358C17.2374 3.10771 17.334 3.34087 17.334 3.58398V16.4173Z"
        fill="white"
      />
      <path
        d="M6.33398 14.584H13.6673C13.927 14.584 14.1446 14.496 14.32 14.32C14.496 14.1446 14.584 13.927 14.584 13.6673C14.584 13.4076 14.496 13.1897 14.32 13.0137C14.1446 12.8383 13.927 12.7506 13.6673 12.7506H6.33398C6.07426 12.7506 5.8564 12.8383 5.6804 13.0137C5.50501 13.1897 5.41732 13.4076 5.41732 13.6673C5.41732 13.927 5.50501 14.1446 5.6804 14.32C5.8564 14.496 6.07426 14.584 6.33398 14.584ZM6.33398 7.25065H9.08398C9.34371 7.25065 9.56157 7.16265 9.73757 6.98665C9.91296 6.81126 10.0006 6.59371 10.0006 6.33398C10.0006 6.07426 9.91296 5.8564 9.73757 5.6804C9.56157 5.50501 9.34371 5.41732 9.08398 5.41732H6.33398C6.07426 5.41732 5.8564 5.50501 5.6804 5.6804C5.50501 5.8564 5.41732 6.07426 5.41732 6.33398C5.41732 6.59371 5.50501 6.81126 5.6804 6.98665C5.8564 7.16265 6.07426 7.25065 6.33398 7.25065ZM6.33398 10.9173H13.6673C13.927 10.9173 14.1446 10.8293 14.32 10.6533C14.496 10.4779 14.584 10.2604 14.584 10.0006C14.584 9.74093 14.496 9.52307 14.32 9.34707C14.1446 9.17168 13.927 9.08398 13.6673 9.08398H6.33398C6.07426 9.08398 5.8564 9.17168 5.6804 9.34707C5.50501 9.52307 5.41732 9.74093 5.41732 10.0006C5.41732 10.2604 5.50501 10.4779 5.6804 10.6533C5.8564 10.8293 6.07426 10.9173 6.33398 10.9173Z"
        fill="white"
      />
    </svg>
  );
};
export const UpVectorIcon = () => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.336722 6.67414C0.550006 6.88285 0.838522 7 1.13926 7C1.43999 7 1.72851 6.88285 1.94179 6.67414L6.02846 2.70717L10.0582 6.67413C10.2715 6.88285 10.56 7 10.8607 7C11.1615 7 11.45 6.88285 11.6633 6.67413C11.77 6.56996 11.8547 6.44602 11.9125 6.30946C11.9702 6.1729 12 6.02643 12 5.8785C12 5.73057 11.9702 5.5841 11.9125 5.44754C11.8547 5.31098 11.77 5.18704 11.6633 5.08287L6.83669 0.331475C6.73086 0.226442 6.60496 0.143075 6.46624 0.0861836C6.32752 0.0292916 6.17873 5.08911e-07 6.02846 5.22049e-07C5.87818 5.35186e-07 5.72939 0.0292917 5.59068 0.0861836C5.45196 0.143076 5.32606 0.226442 5.22023 0.331475L0.336722 5.08287C0.230027 5.18704 0.145341 5.31098 0.0875481 5.44754C0.0297555 5.5841 -1.10977e-07 5.73057 -9.80446e-08 5.8785C-8.51118e-08 6.02644 0.0297555 6.17291 0.0875482 6.30946C0.145341 6.44602 0.230027 6.56996 0.336722 6.67414Z"
      fill="white"
    />
  </svg>
);
export const TermsOfUseIcon = () => <></>;
export const PrivacyPolicyIcon = () => <></>;
const MenuIcons = new Map();
MenuIcons.set("HomeIcon", HomeIcon);
MenuIcons.set("DealsIcon", DealsIcon);
MenuIcons.set("GradesIcon", GradesIcon);
MenuIcons.set("MyTradeIcon", MyTradeIcon);
MenuIcons.set("MessagesIcon", MessagesIcon);
MenuIcons.set("LegalIcon", LegalIcon);
MenuIcons.set("HelpIcon", HelpIcon);
MenuIcons.set("OffersIcon", OffersIcon);
MenuIcons.set("OrdersIcon", OrdersIcon);
MenuIcons.set("DraftsIcon", DraftsIcon);
MenuIcons.set("UpVectorIcon", UpVectorIcon);
MenuIcons.set("TermsOfUseIcon", DraftsIcon);
MenuIcons.set("PrivacyPolicyIcon", PrivacyPolicyIcon);

export default MenuIcons;
