import router from "./router";
import { RouterProvider } from "react-router-dom";
import "./services/interceptor";
import "./App.css";
import { PortalLoader } from "./components/Loader/Loader";
import { useAppSelector } from "./hooks/reduxHooks";
import { ToastMessage } from "./components/ToastMessage/ToastMessage";
import useAuth from "./hooks/useAuth";
import InitializeData from "./InitializeData";

function App() {
  useAuth();

  const { loader } = useAppSelector((state) => state.CommonReducer);
  return (
    <>
      <InitializeData />
      <RouterProvider router={router} />
      {loader && <PortalLoader />}
      <ToastMessage />
    </>
  );
}

export default App;
