import { Button, Modal } from "antd";
import React, { useEffect } from "react";

import styled from "styled-components";
import { fontMedium14, fontNormal14 } from "../../utils/styleConsts";
import useScreenType from "../../hooks/useScreenType";
import { SCREEN_TYPE } from "../../utils/constant";
import HubbitDrawer from "../HubbitDrawer/HubbitDrawer";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { updateShowDuplicateSale } from "../../store/features/OffersActions.action";
import {
  clearMYScachedata,
  MYSformOneInitialValues,
  MYSgetCachedKey,
  MYSLoadTermsApiData,
  MYSnavigatePath,
  MYSsaleFlowApiType,
  MYSsaleFlowType,
  MYSshipmentTypes,
  MYSweightFormatter,
} from "../../pages/MakeYourSale/MYSconst";
import { useNavigate } from "react-router-dom";
import { IOrderDetails } from "../../interfaces/OrdersInterface";
import { IOfferDetailsData } from "../../interfaces/IOfferInterface";
import { IMYSformOneValue } from "../../interfaces/ImakeYourSale";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { heap_startsale } from "../../pages/MakeYourSale/MYS_heap";
import { IGrade } from "../../interfaces/IGradeReducer";

const Wrapper = styled.div`
  p {
    ${fontNormal14}
    color:var(--NEUTRAL-4);
  }
  .desc {
    margin: 1.6rem 0 2.5rem;
  }
  .sub_desc {
    margin-bottom: 2rem;
  }
  .action-btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 2rem;
    gap: 1.2rem 2rem;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    button {
      min-height: 4.4rem;
      min-width: 18rem;
      ${fontMedium14}
    }
  }
`;

const copyText = {
  desc: "If you'd like to re-submit this material for pricing, please choose an option below.",
  sub_desc:
    'To save time, choose "Continue with Parameters" and many of the parameters of the sale will pre-populate for you. You can still edit them as necessary.',
};

const SaleDuplicationPopup = (props: {
  open: boolean;
  title: string;
  grade_id: string;
  order_details: IOrderDetails;
  offer_details: IOfferDetailsData | null;
  isOrder: boolean;
}) => {
  const {
    open = false,
    title = "",
    grade_id = "",
    order_details = {} as IOrderDetails,
    offer_details = {} as IOfferDetailsData,
    isOrder = true,
  } = props;
  const screenType = useScreenType();
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { defaultLocation } = useAppSelector((state) => state.ProfileReducer);
  const desktop =
    screenType === SCREEN_TYPE.DESKTOP || screenType === SCREEN_TYPE.LAPTOP;
  const zipcode = defaultLocation.zip_code;
  const path = MYSnavigatePath(grade_id, zipcode, 1);
  const LS = useLocalStorage();

  const triggerHeap = () => {
    if (isOrder) {
      let heap_data: IGrade = {} as IGrade;
      heap_data["name"] = order_details.grade.name;
      heap_data["id"] = order_details.grade.id;
      heap_data["unit"] = order_details.grade.unit;
      heap_data["price_type"] = "";
      heap_data["category"] = "" + order_details.grade.category_name;
      heap_data["sub_category_1"] = "";
      heap_data["sub_category_2"] = "";
      if (order_details.grade_subcategory_1_info) {
        if (order_details?.grade_subcategory_1_info?.name) {
          heap_data["sub_category_1"] =
            order_details?.grade_subcategory_1_info?.name;
        }
      }
      if (order_details.grade_subcategory_2_info) {
        if (order_details?.grade_subcategory_2_info?.name) {
          heap_data["sub_category_2"] =
            order_details?.grade_subcategory_2_info?.name;
        }
      }
      heap_startsale(heap_data);
    }
    if (offer_details && !isOrder) {
      let heap_data: IGrade = {} as IGrade;
      heap_data["name"] = offer_details.grade.name;
      heap_data["id"] = offer_details.grade.id;
      heap_data["unit"] = offer_details.grade.unit;
      heap_data["price_type"] = "";
      heap_data["category"] = "" + offer_details.grade.category_name;
      heap_data["sub_category_1"] = "";
      heap_data["sub_category_2"] = "";
      if (offer_details.grade_subcategory_1_info) {
        if (offer_details?.grade_subcategory_1_info?.name) {
          heap_data["sub_category_1"] =
            offer_details?.grade_subcategory_1_info?.name;
        }
      }
      if (offer_details.grade_subcategory_2_info) {
        if (offer_details?.grade_subcategory_2_info?.name) {
          heap_data["sub_category_2"] =
            offer_details?.grade_subcategory_2_info?.name;
        }
      }
      heap_startsale(heap_data);
    }
  };
  const withoutParams = () => {
    dispatch(updateShowDuplicateSale(false));
    triggerHeap();
    nav(path);
  };

  const withParams = () => {
    const form_one: IMYSformOneValue = { ...MYSformOneInitialValues };
    if (isOrder) {
      if (order_details) {
        const shipment_type = `${order_details.freight_type} ${order_details.sell_type}`;

        if (order_details.sale_flow_type) {
          form_one.sale_type = MYSsaleFlowApiType[order_details.sale_flow_type];
          if (
            order_details.sale_flow_type === MYSsaleFlowType.WEIGHT_PER_LOAD
          ) {
            if (order_details.loads)
              form_one.min_weight_load = order_details.loads;
          }
          if (order_details.sale_flow_type === MYSsaleFlowType.TOTAL_WEIGHT) {
            if (order_details.material_packing_type)
              form_one.material_type = order_details.material_packing_type;
          }
        }
        if (order_details.sell_type) {
          form_one.sell_option = order_details.sell_type.toLowerCase();
          if (order_details.freight_type) {
            form_one.freight_option = `${order_details.freight_type} ${order_details.sell_type}`;
          }
        }
        if (order_details.min_weight) {
          form_one.min_weight = MYSweightFormatter(
            "" + order_details.min_weight
          );
        }
        if (order_details.supplier_weight_unit) {
          form_one.min_weight_unit = "lb";
        }
        if (order_details.load_info)
          form_one.load_terms = MYSLoadTermsApiData[order_details.load_info];

        if (order_details.shipping_location.preferred_location_id) {
          if (
            shipment_type &&
            [
              MYSshipmentTypes.FOB_DOMESTIC,
              MYSshipmentTypes.FOB_EXPORT,
            ].includes(shipment_type)
          ) {
            form_one.pickup_address =
              order_details.shipping_location.preferred_location_id;
          }
        }

        if (shipment_type && MYSshipmentTypes.FAS_EXPORT === shipment_type) {
          if (order_details?.shipping_location?.port_id)
            form_one.port = "" + order_details.shipping_location.port_id;
        }
        if (order_details.shipping_location) {
          if (
            order_details.shipping_location.preferred_location_id === null &&
            order_details?.shipping_location?.port_id === null
          ) {
            const { shipping_location } = order_details;
            if (shipping_location.address)
              form_one.new_address = shipping_location.address;
            if (shipping_location.state_id)
              form_one.new_state = shipping_location.state_id;
            if (shipping_location.city)
              form_one.new_city = shipping_location.city;
            if (shipping_location.zip_code)
              form_one.new_zip_code = shipping_location.zip_code;
          }
        }
      }
    } else {
      if (offer_details) {
        if (offer_details.sale_flow_type) {
          form_one.sale_type = MYSsaleFlowApiType[offer_details.sale_flow_type];
          if (
            offer_details.sale_flow_type === MYSsaleFlowType.WEIGHT_PER_LOAD
          ) {
            if (offer_details.loads)
              form_one.min_weight_load = offer_details.loads;
          }
          if (offer_details.sale_flow_type === MYSsaleFlowType.TOTAL_WEIGHT) {
            if (offer_details.material_packing_type)
              form_one.material_type = offer_details.material_packing_type;
          }
        }
        if (offer_details.sell_type) {
          form_one.sell_option = offer_details.sell_type.toLowerCase();
          if (offer_details.freight_type) {
            form_one.freight_option = `${offer_details.freight_type} ${offer_details.sell_type}`;
            if (offer_details.offer_details.shipment_information) {
              const shipment_information =
                offer_details.offer_details.shipment_information;
              if (
                offer_details.freight_type.toUpperCase() === "FAS" &&
                offer_details.sell_type.toLowerCase() === "export"
              ) {
                form_one.port = "" + shipment_information.port_id;
              } else if (offer_details.freight_type.toUpperCase() === "FOB") {
                if (shipment_information.preferred_location_id)
                  form_one.pickup_address =
                    shipment_information.preferred_location_id;
              }
              if (
                shipment_information.preferred_location_id === null &&
                shipment_information.port_id === null
              ) {
                if (shipment_information.address)
                  form_one.new_address = shipment_information.address;
                if (shipment_information.state_id)
                  form_one.new_state = shipment_information.state_id;
                if (shipment_information.city)
                  form_one.new_city = shipment_information.city;
                if (shipment_information.zip_code)
                  form_one.new_zip_code = shipment_information.zip_code;
              }
            }
          }
        }
        if (offer_details.min_weight) {
          form_one.min_weight = MYSweightFormatter(
            "" + offer_details.min_weight
          );
        }
        if (offer_details.supplier_weight_unit) {
          form_one.min_weight_unit = "lb";
        }
        if (offer_details.load_info)
          form_one.load_terms = MYSLoadTermsApiData[offer_details.load_info];
      }
    }
    const cachekeyFormOne = MYSgetCachedKey(1, grade_id, zipcode);
    LS.setItem(cachekeyFormOne, form_one);
    dispatch(updateShowDuplicateSale(false));
    triggerHeap();
    nav(path);
  };
  const onClose = () => {
    dispatch(updateShowDuplicateSale(false));
  };

  useEffect(() => {
    clearMYScachedata(grade_id, zipcode);

    //eslint-disable-next-line
  }, []);
  const content = (
    <>
      <Wrapper>
        <p className="desc">{copyText.desc}</p>
        <p className="sub_desc">{copyText.sub_desc}</p>

        <div className="action-btn">
          <Button type="default" onClick={withoutParams} block={true}>
            Clear Parameters
          </Button>
          <Button type="primary" onClick={withParams} block={true}>
            Continue with Parameters
          </Button>
        </div>
      </Wrapper>
    </>
  );

  const mobileView = (
    <HubbitDrawer
      title={title}
      open={open}
      height={"auto"}
      onClose={onClose}
      showchatbot={false}
    >
      {content}
    </HubbitDrawer>
  );

  const desktopView = (
    <Modal
      centered
      title={title}
      open={open}
      width={580}
      footer={[]}
      onCancel={onClose}
    >
      {content}
    </Modal>
  );

  return desktop ? desktopView : mobileView;
};

export default SaleDuplicationPopup;
