import { Col, Modal, Row } from "antd";
import { useAppSelector } from "../../../hooks/reduxHooks";
import CancelOfferModalForm from "./CancelOfferModalForm/CancelOfferModalForm";

type ModalProp = {
  closeModal: Function;
  offer_id: number | undefined;
};

export const CancelOfferModal = (props: ModalProp) => {
  const { cancelOfferModalSt } = useAppSelector(
    (state) => state.OffersActionsReducer
  );

  const title = `Cancel Price Request #${props.offer_id}`;

  return (
    <Modal
      title={title}
      centered
      destroyOnClose={true}
      className="cancel-offer-modal"
      open={cancelOfferModalSt}
      width={600}
      onOk={() => props.closeModal()}
      onCancel={() => props.closeModal()}
      footer={[]}
    >
      <Row>
        <Col xs={24}>
          <CancelOfferModalForm offer_id={props.offer_id} />
        </Col>
      </Row>
    </Modal>
  );
};
