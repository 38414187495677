import styled from "styled-components";

export const HistoryWrapper = styled.div`
  font-family: "Gotham";
  overflow-y: auto;
  .ant-steps-item-title {
    color: var(--NEUTRAL-4) !important;
    b {
      font-weight: 350;
      font-family: "GothamMedium";
    }
  }
  .ant-steps-item-description {
    color: var(--NEUTRAL-3) !important;
  }
  .hide-history-stepper {
    .ant-steps-item-icon {
      display: none;
    }
  }
  .ant-steps-item {
    .ant-steps-item-description {
      padding-bottom: 0 !important;
    }
  }
  .ant-card-body {
    padding: 1.6rem;
    border: 0.1rem solid var(--NEUTRAL-2);
    border-radius: 1rem;
  }
  .heading {
    padding: 0 0 1.2rem 0.4rem;
    color: var(--NEUTRAL-4);
    font-weight: 350;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: "GothamMedium";
  }
  .ant-steps-item-content {
    background: var(--NEUTRAL-1);
    margin-bottom: 1rem;
    border-radius: 1rem;
    padding: 1rem;
  }
  .ant-steps-item-tail::after,
  .ant-steps-icon-dot {
    background: var(--PRIMARY) !important;
  }
  .ant-steps-item-tail {
    padding: 0 !important;
  }
  .ant-steps-icon-dot {
    height: 1rem !important;
    width: 1rem !important;
  }
  .ant-steps-item-description {
    font-family: "Gotham";
    font-size: 1.2rem;
  }
  .ant-steps-item-title {
    font-family: "Gotham";
    font-size: 1.4rem;
    line-height: 140% !important;
    b {
      color: var(--NEUTRAL-4);
      display: inline-block;
    }
    b:after {
      content: "";
      width: 0px;
      height: 1px;
      display: block;
      background: var(--NEUTRAL-4);
      transition: 300ms;
    }
    b:hover:after {
      width: 100%;
    }
  }
  .all-transactions-btn {
    color: var(--NEUTRAL-4);
    cursor: pointer;
    padding: 1rem 0 0 1.8rem;
    font-family: "GothamMedium";
    background: transparent;
    border: none;
  }
  .transaction-chat-btn {
    width: 100%;
    margin: 2.2rem 0.4rem 0.4rem 0.4rem;
    height: 4.4rem;
    border-radius: 1rem;
    box-shadow: none;
    background-color: var(--PRIMARY);
    font-family: "GothamMedium";
  }
  .transaction-chat-btn:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      var(--PRIMARY);
  }
`;
