import { useEffect, useState } from "react";
import { SCREEN_TYPE } from "../utils/constant";

const useScreenType = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, [width]);

  return width >= 1280
    ? SCREEN_TYPE.DESKTOP
    : width > 840
    ? SCREEN_TYPE.LAPTOP
    : width > 480
    ? SCREEN_TYPE.TABLET
    : SCREEN_TYPE.MOBILE;
};

export default useScreenType;
