import { Button } from "antd";
import React from "react";
import { IOrderDetails } from "../../interfaces/OrdersInterface";
import { roles, status } from "../../utils/constant";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { updateShowDuplicateSale } from "../../store/features/OffersActions.action";

const OrderActions = (props: { details: IOrderDetails }) => {
  const { details } = props;
  const dispatch = useAppDispatch();
  const isCancelled = details?.status === status.canceled;

  const isAdmin =
    details?.trade_history?.[0].action_initiated_by === roles.ADMIN;

  const traderName = details?.trader?.name;
  const endnotes = details?.end_note;

  const onSellAgain = () => {
    dispatch(updateShowDuplicateSale(true));
  };
  return (
    <>
      <div className="action-text">
        {isCancelled && (
          <>
            <p className="light-text">
              {`This Sale was canceled by ${
                isAdmin ? "Admin" : `Trader ${traderName}`
              }${endnotes ? "with following reason:" : "."}`}
            </p>
            {endnotes && <p className="reason-text">{endnotes}</p>}
          </>
        )}
      </div>
      <div className="action-button-wrapper">
        <Button
          className="button"
          size="large"
          type="primary"
          block
          onClick={onSellAgain}
        >
          Sell Again
        </Button>
      </div>
    </>
  );
};

export default OrderActions;
