const theme = {
    default: {
        "--PRIMARY": "#285E5F",
        "--SECONDARY": "#64C492",
        "--NEUTRAL-1": "#F2F5F9",
        "--NEUTRAL-2": "#E0E6F0",
        "--NEUTRAL-3": "#627078",
        "--NEUTRAL-4": "#283238",
        "--AUXILIARY-1": "#E2807D",
        "--AUXILIARY-2": "#D6605C",
    },
};
export default theme;