import React, { useEffect } from "react";
import { ApiURL, SCREEN_TYPE, USER_NOT_FOUND, status } from "./utils/constant";
import { GET } from "./services/api";
import { logoutUser } from "./utils/utils";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import theme from "./assets/theme";
import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import useScreenType from "./hooks/useScreenType";
import { updateToggleLoader } from "./store/features/Common.slice";
import { QB_initiat } from "./utils/QuickBlox/QB_initiat";

const themeWrapper: any = document.querySelector("html");

const InitializeData = () => {
  for (const [key, value] of Object.entries(theme["default"])) {
    themeWrapper.style.setProperty(key, value);
  }
  const { isAuthenticated, token = "" } = useAppSelector(
    (state) => state.AuthReducer
  );
  const { session_id, chatbot_id } = useAppSelector(
    (state) => state.MessagesReducer
  );
  const screenType = useScreenType();
  const mobileView =
    screenType === SCREEN_TYPE.TABLET || screenType === SCREEN_TYPE.MOBILE;

  const dispatch = useAppDispatch();

  const tagManagerArgs: TagManagerArgs = {
    gtmId: "GTM-TSTC59QC",
  };
  useEffect(() => {
    if (isAuthenticated && token !== "") {
      dispatch(GET("usersession", ApiURL.USER_MESSAGE_SESSION)()).catch(
        (error) => {
          // if the session is invalid then route to login page
          if (error.error === USER_NOT_FOUND) logoutUser();
        }
      );

      dispatch(GET("common/appAlerts", ApiURL.APP_ALERTS)());
      dispatch(GET("userlist", ApiURL.MESSAGE_USER_LIST)());
      dispatch(GET("notificationList", ApiURL.NOTIFICATION_LIST + "&page=1")());
      dispatch(GET("messageQuestion", ApiURL.MESSAGE_QUESTIONS)());
      dispatch(
        GET("grades/gradeCategory", ApiURL.GRADE_CATEGORY + "?grades=false")()
      );
      if (mobileView) dispatch(updateToggleLoader(true));
      dispatch(
        GET(
          "offersListHome",
          `${ApiURL.OFFERS_LIST}/?page=1&size=${2}&status=${
            status.waiting_for_supplier
          }`
        )()
      ).then(() => {
        if (mobileView) dispatch(updateToggleLoader(false));
      });
    }
    TagManager.initialize(tagManagerArgs);
    //eslint-disable-next-line
  }, [dispatch, isAuthenticated, token]);

  useEffect(() => {
    if (session_id !== "" && chatbot_id !== null) {
      QB_initiat(session_id);
    }
  }, [session_id, chatbot_id]);

  return <></>;
};

export default InitializeData;
