import Card from "antd/es/card/Card";
import OfferOrderHeader from "../../components/OfferOrderHeader/OfferOrderHeader";
import OfferOrderSummary from "../../components/OfferOrderSummary/OfferOrderSummary";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import History from "../../components/History/History";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GET } from "../../services/api";
import { ApiURL, CUSTOM_ERROR_MSG, ErrorCodes } from "../../utils/constant";
import { ModalLoader } from "../../components/Loader/Loader";
import OfferDeliveryInformation from "../../components/OfferDeliveryInformation/OfferDeliveryInformation";
import OffersShipmentImages from "../../components/OffersShipmentImages/OffersShipmentImages";
import OffersNotes from "../../components/OffersNotes/OffersNotes";
import useUpdateHeaderName from "../../hooks/useUpdateHeaderName";
import OfferOrderAction from "../../components/OfferOrderActions/OfferOrderAction";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { getLocalTimeDate } from "../../utils/DateNTimeConvertor";
import ContactTrader from "../../components/ContactTrader/ContactTrader";
import { setOfferOrderSubmitted } from "../../store/features/CreateOfferOrderFormReducer.slice";
import { order_details } from "../../store/features/Order.slice";
import {
  formatCurrency,
  formatLBCurrency,
  formatTotalPriceCurrency,
} from "../../utils/utils";
import {
  setBackButtonData,
  setShowBackButton,
} from "../../store/features/Common.slice";
import { OrderDetailsWrapper } from "./OrderDetailsStyled";
import { LeftArrowIcon } from "../../components/Icons/Icons";
import { BackBtnWrapper } from "../../utils/styleConsts";
import { MYSgetFreightType, MYSsaleFlowType } from "../MakeYourSale/MYSconst";

const OrderDetails = () => {
  const { OrderReducer } = useAppSelector((state) => state);
  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { selected_order_details, order_details_loader } = OrderReducer;
  const {
    unit_price,
    min_weight,
    load_info,
    loads,
    total_price,
    shipping_location,
    created_at,
    shipment_type,
    grade_images,
    additional_info,
    grade,
    sell_type,
    freight_type,
    sale_flow_type,
  } = selected_order_details || order_details;
  localStorage.setItem("card_path", "Sell Again");

  let IsWeightPerLoad = false;
  if (
    selected_order_details?.sale_flow_type === MYSsaleFlowType.WEIGHT_PER_LOAD
  ) {
    IsWeightPerLoad = true;
  }
  useUpdateHeaderName(`Sales #${selected_order_details?.id || ""}`);
  dispatch(setOfferOrderSubmitted(false));
  const navigate = useNavigate();

  let freight_type_copy = MYSgetFreightType({
    freight_type,
    sale_flow_type,
    sell_type,
  });

  const summaryDetails: any = [
    {
      label: "Created",
      value: dayjs(getLocalTimeDate(created_at)).format("DD MMM YYYY"),
    },
    {
      label: "Sell Option",
      value: sell_type,
    },
    {
      label: "Frieght Options",
      value: freight_type_copy,
    },

    {
      label: "Load Terms",
      value: load_info || "",
    },
    {
      label: shipment_type?.includes("FOB")
        ? "Pickup Location"
        : "Delivery Location",
      value:
        `${shipping_location?.city} ${
          shipping_location.state_code
            ? `(${shipping_location.state_code})`
            : ""
        }` || "N/A",
    },
    {
      label: IsWeightPerLoad ? "Weight Per Load" : "Min Weight",
      value: `${min_weight.toLocaleString()} lbs` || "",
    },
    {
      label: "Total Loads",
      value: loads || "",
    },
    {
      label: "Total weight",
      value: `${((min_weight || 0) * (loads || 0)).toLocaleString()} lbs`,
    },
    {
      label: "Unit Pricing",
      value: (
        <b>{` ${
          grade?.unit.toLowerCase() === "lb"
            ? formatLBCurrency(unit_price)
            : formatCurrency(unit_price)
        }/${grade?.unit.toLowerCase()}`}</b>
      ),
    },
    {
      label: "Total Price",
      value: ` ${formatTotalPriceCurrency(total_price)}`,
      bold: true,
    },
  ];

  useEffect(() => {
    dispatch(setShowBackButton({ show: true }));
    dispatch(setBackButtonData({ page: "Sales", path: "/home/orders" }));
    dispatch(GET("order/details", `${ApiURL.ORDER_LIST}${id}/`)()).then(
      (data) => {
        data?.payload?.status === ErrorCodes.PERMISSION_DENIED
          ? setErrorMsg(CUSTOM_ERROR_MSG.ORDER_PERMISSION_DENIED)
          : setErrorMsg(CUSTOM_ERROR_MSG.ORDER);
      }
    );
  }, [dispatch, id]);

  const items = selected_order_details?.trade_history?.map((item) => ({
    title: parse(sanitizeHtml(item?.status_message)),
    description: dayjs(getLocalTimeDate(item?.date)).format(
      "DD MMM YYYY hh:mm A"
    ),
  }));

  const onHandleBack = () => {
    navigate("/home/orders");
  };
  return (
    <>
      <BackBtnWrapper
        onClick={onHandleBack}
        icon={<LeftArrowIcon stroke="var(--NEUTRAL-3)" />}
      >
        Back to Sales
      </BackBtnWrapper>
      <OrderDetailsWrapper
        className={!selected_order_details?.id ? "error" : ""}
      >
        <ModalLoader show={!order_details_loader} />
        <Card>
          {selected_order_details?.id ? (
            <>
              <OfferOrderHeader
                offer_details={selected_order_details}
                flow={"orders"}
              />
              <div className="order_info">
                <OfferOrderSummary details={summaryDetails} type="order" />
                <div className="right-pane">
                  <OfferDeliveryInformation
                    shipment_info={shipping_location}
                    shipment_schedule={shipping_location?.shipping_schedule}
                    shipment_type={selected_order_details.shipment_type}
                    toAddress={selected_order_details.to_address}
                  />
                  <OffersShipmentImages images={grade_images} />
                  <OffersNotes notes={additional_info} />
                  <div className="offerchatblock">
                    <ContactTrader
                      detail_type="order"
                      relavant_id={selected_order_details.id}
                      dialog_id={selected_order_details.dialog_id}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p className="noDataFoundMsg">{errorMsg}</p>
          )}
        </Card>

        {selected_order_details?.id && (
          <div className="actions">
            <Card>
              <OfferOrderAction Type="order" />
            </Card>

            <div className="offerchatblockresponsive">
              <ContactTrader
                detail_type="order"
                relavant_id={selected_order_details.id}
                dialog_id={selected_order_details.dialog_id}
              />
            </div>
            <History items={items || []} heading={"Sale History"} />
          </div>
        )}
      </OrderDetailsWrapper>
    </>
  );
};

export default OrderDetails;
