import Button from "antd/es/button";
import styled from "styled-components";
import { fontBold30, fontMedium28 } from "../../utils/styleConsts";

export const OnboardingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 21.9%;
  left: 22.2%;
  background: #ffffff;
  box-shadow: 0rem 0.4rem 4rem rgb(0 0 0 / 5%);
  border-radius: 2rem;
  @media (min-width: 842px) {
    width: 100%;
    height: 100%;
    max-height: 46rem;
    max-width: 80rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .textContainer {
    width: 50%;
    background-color: white;
    border-radius: 2rem 0rem 0rem 2rem;
    padding: 0 3rem;
    display: grid;
    z-index: 9;
  }
  .bottonbutton {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .imageContainer {
    position: relative;
    width: 50%;
    background: var(--PRIMARY);
    border-radius: 0rem 2rem 2rem 0rem;
    &.hideoverflow {
      overflow: hidden;
    }
  }
  .hideoverflow {
    overflow: hidden;
  }
  .contentdiv {
    min-height: 23rem;
    padding-top: 1rem;
    display: grid;
    .stepper-icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 4.4rem;
      margin-top: 5rem;
      @media (max-width: 841px) {
        margin-top: 2.4rem;
        padding: 0rem;
      }
    }
  }
  .logoContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 841px) {
      display: none;
    }
  }
  .forwardButton {
    cursor: pointer;
    font-family: "GothamMedium";
    font-weight: 325;
    font-size: 1.4rem;
    @media (max-width: 841px) {
      font-size: 1.6rem;
    }
  }
  @media (max-width: 841px) {
    flex-direction: column-reverse;
    height: 85%;
    width: 100%;
    top: 10rem;
    left: 0%;
    .imageContainer {
      width: 100%;
      height: 100%;
      background: var(--PRIMARY);
      padding: 1%;
      border-radius: 2rem;
      img {
        max-width: 37.5rem;
      }
    }
    .textContainer {
      width: 100%;
      height: 29rem;
      background-color: white;
      border-radius: 2rem;
      padding: 0% 3%;
      position: absolute;
      padding: 0rem 1.6rem;
      z-index: 2;
    }
  }
`;
export const TitleDiv = styled.div`
  ${fontBold30}
  color: var(--NEUTRAL-4);
  @media (max-width: 841px) {
    margin-top: 1rem;
    ${fontMedium28}
  }
`;
export const BodyContentDiv = styled.div`
  font-family: "Gotham";
  font-style: normal;
  font-weight: 325;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--NEUTRAL-3);
  height: 8rem;
  @media (max-width: 841px) {
    min-height: 6rem;
  }
`;
export const HomeButton = styled(Button)`
  height: 4.4rem;
  width: 45%;
  border-radius: 10px;
  font-family: GothamMedium;
  font-size: 1.4rem;
  font-weight: 350;
  line-height: 20px;
  text-align: center;
  box-shadow: none;
  justify-content: flex-end;
`;
export const MockupImgFirstDesktop = styled.img`
  max-width: 60rem;
  max-height: 36rem;
  position: absolute;
  right: -22%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;
export const MockupImgFirstMobile = styled.img`
  max-width: 90%;
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
`;
export const MockupImgSecondMobile = styled.img`
  max-width: 90%;
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const MockupImgSecond1Desktop = styled.img`
  position: absolute;
  top: 0;
  right: 0%;
  height: 100%;
`;
export const MockupImgSecond2Desktop = styled.img`
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  max-width: 26.8rem;
  max-height: 14rem;
  height: 100%;
  box-shadow: 0px -11px 60px 0px #00000021;
`;
export const MockupImgThird1Desktop = styled.img`
  position: absolute;
  top: 3rem;
  left: 2rem;
  max-height: 30.2rem;
  // max-height: 29.6rem;
  z-index: 1;
`;
export const MockupImgThird2Desktop = styled.img`
  position: absolute;
  bottom: 3rem;
  right: 2rem;
  // max-height: 14.3rem;
  max-height: 20rem;
  box-shadow: 0px -11px 60px 0px #00000021;

  z-index: 2;
`;
export const MockupImgThirdMobile = styled.img`
  max-width: 90%;
  position: absolute;
  top: 1rem;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const MockupImgFourthDesktop = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 29.3rem;
  transform: translate(-50%, -50%);
  z-index: 1;
`;
export const MockupImgFourthMobile = styled.img`
  max-width: 90%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const MockupImgFinal = styled.img`
  width: 22rem;
  height: 54.6rem;
  position: absolute;
  left: 65%;
  top: -16.6%;
  z-index: 1;
  filter: drop-shadow(2rem -1rem 2rem rgba(3, 30, 30, 0.1));
  @media (max-width: 841px) {
    left: 53%;
    transform: translate(-50%);
    right: 0;
    top: -4%;
    margin-left: auto;
    margin-right: auto;
    width: 62%;
    height: 97%;
  }
`;
