import { MessageDialogListInterface } from "../../interfaces/MessagesInterface";
import {
  updateMessageloader,
  updateUserId,
} from "../../store/features/Messages.slice";
import store from "../../store/store";
import { QB } from "../QuickBlox_initiation";
import QB_connect from "./QB_connect";

export const QB_initiat = (token: string) => {
  if (token) {
    QB.startSessionWithToken(token, function (err: any, mySession: any) {
      if (err) {
        console.log("Error in start session with token - ", token, err);
      } else {
        store.dispatch(updateUserId(mySession.session.user_id));
        const cache_dailog = localStorage.getItem("cache_dailog");
        const chat_list: null | {
          [user_id: number]: { [id: string]: MessageDialogListInterface };
        } = cache_dailog !== null ? JSON.parse(cache_dailog) : {};
        setTimeout(() => {
          if (chat_list && chat_list[mySession.session.user_id]) {
            store.dispatch(updateMessageloader(false));
          }
        }, 5000);
        QB_connect(mySession);
      }
    });
  }
};
