import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const ErrorWrapper = styled.section`
  width: 86%;
  height: 100%;
  display: table;
  text-align: center;
  color: var(--NEUTRAL-4);
  font-size: 1.4rem;
  height: 100%;
  margin: auto;
  div {
    display: table-cell;
    vertical-align: middle;
  }
  h3 {
    font-size: 2rem;
    font-family: "GothamMedium";
    text-align: center;
    font-weight: 350;
  }
  p {
    margin: 2rem 0;
  }
  p:last-child {
    cursor: pointer;
  }
  @media (min-width: 540px) {
    width: 65%;
  }
  @media (min-width: 768px) {
    width: 53%;
  }
  @media (min-width: 1024px) {
    width: 38%;
  }
  @media (min-width: 1440px) {
    width: 22%;
  }
`;

const PageNotFound = (props: { isInnerRoute: boolean }) => {
  const navigate = useNavigate();

  return (
    <ErrorWrapper>
      <div>
        <h3>Page not found</h3>
        <p>This page is missing or you assembled the link incorrectly</p>
        {props.isInnerRoute && (
          <Button
            block
            size="large"
            type="primary"
            onClick={() => navigate("/home")}
          >
            Go to Home
          </Button>
        )}
      </div>
    </ErrorWrapper>
  );
};

export default PageNotFound;
