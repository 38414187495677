import { useState } from "react";
import { StepProps } from "antd";
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Steps from "antd/lib/steps";
import { HistoryWrapper } from "./HistoryStyled";

export default function History(props: {
  heading: string;
  items: StepProps[];
}) {
  const { heading, items } = props;
  const [isAllTransactions, setIsAllTransactions] = useState(false);
  return (
    <HistoryWrapper>
      <Card>
        <h4 className="heading">{heading}</h4>
        <Steps
          className={items?.length <= 1 ? "hide-history-stepper" : ""}
          progressDot
          current={isAllTransactions ? items?.length - 1 : 3}
          direction="vertical"
          items={isAllTransactions ? items : items?.slice(0, 3)}
        />
        {items?.length > 3 ? (
          <button
            onClick={() => setIsAllTransactions(!isAllTransactions)}
            className="all-transactions-btn btn-link"
          >
            {isAllTransactions
              ? "Hide All Transactions"
              : "Show All Transactions"}
          </button>
        ) : (
          ""
        )}
        {heading !== "Sale History" && heading !== "Price Request History" && (
          <Button className="transaction-chat-btn" type="primary">
            View Transaction Chat
          </Button>
        )}
      </Card>
    </HistoryWrapper>
  );
}
