import { useEffect, useState } from "react";
import {
  FirstStepperIcon,
  FourthStepperIcon,
  OnboardingLogo,
  OnboardingNextIcon,
  SecondStepperIcon,
  ThirdStepperIcon,
} from "../../components/Icons/Icons";
import splash_1_desktop from "../../assets/images/splashScreen/Splash_Screen_1_Desktop.png";
import splash_1_mobile from "../../assets/images/splashScreen/Splash_Screen_1_Mobile.png";
import splash_2_1_desktop from "../../assets/images/splashScreen/Splash_Screen_2.1_Desktop.png";
import splash_2_2_desktop from "../../assets/images/splashScreen/Splash_Screen_2.2_Desktop.png";
import splash_2_mobile from "../../assets/images/splashScreen/Splash_Screen_2_Mobile.png";
import splash_3_1_desktop from "../../assets/images/splashScreen/Splash_Screen_3.1_Desktop.png";
import splash_3_2_desktop from "../../assets/images/splashScreen/Splash_Screen_3.2_Desktop.png";
import splash_3_mobile from "../../assets/images/splashScreen/Splash_Screen_3_Mobile.png";
import splash_4_desktop from "../../assets/images/splashScreen/Splash_Screen_4_Desktop.png";
import splash_4_mobile from "../../assets/images/splashScreen/Splash_Screen_4_Mobile.png";
import { useNavigate } from "react-router-dom";
import {
  BodyContentDiv,
  HomeButton,
  MockupImgFirstDesktop,
  MockupImgFirstMobile,
  MockupImgFourthDesktop,
  MockupImgFourthMobile,
  MockupImgSecond1Desktop,
  MockupImgSecond2Desktop,
  MockupImgSecondMobile,
  MockupImgThird1Desktop,
  MockupImgThird2Desktop,
  MockupImgThirdMobile,
  OnboardingWrapper,
  TitleDiv,
} from "./SplashScreenStyled";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { showSignupSuccessScreen } from "../../store/features/Otp.slice";
import useScreenType from "../../hooks/useScreenType";
import { SCREEN_TYPE } from "../../utils/constant";
import { SplashScreenText } from "./SplashScreenConst";

export const SplashScreen = () => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const screenType = useScreenType();
  const desktop =
    screenType === SCREEN_TYPE.DESKTOP || screenType === SCREEN_TYPE.LAPTOP;
  useEffect(() => {
    dispatch(showSignupSuccessScreen(false));
  }, [dispatch]);
  const FirstPage = () => {
    return (
      <div className="contentdiv">
        <TitleDiv>{SplashScreenText.page_first.title}</TitleDiv>
        <BodyContentDiv>{SplashScreenText.page_first.content}</BodyContentDiv>
        <div className="stepper-icon">
          <FirstStepperIcon />
          <div className="forwardButton" onClick={() => setStep(step + 1)}>
            <div>
              <OnboardingNextIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SecondPage = () => {
    return (
      <div className="contentdiv hideoverflow">
        <TitleDiv>{SplashScreenText.page_second.title}</TitleDiv>
        <BodyContentDiv>{SplashScreenText.page_second.content}</BodyContentDiv>
        <div className="stepper-icon">
          <SecondStepperIcon />
          <div className="forwardButton" onClick={() => setStep(step + 1)}>
            <div>
              <OnboardingNextIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ThirdPage = () => {
    return (
      <div className="contentdiv">
        <TitleDiv>{SplashScreenText.page_third.title}</TitleDiv>
        <BodyContentDiv>{SplashScreenText.page_third.content}</BodyContentDiv>
        <div className="stepper-icon">
          <ThirdStepperIcon />
          <div className="forwardButton" onClick={() => setStep(step + 1)}>
            <div>
              <OnboardingNextIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const FourthPage = () => {
    return (
      <div className="contentdiv">
        <TitleDiv>{SplashScreenText.page_fourth.title}</TitleDiv>
        <BodyContentDiv>{SplashScreenText.page_fourth.content}</BodyContentDiv>
        <div className="stepper-icon">
          <FourthStepperIcon />
        </div>
      </div>
    );
  };

  const conditionalComponent = () => {
    switch (step) {
      case 0:
        return <FirstPage />;
      case 1:
        return <SecondPage />;
      case 2:
        return <ThirdPage />;
      case 3:
        return <FourthPage />;
      default:
        return <FirstPage />;
    }
  };

  const navFromSplashScreen = () => {
    navigate("/home/grades");
  };

  let stepDom = <></>;
  switch (step) {
    case 0:
      stepDom = desktop ? (
        <MockupImgFirstDesktop src={splash_1_desktop} />
      ) : (
        <MockupImgFirstMobile src={splash_1_mobile} />
      );
      break;
    case 1:
      stepDom = desktop ? (
        <>
          <MockupImgSecond1Desktop src={splash_2_1_desktop} />
          <MockupImgSecond2Desktop src={splash_2_2_desktop} />
        </>
      ) : (
        <>
          <MockupImgSecondMobile src={splash_2_mobile} />
        </>
      );
      break;

    case 2:
      stepDom = desktop ? (
        <>
          <MockupImgThird1Desktop src={splash_3_1_desktop} />;
          <MockupImgThird2Desktop src={splash_3_2_desktop} />;
        </>
      ) : (
        <MockupImgThirdMobile src={splash_3_mobile} />
      );
      break;

    case 3:
      stepDom = desktop ? (
        <MockupImgFourthDesktop src={splash_4_desktop} />
      ) : (
        <MockupImgFourthMobile src={splash_4_mobile} />
      );

      break;

    default:
      stepDom = desktop ? (
        <MockupImgFirstDesktop src={splash_1_desktop} />
      ) : (
        <MockupImgFirstMobile src={splash_1_mobile} />
      );
      break;
  }
  return (
    <OnboardingWrapper>
      <div className="textContainer">
        <div className="logoContainer">
          <OnboardingLogo />
        </div>
        <>{conditionalComponent()}</>
        {step === 3 && (
          <div className="bottonbutton">
            <HomeButton
              onClick={navFromSplashScreen}
              type="primary"
              className="home-button"
              block={true}
            >
              Get Started
            </HomeButton>
          </div>
        )}
      </div>
      <div
        className={`imageContainer ${
          [1, 2, 3].includes(step) ? "hideoverflow" : ""
        } ${[0].includes(step) && !desktop ? "hideoverflow" : ""}`}
      >
        {stepDom}
      </div>
    </OnboardingWrapper>
  );
};
