import { MessageDialogListInterface } from "../../interfaces/MessagesInterface";
import { updateDailogId } from "../../store/features/MakeYourSale.slice";
import {
  updateChatHistory,
  updateCreateNewChatFullFilled,
  updateCreateNewChatPending,
  updateCreateNewChatRejected,
  updateUploadPendingCount,
} from "../../store/features/Messages.slice";
import store from "../../store/store";
import { QB } from "../QuickBlox_initiation";
import QB_sendGroupImg from "./QB_sendGroupImg";
import QB_sendMessage from "./QB_sendMessage";
import QB_sendSystemMessage from "./QB_sendSystemMessage";

const QB_onCreateDialog = ({
  name = "",
  occupants_ids = [],
  permitted_user = [],
  groupType = "",
  unique_id = "",
  message = "",
  topic_id = 0,
  images = [],
  msg_style = "",
  mys_type = "",
  mys_count = "",
  mys_grade_name = "",
}: {
  name: string;
  occupants_ids: number[];
  permitted_user: number[];
  groupType: string;
  unique_id: string;
  message: string;
  topic_id?: number;
  images?: File[];
  msg_style?: string;
  mys_type?: string;
  mys_grade_name?: string;
  mys_count?: string;
}) => {
  const params = {
    type: 2,
    occupants_ids,
    name: name,
    data: {
      class_name: "dialog_additional_info",
      permitted_user: permitted_user,
      topic_type: groupType,
      topic_id: topic_id,
      unique_id: unique_id,
    },
  };
  store.dispatch(updateCreateNewChatPending());
  QB.chat.dialog.create(
    params,
    function (error: any, dialog: MessageDialogListInterface) {
      if (!error) {
        store.dispatch(
          updateChatHistory({
            id: dialog._id,
            chats: [],
          })
        );
        occupants_ids.forEach((ids) => {
          QB_sendSystemMessage(ids, dialog._id);
        });
        if (mys_type !== "") store.dispatch(updateDailogId(dialog._id));

        store.dispatch(updateCreateNewChatFullFilled(dialog));
        if (images.length > 0) {
          store.dispatch(
            updateUploadPendingCount({
              id: dialog._id,
              count: images.length || 0,
            })
          );
          QB_sendGroupImg({
            attachmentFiles: images,
            dailog_id: dialog._id,
            opponentId: dialog.occupants_ids,
            caption: message,
            msg_style,
            mys_type,
            mys_count,
            mys_grade_name,
          });
        }
        if (images.length === 0 && message) {
          QB_sendMessage({
            text: message,
            dialog_id: dialog._id,
            opponentId: dialog.occupants_ids,
            type: dialog.type,
            topic_type: "Adhoc",
            msg_style,
            mys_type,
            mys_count,
            mys_grade_name,
          });
        }
      } else store.dispatch(updateCreateNewChatRejected());
    }
  );
};

export default QB_onCreateDialog;
