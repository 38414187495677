import React from "react";
import styled from "styled-components";
import Tag from "../Tag/Tag";
import dayjs from "dayjs";
import { IOfferDetailsData } from "../../interfaces/IOfferInterface";
import { SCREEN_TYPE, status } from "../../utils/constant";
import { IOrderDetails } from "../../interfaces/OrdersInterface";
import { getLocalTimeDate } from "../../utils/DateNTimeConvertor";
import Button from "antd/es/button";
import { LeftArrowIcon } from "../Icons/Icons";
import { formatTransactionWeight, getShipmentType } from "../../utils/utils";
import { fontMedium16, fontMedium20 } from "../../utils/styleConsts";
import useScreenType from "../../hooks/useScreenType";
import GradeIcons from "../Icons/GradeIcons";

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 65% auto;
  align-items: center;
  justify-content: space-between;
  font-family: "Gotham";
  border-bottom: 1px solid var(--NEUTRAL-2);
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    display: flex;
    gap: 1.2rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .title {
    display: grid;
    grid-template-columns: 5rem auto;
    height: 6rem;
    max-height: 20rem;
    gap: 1rem;
    color: var(--NEUTRAL-4);
    align-items: center;
    h3 {
      ${fontMedium20}
      @media (max-width: 840px) {
        ${fontMedium16}
      }
    }
    .img-holder {
      max-width: 5rem;
      max-height: 5rem;
      min-width: 5rem;
      min-height: 5rem;
      width: 100%;
      height: 100%;
      background-color: var(--NEUTRAL-1);
      border: 0.1rem solid var(--NEUTRAL-2);
      display: grid;
      place-items: center;
      border-radius: 1rem;
      overflow: hidden;
      img {
        cursor: pointer;
        max-width: 5rem;
        max-height: 5rem;
        border-radius: 1rem;
      }
    }
  }
  .date {
    font-size: 1.2rem;
    color: var(--NEUTRAL-3);
  }
  .status {
    span {
      color: var(--NEUTRAL-3);
      font-family: "GothamMedium";
    }
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  .expires-by {
    color: var(--ERROR);
    font-size: 14px;
    font-family: GothamMedium;
    width: 100%;
    .expires-by-flex {
      width: 100%;
      display: flex;
      gap: 0.5rem;
      @media (max-width: 769px) {
        justify-content: space-between;
      }
    }
  }
`;

const OfferOrderHeader = (props: {
  offer_details?: IOfferDetailsData | IOrderDetails | null;
  flow?: string;
}) => {
  const screenType = useScreenType();
  const desktop =
    screenType === SCREEN_TYPE.DESKTOP || screenType === SCREEN_TYPE.LAPTOP;
  const { offer_details: details } = props;

  let date = null;

  if (details?.offer_details?.expiry_at) {
    date = dayjs(getLocalTimeDate(details?.offer_details?.expiry_at)).format(
      "DD MMM YYYY hh:mm A"
    );
  }

  let offerCity = "NA";
  let orderCity = "NA";

  const isOffer = props.flow !== "orders";

  if (details?.offer_details?.shipment_information?.city)
    offerCity = details?.offer_details?.shipment_information?.city;

  if (details?.shipping_location?.city)
    orderCity = details?.shipping_location?.city;

  const title = `${details?.grade.name} / ${formatTransactionWeight(
    details?.min_weight || 0
  )?.toLocaleString()} / ${isOffer ? offerCity : orderCity} / ${
    !isOffer ? details?.shipment_type : getShipmentType(details?.shipment_type)
  }`;
  let IconBasedOnGradeName = "OtherIcon";
  if (isOffer && details?.grade_subcategory_1_info.id) {
    const sub_id = details?.grade_subcategory_1_info.id;

    IconBasedOnGradeName =
      sub_id < 10 && sub_id !== null ? "Icon" + sub_id : "OtherIcon";
  }
  if (!isOffer && details?.grade) {
    if (details?.grade?.category_id) {
      const sub_id = details?.grade.category_id;
      IconBasedOnGradeName =
        sub_id < 10 && sub_id !== null ? "Icon" + sub_id : "OtherIcon";
    }
  }
  // details.sub_category_1_id < 10 && props.sub_category_1_id !== null
  //   ? "Icon" + props.sub_category_1_id
  //   : "OtherIcon";
  let showExpiry =
    isOffer &&
    [status.waiting_for_supplier, status.waiting_for_trader].includes(
      "" + details?.status
    ) &&
    date
      ? true
      : false;

  const expiryDateDom = (
    <div className="expires-by date">
      <div className="expires-by-flex">
        <div>Expires by</div>
        <div>{date}</div>
      </div>
    </div>
  );

  return (
    <>
      <HeaderWrapper>
        <div className="title">
          {false && (
            <Button
              onClick={() => {}}
              icon={<LeftArrowIcon />}
              className="mobile-back-btn"
            />
          )}
          <div className="img-holder">
            {details?.grade.image ? (
              <img src={details?.grade.image} alt="grade" />
            ) : (
              GradeIcons.get(IconBasedOnGradeName)()
            )}
          </div>
          <div className="text-content">
            <h3>{title}</h3>
            {desktop && showExpiry && expiryDateDom}
          </div>
        </div>
        <div className="status">
          <Tag flow={props.flow || ""} content={details?.status || ""} />
        </div>
        {!desktop && showExpiry && expiryDateDom}
      </HeaderWrapper>
    </>
  );
};

export default OfferOrderHeader;
