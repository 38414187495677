export interface IPasswordValidator {
  hasLowercase: boolean;
  hasUpperCase: boolean;
  hasNumbers: boolean;
  hasSixCharacters: boolean;
  hasSpecialCharacters: boolean;
}

export interface IAuthState {
  isAuthenticated: boolean;
  message: string;
  status: StoreStatus;
  user: ICognitoAttributes;
  roles: string[];
  token: string;
  cognitoUser: ICognitoUserAmplify | null;
  signupFormCompletionPercentage: number;
  personalDetailsFormData: any;
  createAccFormSubmitted: boolean;
  companyDetailsFormData: any;
  user_phone_number: string;
}

export interface ILoginForm {
  email: string;
  password: string;
}

export enum StoreStatus {
  Idle = "Idle",
  Loading = "Loading",
  Failed = "Failed",
  Succeeded = "Succeeded",
  Unverified = "Unverified",
}

export interface ICognitoAttributes {
  email: string;
  email_verified: boolean;
  given_name: string;
  family_name: string;
  sub: string;
  "custom:user_role": string;
  phone_number: string;
}

export interface ICognitoUserAmplify {
  username: string;
  attributes: ICognitoAttributes;
  signInUserSession: ICognitoUserSession;
  session: string;
  authenticationFlowType: string;
  challengeName: string;
  challengeParam: {
    userAttributes: ICognitoAttributes;
    requiredAttributes: string[];
  };
  client: any;
  userDataKey: string;
  userName: string;
}

export interface ICognitoUserSession {
  accessToken: {
    jwtToken: string;
  };
  refreshToken: {
    token: string;
  };
}

export interface ICognitoSignupAttributes {
  email: string;
  password?: string;
  given_name: string;
  family_name: string;
  phone_number: number;
  address: string;
  "custom:user_role": string;
  "custom:company_name": string;
  "custom:city": string;
  "custom:state": string;
  "custom:state_id": string;
  "custom:zip_code": number;
  "custom:is_company_verified": string;
}
