import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./awsConfig";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import store from "./store/store";
import ConfigProvider from "antd/es/config-provider";
import { Provider } from "react-redux/es/exports";
import App from "./App";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Gotham",
            colorPrimary: "#285E5F",
          },
        }}
      >
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
