import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/api";
import { ApiURL } from "../../utils/constant";
import { NotificationReducerInterface } from "../../interfaces/NotificationInterface";
const initialState: NotificationReducerInterface = {
  notification_loader: true,
  notificationList: [],
  appId: process.env.REACT_APP_ONE_SIGNAL_ID,
  oneSignalInit: false,
  notificationListHeader: [],
  page: 1,
  size: 10,
  total_pages: 1,
  total_count: 1,
};
const NotificationSlice = createSlice({
  name: "notification_reducer",
  initialState,
  reducers: {
    resetNotificationReducer: () => initialState,
    updateOneSignalInit: (state, action) => {
      state.oneSignalInit = action.payload;
    },
    updateNotificationPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("notificationList", `${ApiURL.NOTIFICATION_LIST}`).pending,
      (state, action) => {
        state.notification_loader = true;
      }
    );
    builder.addCase(
      GET("notificationList", `${ApiURL.NOTIFICATION_LIST}`).fulfilled,
      (state, action) => {
        state.notificationList = action?.payload?.data;
        state.notification_loader = false;
        state.total_pages = action?.payload?.meta.total_pages;
        state.total_count = action?.payload?.meta.total_count;
      }
    );
    builder.addCase(
      GET("notificationList", `${ApiURL.NOTIFICATION_LIST}`).rejected,
      (state, action) => {
        state.notificationList = [];
        state.notification_loader = false;
      }
    );
    builder.addCase(
      GET("notificationListHeader", `${ApiURL.NOTIFICATION_LIST}`).fulfilled,
      (state, action) => {
        state.notificationListHeader = action?.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(
        GET("notification/delete", "").pending,
        GET("notification/archive", "").pending,
        GET("notification/unarchive", "").pending,
        POST("notification/archiveAll", "").pending,
        POST("notification/unarchiveAll", "").pending
      ),
      (state) => {
        state.notification_loader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        GET("notification/delete", "").rejected,
        GET("notification/archive", "").rejected,
        GET("notification/unarchive", "").rejected
      ),
      (state) => {
        state.notification_loader = false;
      }
    );
  },
});

export default NotificationSlice.reducer;
export const {
  resetNotificationReducer,
  updateOneSignalInit,
  updateNotificationPage,
} = NotificationSlice.actions;
