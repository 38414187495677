import { createSlice } from "@reduxjs/toolkit";
import { IPricingReducer } from "../../interfaces/IPricing";
import { GET, POST } from "../../services/api";
import {
  ICategoryGrade,
  ICombinedCategoryGrade,
} from "../../interfaces/IPricing";

const initialState: IPricingReducer = {
  pricing_list: [],
  pricing_list_more: true,
  loader: false,
  meta: {
    total_count: 0,
  },
  active_category_id: null,
  queryparams: {
    type: null,
    query: "",
    category_id: null,
    sub_category_1_id: null,
    sub_category_2_id: null,
    page: 1,
    size: 20,
    order: null,
    sort: null,
    preferred: null,
  },
  categories: [],
  category_all: {
    has_sub_categories: false,
    id: 101,
    name: "All Grades",
    sub_categories: [],
  },
  category_mygrade: {
    has_sub_categories: false,
    id: 102,
    name: "My Grades",
    sub_categories: [],
  },
  sub_category_one: {},
  sub_category_two: {},
  combined_sub_category: {},
};

export const PricingSlice = createSlice({
  name: "PricingReducer",
  initialState,
  reducers: {
    clearPricingList: (state) => {
      state.loader = true;
      state.pricing_list = [];
    },
    updatePricingQueryParams: (state, action) => {
      state.queryparams = { ...state.queryparams, ...action.payload };
    },
    updatePricingQueryParamsWithClear: (state, action) => {
      state.loader = true;
      state.pricing_list = [];
      state.queryparams = { ...state.queryparams, ...action.payload, page: 1 };
    },
    updateActiveCategoryId: (state, action) => {
      state.active_category_id = action.payload;
    },
    removeFavFromList: (state, action) => {
      const id = action.payload;
      for (let i = 0; i < state.pricing_list.length; i++) {
        if (state.pricing_list[i].id === id) {
          state.pricing_list[i].is_preferred = false;
        }
      }
    },
    resetParamsNList: (state) => {
      state.pricing_list = [];
      state.queryparams = initialState.queryparams;
    },

    resetPricingReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GET("pricing/gradeList", "").pending, (state) => {
      state.loader = true;
    });
    builder.addCase(GET("pricing/gradeList", "").fulfilled, (state, action) => {
      state.meta.total_count = action.payload.meta.total_count;
      let pricing_list = [];
      if (state.queryparams.page === 1) {
        pricing_list = [...action.payload.data];
        state.pricing_list = pricing_list;
      } else
        state.pricing_list = [...state.pricing_list, ...action.payload.data];
      state.loader = false;
    });

    builder.addCase(GET("grades/gradeCategory", "").pending, (state) => {
      state.categories = [];
    });
    builder.addCase(
      GET("grades/gradeCategory", "").fulfilled,
      (state, action) => {
        state.categories = [
          state.category_all,
          ...action.payload.data,
          state.category_mygrade,
        ];
        action.payload.data.forEach((el: ICategoryGrade) => {
          state.combined_sub_category[el.id] = el.sub_categories.reduce(
            (prev: ICombinedCategoryGrade[], sub_1_el) => {
              let combined_list: ICombinedCategoryGrade = {
                name: sub_1_el.name,
                sub_1_id: sub_1_el.id,
                sub_2_id: null,
                id: sub_1_el.id,
              };
              // combining list for category sub one & two
              let combined: ICombinedCategoryGrade[] =
                sub_1_el.sub_categories.map((sub_2_el) => {
                  let combined_list_2: ICombinedCategoryGrade = {
                    name: sub_1_el.name + " / " + sub_2_el.name,
                    sub_1_id: sub_1_el.id,
                    sub_2_id: sub_2_el.id,
                    id: +(sub_1_el.id + "" + sub_2_el.id),
                  };

                  return combined_list_2;
                });
              if (combined.length > 0) {
                prev = [...prev, ...combined];
              } else {
                prev = [...prev, combined_list];
              }

              return prev;
            },
            []
          );
        });

        state.sub_category_one = action.payload.data.reduce(
          (prev: { [id: number]: ICategoryGrade[] }, el: ICategoryGrade) => {
            prev[el.id] = el.sub_categories;
            el.sub_categories.forEach((child_el) => {
              state.sub_category_two[child_el.id] = child_el.sub_categories;
            });
            return prev;
          },
          {}
        );
      }
    );
    builder.addCase(POST("grades/addGrade", "").fulfilled, (state, action) => {
      const id = action.payload?.data?.id;
      if (id) {
        for (let i = 0; i < state.pricing_list.length; i++) {
          if (id === state.pricing_list[i].id) {
            state.pricing_list[i].is_preferred = true;
            break;
          }
        }
      }
    });
  },
});

export default PricingSlice.reducer;
export const {
  resetPricingReducer,
  updatePricingQueryParams,
  clearPricingList,
  updateActiveCategoryId,
  updatePricingQueryParamsWithClear,
  resetParamsNList,
  removeFavFromList,
} = PricingSlice.actions;
