import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DELETE, GET, POST } from "../../services/api";
import { ApiURL } from "../../utils/constant";
import {
  ICategory,
  IGrade,
  IGradeReduceer,
  IMetaData,
  IShipment,
} from "../../interfaces/IGradeReducer";
import { IPickupLocationData } from "../../interfaces/User";
import { defaultLoc } from "./Profile.slice";

const defaultMeta: IMetaData = {
  page: 0,
  count: 0,
  total_count: 0,
  total_pages: 0,
};
export const defaultFilterCategory: ICategory = {
  selectedDeal: "all",
  selectedCategory: { id: 0, name: "all" },
  selectedsubcategoryA: { id: 0, name: "all" },
  selectedsubcategoryB: { id: 0, name: "all" },
  grades: [],
  selectedSubCtegoryAList: [],
  selectedSubCtegoryBList: [],
  sort: "default",
};

const initialState: IGradeReduceer = {
  yourGrades: [],
  yourGradeLoader: true,
  addPreferredGradesModalSt: false,
  categories: [],
  metaGrade: defaultMeta,
  metaDeals: defaultMeta,
  metaPreferred: defaultMeta,
  showGradeSideCard: false,
  selectedGradetoAdd: 0,
  gradeList: [],
  grades: [],
  selectedGradeDetails: {} as IGrade,
  selectedShipmentMode: {} as IShipment,
  bestPricedMode: null,
  gradeSideCardLoader: true,
  filterCategoryLoader: true,
  filteredGradeListLoader: true,
  filteredCategory: defaultFilterCategory,
  total_weight: 0,
  total_price: 0,
  desiredLocation: [],
  noPriceModalStatus: false,
  currentShipment: null,
  selectedGradeLocation: defaultLoc,
  selectedDealLocation: defaultLoc,
  selectedCity: "",
  selectedCityId: null,
  gradeAlertModalStatus: false,
  minWeightLimitSt: false,
  totalpreferredgrade: 0,
  searchedGradeParam: "",
  hasGradeFilterValue: false,
  deals: [],
  mobileviewGrade: false,
};

export const GradeSlice = createSlice({
  name: "grades",
  initialState,
  reducers: {
    setPreferredGradeModalSt: (state, action: PayloadAction<boolean>) => {
      state.addPreferredGradesModalSt = action.payload;
    },
    updateGradeSideCard: (state, action: PayloadAction<boolean>) => {
      state.showGradeSideCard = action.payload;
      state.gradeSideCardLoader = true;
    },
    setSelectedGradetoAdd: (state, action: PayloadAction<number>) => {
      state.selectedGradetoAdd = action.payload;
    },
    setselectedCtegoryListSt: (state, action: PayloadAction<[]>) => {
      state.grades = action.payload;
    },
    setClearCategoryFilter: (state) => {
      state.filteredCategory = defaultFilterCategory;
    },
    setSelectedShipmentMode: (state, action: PayloadAction<any>) => {
      state.selectedShipmentMode = action.payload;
    },
    setBestPricedItem: (state, action: PayloadAction<IShipment>) => {
      state.bestPricedMode = action.payload;
    },
    setFilteredGradeListLoader: (state, action: PayloadAction<boolean>) => {
      state.filteredGradeListLoader = action.payload;
    },
    setYourGradeLoader: (state, action: PayloadAction<boolean>) => {
      state.yourGradeLoader = action.payload;
    },
    setFilteredCategorySt: (state, action: PayloadAction<ICategory>) => {
      state.filteredCategory = action.payload;
    },

    setOrderTotalValues: (
      state,
      action: PayloadAction<{ totalPrice: number; totalWeight: number }>
    ) => {
      state.total_weight = action.payload.totalWeight;
      state.total_price = action.payload.totalPrice;
    },
    setGradeAlertModalStatus: (state, action: PayloadAction<boolean>) => {
      state.gradeAlertModalStatus = action.payload;
    },
    setMinweightLimitStatus: (state, action: PayloadAction<boolean>) => {
      state.minWeightLimitSt = action.payload;
    },
    setCurrentShipment: (state, action: PayloadAction<IShipment>) => {
      state.currentShipment = action.payload;
    },
    updateSelectedGradeLocation: (
      state,
      action: PayloadAction<IPickupLocationData>
    ) => {
      state.selectedGradeLocation = action.payload;
    },
    updateSelectedDealLocation: (
      state,
      action: PayloadAction<IPickupLocationData>
    ) => {
      state.selectedDealLocation = action.payload;
    },
    setSelectedCity: (state, action: PayloadAction<string>) => {
      state.selectedCity = action.payload;
    },
    setSelectedCityId: (state, action: PayloadAction<number>) => {
      state.selectedCityId = action.payload;
    },
    updateSelectedGradeDetails: (state, action: PayloadAction<IGrade>) => {
      state.selectedGradeDetails = action.payload;
    },
    setSearchedGradeParam: (state, action: PayloadAction<string>) => {
      state.searchedGradeParam = action.payload;
    },
    setHasGradeFilterValue: (state, action: PayloadAction<boolean>) => {
      state.hasGradeFilterValue = action.payload;
    },
    setMobileViewGrade: (state, action: PayloadAction<boolean>) => {
      state.mobileviewGrade = action.payload;
    },
    resetGradeReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GET("grades/yourGrades", "").fulfilled, (state, action) => {
      state.yourGradeLoader = false;
      state.yourGrades = action.payload.data;
      state.totalpreferredgrade = action.payload?.data?.length;
      state.metaPreferred = action.payload.meta;
    });
    builder.addCase(GET("grades/gradeDetails", "").pending, (state) => {
      state.selectedGradeDetails = {} as IGrade;
    });
    builder.addCase(
      GET("grades/gradeDetails", "").fulfilled,
      (state, action) => {
        state.selectedGradeDetails = action.payload.data;
        state.gradeSideCardLoader = false;
      }
    );
    builder.addCase(GET("grades/yourGrades", "").rejected, (state) => {
      state.yourGradeLoader = false;
      state.yourGrades = [];
      state.totalpreferredgrade = 0;
      state.metaPreferred = defaultMeta;
    });
    builder.addCase(
      GET("grades/gradeCategory", ApiURL.GRADE_CATEGORY).fulfilled,
      (state, action) => {
        state.categories = action.payload.data;
        state.filterCategoryLoader = false;
      }
    );
    builder.addCase(
      GET("grade/category", ApiURL.GRADE_CATEGORY).rejected,
      (state, action) => {
        state.categories = [];
        state.filterCategoryLoader = false;
      }
    );
    builder.addCase(
      POST("grades/addGrade", ApiURL.YOUR_GRADES_LIST).fulfilled,
      () => {}
    );
    builder.addCase(
      POST("grade/addGrade", ApiURL.YOUR_GRADES_LIST).rejected,
      () => {}
    );
    builder.addCase(GET("grades", "").fulfilled, (state, action) => {
      state.grades = action.payload.data;
      state.metaGrade = action.payload.meta;
      state.filteredGradeListLoader = false;
    });
    builder.addCase(GET("grades", "").rejected, (state, action) => {
      state.grades = [];
      state.filteredGradeListLoader = false;
      state.metaGrade = defaultMeta;
    });
    builder.addCase(DELETE("grades/delete", "").fulfilled, () => {});
    builder.addCase(DELETE("grades/delete", "").rejected, () => {});
    builder.addCase(GET("grades/gradeDetails", "").rejected, (state) => {
      state.selectedGradeDetails = {} as IGrade;
      state.gradeSideCardLoader = false;
    });

    builder.addCase(
      GET("desiredLocation", `${ApiURL.ORDER_CREATION_DESIRED_LOCATION}`)
        .fulfilled,
      (state, action) => {
        state.desiredLocation = action.payload.data;
      }
    );
    builder.addCase(
      GET("desiredLocation", `${ApiURL.ORDER_CREATION_DESIRED_LOCATION}`)
        .rejected,
      (state, action) => {}
    );
    builder.addCase(
      POST("grades/create", ApiURL.CREATE_GRADE).fulfilled,
      (state, action) => {
        state.selectedGradeDetails = action.payload.data;
      }
    );
    builder.addCase(
      POST("grade/create", ApiURL.CREATE_GRADE).rejected,
      (state, action) => {
        state.selectedGradeDetails = {} as IGrade;
      }
    );
    builder.addCase(GET("deals", "").fulfilled, (state, action) => {
      state.deals = action.payload.data;
      state.metaDeals = action.payload.meta;
      state.filteredGradeListLoader = false;
    });
    builder.addCase(GET("profileDetails", "").fulfilled, (state, action) => {
      state.selectedGradeLocation = action.payload.data.default_location;
    });
    builder.addCase(GET("deals", "").rejected, (state, action) => {
      state.deals = [];
      state.filteredGradeListLoader = false;
      state.metaDeals = defaultMeta;
    });
  },
});

export default GradeSlice.reducer;
export const {
  updateGradeSideCard,
  setSelectedGradetoAdd,
  setselectedCtegoryListSt,
  setPreferredGradeModalSt,
  setClearCategoryFilter,
  setSelectedShipmentMode,
  setBestPricedItem,
  setFilteredGradeListLoader,
  setYourGradeLoader,
  setFilteredCategorySt,
  setOrderTotalValues,
  setSelectedCity,
  setSelectedCityId,
  setGradeAlertModalStatus,
  setCurrentShipment,
  updateSelectedGradeLocation,
  updateSelectedDealLocation,
  updateSelectedGradeDetails,
  setMinweightLimitStatus,
  setSearchedGradeParam,
  setHasGradeFilterValue,
  setMobileViewGrade,
  resetGradeReducer,
} = GradeSlice.actions;
