import axios from "axios";
import store from "../store/store";
import {
  EXCLUDED_API_URL,
  ErrorCodes,
  HTTPMethods,
  ToastTypes,
  ApiURL,
  EXCLUDED_SERVER_ERR_URL,
  forbiddenUser,
} from "../utils/constant";
import { Auth } from "@aws-amplify/auth";
import { setToken } from "../store/features/Auth.slice";
import {
  setServerErrSt,
  setToastMessage,
} from "../store/features/Common.slice";
import { logoutUser } from "../utils/utils";

const forbiddenLogout = (response: any) => {
  if (response.data.status === forbiddenUser.status) {
    if (
      response.data.error &&
      response.data.error.toUpperCase() === forbiddenUser.error
    ) {
      if (
        response.data.message &&
        response.data.message.toUpperCase() === forbiddenUser.message
      ) {
        logoutUser();
      }
    }
  }
};

axios.interceptors.request.use(
  (config) => {
    const { AuthReducer } = store.getState();
    const { token } = AuthReducer;
    if (
      config.url &&
      !EXCLUDED_API_URL.some((url) => config.url?.includes(url))
    ) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    forbiddenLogout(response);
    if (response.config.method !== HTTPMethods.GET) {
      const {
        data: { message },
      } = response;
      if (message) {
        triggerToastMessage(message, ToastTypes.SUCCESS);
      }
    }
    return response;
  },
  async function (err) {
    const originalConfig = err.config;
    const {
      response: { status },
    } = err;
    const location = window.location.href;
    //Handling 401 case
    forbiddenLogout(err.response);
    if (
      status &&
      status === ErrorCodes.UNAUTHORIZED &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;
      const jwtToken = await refreshToken();
      store.dispatch(setToken(jwtToken));
      axios.defaults.headers.common["Authorization"] = `Bearer ${jwtToken}`;
      return axios(originalConfig);
    } else if (
      status &&
      status === ErrorCodes.SERVER_ERR &&
      !EXCLUDED_SERVER_ERR_URL.some((url) =>
        originalConfig.url?.includes(url)
      ) &&
      !location.endsWith("/home")
    ) {
      store.dispatch(setServerErrSt(true));
    } else if (
      status &&
      status === ErrorCodes.BADREQUEST &&
      originalConfig.url.includes(ApiURL.ZIP_CODE_VERIFICATION)
    ) {
      throw err;
    } else {
      const {
        response: {
          data: { message, error },
        },
      } = err;
      let errorMessage = message;
      if (message.toUpperCase() === forbiddenUser.message) {
        errorMessage = forbiddenUser.errorMessage;
      }
      triggerToastMessage(message ? errorMessage : error, ToastTypes.ERROR);
    }
    return err.response;
  }
);

const refreshToken = async () => {
  try {
    const resp = await Auth.currentSession();
    return resp.getAccessToken().getJwtToken();
  } catch (e) {
    return Promise.reject(e);
  }
};

const triggerToastMessage = (message: string, toastType: string) => {
  store.dispatch(
    setToastMessage({
      showToast: true,
      toastMessage: message,
      toastType: toastType,
    })
  );
};
