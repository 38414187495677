import { ApiURL } from "../../utils/constant";
import { IPickupLocationData } from "../../interfaces/User";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GET } from "../../services/api";

const initialCompanyAddress: IPickupLocationData = {
  address: "",
  city: "",
  state: "",
  state_id: "",
  zip_code: "",
  id: 0,
  name: "",
  is_editable: false,
  is_default: false,
  supplier_id: 0,
  address2: "",
  address3: "",
  region: "",
};

const initialState: {
  pickupLocations: IPickupLocationData[];
  chosenLocation: IPickupLocationData;
  addNewLocationModalSt: boolean;
  editPickupLocationModalSt: boolean;
  deleteLocationModalSt: boolean;
  locationModalSt: boolean;
  location_loaded: boolean;
  pickupLocationsDropDown: { value: number; label: string; zip_code: string }[];
} = {
  pickupLocations: [],
  pickupLocationsDropDown: [],
  chosenLocation: initialCompanyAddress,
  addNewLocationModalSt: false,
  editPickupLocationModalSt: false,
  deleteLocationModalSt: false,
  locationModalSt: false,
  location_loaded: false,
};

export const PickupLocationsSlice = createSlice({
  name: "pickupLocations",
  initialState,
  reducers: {
    setLocationModalSt: (state, action: PayloadAction<boolean>) => {
      state.locationModalSt = action.payload;
    },
    setAddNewLocationModalSt: (state, action: PayloadAction<boolean>) => {
      state.addNewLocationModalSt = action.payload;
    },
    setEditPickupLocationModalSt: (state, action: PayloadAction<boolean>) => {
      state.editPickupLocationModalSt = action.payload;
    },
    setDeleteLocationModalSt: (state, action: PayloadAction<boolean>) => {
      state.deleteLocationModalSt = action.payload;
    },
    setPickupLocations: (state, action: PayloadAction<any | null>) => {
      state.pickupLocations = action.payload;
    },
    setChosenLocations: (state, action: PayloadAction<any | null>) => {
      state.chosenLocation = action.payload;
    },
    resetPickupLocationReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("pickupLocationList", `${ApiURL.PICKUP_LOCATION}`).pending,
      (state, action) => {
        state.location_loaded = true;
      }
    );
    builder.addCase(
      GET("pickupLocationList", `${ApiURL.PICKUP_LOCATION}`).fulfilled,
      (state, action) => {
        let locations = action.payload?.data;
        if (locations?.length > 0) {
          state.pickupLocations = locations?.sort(
            (a: any, b: any) => b.is_default - a.is_default
          );
          let defaultLocation = locations?.filter(
            (a: any) => a.is_default === true
          );
          heap.addUserProperties({
            no_of_locations: locations?.length,
            company_location_default:
              defaultLocation[0]?.name === "Company Address" ? "Yes" : "No",
            default_location_name: defaultLocation[0]?.name,
            default_location_address:
              defaultLocation[0]?.address +
              defaultLocation[0]?.address_2 +
              defaultLocation[0]?.address_3,
            default_location_city: defaultLocation[0]?.city,
            default_location_state: defaultLocation[0]?.state,
            default_location_zip_code: defaultLocation[0]?.zip_code,
          });
          const options = locations?.map((item: any) => {
            return {
              value: Number(item.id),
              zip_code: item.zip_code,
              label: `${item.is_editable ? item.name : ""} 
              ${item.is_editable ? "(" : ""}${
                item?.address && item?.address !== "NaN"
                  ? item?.address + ", "
                  : ""
              }
            ${
              item?.address_2 && item?.address_2 !== "NaN"
                ? item?.address_2 + ", "
                : ""
            }
            ${
              item?.address_3 && item?.address_3 !== "NaN"
                ? item?.address_3 + ", "
                : ""
            }
            ${item?.city && item?.city !== "NaN" ? item?.city + ", " : ""}
            ${item?.state && item?.state !== "NaN" ? item?.state + ", " : ""}
            ${
              (item?.zip_code && item?.zip_code) !== "NaN" ? item?.zip_code : ""
            } ${item.is_editable ? ")" : ""}`,
            };
          });
          state.pickupLocationsDropDown = options;
        } else state.pickupLocations = [];
        state.location_loaded = false;
      }
    );
    builder.addCase(
      GET("pickupLocationList", `${ApiURL.PICKUP_LOCATION}/`).rejected,
      (state, action) => {
        state.pickupLocations = [];
        state.location_loaded = false;
      }
    );
  },
});

export default PickupLocationsSlice.reducer;
export const {
  setAddNewLocationModalSt,
  setEditPickupLocationModalSt,
  setDeleteLocationModalSt,
  setLocationModalSt,
  setChosenLocations,
  setPickupLocations,
  resetPickupLocationReducer,
} = PickupLocationsSlice.actions;
