import { Button } from "antd";
import styled from "styled-components";

export const fontMedium10 = `
font-family:GothamMedium;
font-weight:350;
font-size:1rem;
line-height:1.4rem;
`;

export const fontNormal12 = `
font-family:Gotham;
font-weight:325;
font-size:1.2rem;
line-height:1.96rem;
`;

export const fontMedium12 = `
font-family:GothamMedium;
font-weight:350;
font-size:1.2rem;
line-height:1.96rem;
`;

export const fontNormal14 = `
font-family:Gotham;
font-weight:325;
font-size:1.4rem;
line-height:1.96rem;
`;

export const fontMedium14 = `
font-family:GothamMedium;
font-weight:350;
font-size:1.4rem;
line-height:1.96rem;
`;

export const fontNormal16 = `
font-family:Gotham;
font-weight:325;
font-size:1.6rem;
line-height:2.4rem;
`;

export const fontMedium16 = `
font-family:GothamMedium;
font-weight:350;
font-size:1.6rem;
line-height:2.4rem;
`;

export const fontNormal18 = `
font-family:Gotham;
font-weight:325;
font-size:1.8rem;
line-height:2.61rem;
`;

export const fontMedium18 = `
font-family:GothamMedium;
font-weight:350;
font-size:1.8rem;
line-height:2.61rem;
`;

export const fontMedium20 = `
font-family:GothamMedium;
font-weight:350;
font-size:2rem;
line-height:3rem;
`;

export const fontMedium28 = `
font-family:GothamMedium;
font-weight:350;
font-size:2.8rem;
line-height:4.2rem;
`;

export const fontBold30 = `
font-family:GothamBold;
font-weight:400;
font-size:3rem;
line-height:4.35rem;
`;

export const fontlineClamp = (clamp = 1) => {
  return `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${clamp};
    -webkit-box-orient: vertical;
    `;
};

export const BackBtnWrapper = styled(Button)`
  display: flex;
  gap: 1.4rem;
  ${fontMedium16}
  color: var(--NEUTRAL-3);
  background-color: transparent;
  border: none;
  align-items: center;
  height: auto;
  margin: 1.2rem 1rem 0rem 1rem;
  box-shadow: none;
  @media (min-width: 840px) {
    display: none;
  }
`;

export const fontBold36 = `
font-family:GothamBold;
font-weight:400;
font-size:3.6rem;
line-height:5.02rem;
`;
