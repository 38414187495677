import { QB } from "../QuickBlox_initiation";
import QB_prepareMessageFormat from "./QB_prepareMessageFormat";

const QB_sendSyncGroupImg = ({
  id = "",
  upload_list = [],
  user = {} as any,
  caption = "",
  msg_style = "",
  mys_type = "",
  mys_count = "",
  mys_grade_name = "",
}) => {
  let message: any = QB_prepareMessageFormat({
    text: caption,
    dailog_id: id,
    type: 2,
    msg_style,
    mys_type,
    mys_count,
    mys_grade_name,
  });

  const dialogJid = QB.chat.helpers.getRoomJidFromDialogId(id);
  message["extension"]["attachments"] = upload_list;
  try {
    message.id = QB.chat.send(dialogJid, message);
  } catch (e: any) {
    if (e.name === "ChatNotConnectedError") {
      console.log("error");
    }
  }
};

export default QB_sendSyncGroupImg;
