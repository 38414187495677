import React from "react";
import { UpVectorIcon } from "../Icons/MenuIcons";
type Heading = {
  heading: string;
  setOpenCarosal: Function;
  showIcon?: boolean;
};
const OfferOrderSubHeading = ({
  heading = "",
  setOpenCarosal,
  showIcon = true,
}: Heading) => {
  return (
    <div className="headers">
      <h4 className="heading">{heading}</h4>
      {showIcon && (
        <div className="carosal-btn" onClick={() => setOpenCarosal()}>
          <div className="icon">
            <UpVectorIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferOrderSubHeading;
