import React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
const LoaderBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-content: center;
`;
const LoaderLine = styled.div`
  position: relative;
  width: 200px;
  .loader-line {
    width: 135px;
    height: 6px;
    position: relative;
    overflow: hidden;
    background-color: #e0e6f0;
    margin: 100px auto;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  .loader-line:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 6px;
    width: 40%;
    margin: 0 auto;
    background: linear-gradient(
      90deg,
      #285e5f 0%,
      #2c7771 25%,
      #369080 48.96%,
      #49aa8b 80.73%,
      #64c492 100%
    );
    -webkit-animation: lineAnim 2s linear infinite;
    -moz-animation: lineAnim 2s linear infinite;
    animation: lineAnim 2s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }

  @keyframes lineAnim {
    0% {
      left: -40%;
    }
    50% {
      left: 20%;
      width: 80%;
    }
    100% {
      left: 100%;
      width: 100%;
    }
  }
`;

const ModalLoaderBox = styled.div<{ show: boolean }>`
  position: absolute;
  z-index: ${(props) => (props.show ? "-1" : "99")};
  transition: 0.3s;
  opacity: ${(props) => (props.show ? "0" : "1")};
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  background-color: var(--WHITE);
  transform: translate(-50%, -50%);
  display: grid;
  place-content: center;
  .loader-message-holder {
    color: var(--PRIMARY);
    font-family: GothamMedium;
    font-weight: 350;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 0;
    top: 0;
    transform: translate(120%, 45%);
  }
`;

const ComponentLoaderBox = styled.div<{ show: boolean }>`
  position: absolute;
  z-index: ${(props) => (props.show ? "-1" : "99")};
  transition: 0.3s;
  opacity: ${(props) => (props.show ? "0" : "1")};
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  background-color: var(--WHITE);
  transform: translate(-50%, -50%);
  display: grid;
  place-content: center;
  opacity: 0.6;
`;
const PortalLoaderBox = styled.div`
  position: absolute;
  z-index: 9999;
  background-color: var(--WHITE);
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-content: center;
  transition: 0.3s;
  opacity: 0.6;
`;

export const Loader: React.FC = () => {
  return (
    <LoaderBox>
      <LoaderLine>
        <div className="loader-line"></div>
      </LoaderLine>
    </LoaderBox>
  );
};

export const ModalLoader = ({ show = false, message = "" }) => {
  return (
    <ModalLoaderBox show={show}>
      {message !== "" && <p className="loader-message-holder">{message}</p>}
      <LoaderLine>
        <div className="loader-line"></div>
      </LoaderLine>{" "}
    </ModalLoaderBox>
  );
};

export const ComponentLoader = ({ show = false }) => {
  return (
    <ComponentLoaderBox show={show}>
      <LoaderLine>
        <div className="loader-line"></div>
      </LoaderLine>{" "}
    </ComponentLoaderBox>
  );
};

const portalLoaderDOM = document.getElementById("portal-loader") as HTMLElement;
export const PortalLoader: React.FC = () => {
  return ReactDOM.createPortal(
    <PortalLoaderBox>
      <LoaderLine>
        <div className="loader-line"></div>
      </LoaderLine>{" "}
    </PortalLoaderBox>,
    portalLoaderDOM
  );
};

export default Loader;
