import { I_QB_sendMessageMail } from "../../interfaces/IQBinterfaces";
import { POST } from "../../services/api";
import store from "../../store/store";
import { ApiURL } from "../constant";

const QB_sendEmail = ({
  message = "",
  dialog_id = "",
  recepients = [],
  attachment = null,
  created_on = "",
}: I_QB_sendMessageMail) => {
  const payload = {
    message,
    dialog_id,
    recipients: recepients,
    attachment,
    created_on,
  };
  store.dispatch(POST("messagemail", ApiURL.MAIL_MESSAGE, payload)());
};

export default QB_sendEmail;
