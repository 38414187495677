import TagManager from "react-gtm-module";
import store from "../store/store";

const getUserData = () => {
  const state = store.getState();
  const { userData } = state.ProfileReducer;
  return userData;
};
export const GTM_signUp = (user_id: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "signup",
      user_id: user_id,
    },
  });
};

export const GTM_login = (user_id: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "login",
      user_id: user_id,
    },
  });
};

export const GTM_viewpricing = (user_id: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "view_pricing",
      user_id: user_id,
    },
  });
};

export const GTM_startorder = ({
  grade = "",
  grade_type = "",
  ship_type = "",
}) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "start_order",
      grade,
      grade_type,
      ship_type,
    },
  });
};

export const GTM_startsale = ({ grade = "", grade_type = "" }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "start_sale",
      grade,
      grade_type,
    },
  });
};

export const GTM_startoffer = ({
  grade = "",
  grade_type = "",
  ship_type = "",
}) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "start_offer",
      grade,
      grade_type,
      ship_type,
    },
  });
};

export const GTM_submitoffer = ({
  grade = "",
  grade_type = "",
  ship_type = "",
  offer_id = "",
}) => {
  const email = getUserData()?.email;
  TagManager.dataLayer({
    dataLayer: {
      event: "submit_offer",
      offer_id,
      grade,
      grade_type,
      ship_type,
      user_email: email,
    },
  });
};

export const GTM_submitorder = ({
  grade_name = "",
  grade_type = "",
  ship_type = "",
  order_id = "",
  price = 0,
  loads = 0,
  min_weight = 0,
  total_weight = 0,
  unit_price = 0,
}) => {
  const email = getUserData()?.email;
  TagManager.dataLayer({
    dataLayer: {
      event: "purchase",
      ecommerce: {
        currency: "USD", // Type: String
        value: price, // Type: Number - total price
        transaction_id: order_id, // Type Number - order id
        user_email: email,
        items: [
          {
            item_name: grade_name, //grade
            order_type: "Submit Order",
            grade_type: grade_type,
            ship_type: ship_type,
            unit_price: unit_price,
            loads: loads,
            min_weight: min_weight,
            total_weight: total_weight,
          },
        ],
      },
    },
  });
};

export const GTM_acceptcounteroffer = ({
  grade_name = "",
  grade_type = "",
  ship_type = "",
  order_id = "",
  price = 0,
  loads = 0,
  min_weight = 0,
  total_weight = 0,
  unit_price = 0,
}) => {
  const email = getUserData()?.email;

  TagManager.dataLayer({
    dataLayer: {
      event: "purchase",
      ecommerce: {
        currency: "USD", // Type: String
        value: price, // Type: Number - total price
        transaction_id: order_id, // Type Number - order id
        user_email: email,
        items: [
          {
            item_name: grade_name,
            order_type: "Supplier Accept Offer",
            grade_type: grade_type,
            ship_type: ship_type,
            unit_price: unit_price,
            loads: loads,
            min_weight: min_weight,
            total_weight: total_weight,
          },
        ],
      },
    },
  });
};

export const GTM_traderacceptoffer = ({
  grade_name = "",
  grade_type = "",
  ship_type = "",
  order_id = "",
  price = 0,
  loads = 0,
  min_weight = 0,
  total_weight = 0,
  unit_price = 0,
}) => {
  const email = getUserData()?.email;

  TagManager.dataLayer({
    dataLayer: {
      event: "purchase",
      ecommerce: {
        currency: "USD", // Type: String
        value: price, // Type: Number - total price
        transaction_id: order_id, // Type Number - order id
        user_email: email,
        items: [
          {
            item_name: grade_name,
            order_type: "Trader Accept Offer",
            grade_type: grade_type,
            ship_type: ship_type,
            unit_price: unit_price,
            loads: loads,
            min_weight: min_weight,
            total_weight: total_weight,
          },
        ],
      },
    },
  });
};
