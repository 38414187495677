const QuickBlox = require("quickblox/quickblox.min").QuickBlox;
export const QB_CONFIG = {
  debug: false,
  endpoints: {
    api: "apihubbitgpr.quickblox.com", // set custom API endpoint
    chat: "chathubbitgpr.quickblox.com", // set custom Chat endpoint
  },
};

export const QB = new QuickBlox();
