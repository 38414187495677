import { useState } from "react";
import styled from "styled-components";
import { AttachmentViewModal } from "../AttachmentViewModal/AttachmentViewModal";

const ImagesContainer = styled.div`
  h4 {
    padding: 2rem 0 1rem 0;
    font-family: "GothamMedium";
    font-style: normal;
    font-weight: 350;
    font-size: 1.6rem;
    line-height: 150%;
    color: var(--NEUTRAL-4);
  }
  hr {
    background-color: var(--NEUTRAL-2);
    height: 1px;
    border: none;
  }
  border-top: 1px solid var(--NEUTRAL-2);
  border-bottom: 1px solid var(--NEUTRAL-2);
`;

const ImagesWrapper = styled.div`
    padding: 2rem 0;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    color:var(--NEUTRAL-4);
    .image-box {
        width: 8rem;
        height: 8rem;
        border-radius: 1rem;
        border: 1px solid #62707880;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 1rem;
        }
`;

const OffersShipmentImages = (props: {
  images: string[] | null | undefined;
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [SelectedImage, setSelectedImage] = useState<string>("");
  const onSetImage = (val: string) => {
    setSelectedImage(val);
    setShowModal(true);
  };
  return (
    <>
      <AttachmentViewModal
        src={SelectedImage}
        showModal={showModal}
        setShowModal={setShowModal}
        type={"image"}
      />
      <ImagesContainer>
        <h4>Images</h4>
        <hr />
        <ImagesWrapper>
          {props.images == null || props.images?.length < 1
            ? "N/A"
            : props.images?.map((image, index) => (
                <div className="image-box" key={index}>
                  <img
                    src={image}
                    alt="grade_img"
                    onClick={() => onSetImage(image)}
                  />
                </div>
              ))}
        </ImagesWrapper>
      </ImagesContainer>
    </>
  );
};

export default OffersShipmentImages;
