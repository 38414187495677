import { I_QB_sendMessageMail } from "../../interfaces/IQBinterfaces";
import { updateUploadSentCount } from "../../store/features/Messages.slice";
import store from "../../store/store";
import { QB } from "../QuickBlox_initiation";
import QB_sendEmail from "./QB_sendEmail";

const QB_sendGroupImg = async ({
  attachmentFiles = [],
  dailog_id = "",
  caption = "",
  opponentId = [],
  msg_style = "",
  mys_type = "",
  mys_count = "",
  mys_grade_name = "",
}: {
  attachmentFiles: File[];
  dailog_id: string | number;
  opponentId: number[];
  type?: number;
  caption?: string;
  msg_style?: string;
  mys_type?: string;
  mys_count?: string;
  mys_grade_name?: string;
}) => {
  let attachments: { id: string; type: string }[] = [];
  attachmentFiles.forEach(async (el, index) => {
    const attachmentFile = {
      name: el.name,
      file: el,
      type: el.type,
      size: el.size,
      public: false,
    };

    QB.content.createAndUpload(attachmentFile, async (e: any, res: any) => {
      if (!e) {
        attachments.push({ id: res.uid, type: "image" });
        store.dispatch(
          updateUploadSentCount({
            id: dailog_id,
            upload_list: attachments,
            type: "image",
            caption: caption,
            msg_style,
            mys_type,
            mys_count,
            mys_grade_name,
          })
        );
      }
    });
  });
  const mailMsg: I_QB_sendMessageMail = {
    recepients: opponentId,
    dialog_id: dailog_id,
    message: caption,
    created_on: new Date().toISOString(),
    attachment: "image",
  };
  QB_sendEmail(mailMsg);
};

export default QB_sendGroupImg;
