import { GET } from "../../services/api";
import store from "../../store/store";
import { ApiURL } from "../constant";
import { QB } from "../QuickBlox_initiation";
import QB_dialogListing from "./QB_dialogListing";

const QB_connect = (mySession: any) => {
  const connectparams = {
    userId: mySession.session.user_id,
    password: mySession.session.token,
  };
  QB.chat.connect(connectparams, function (error: any, contactList: any) {
    if (!error) {
      QB_dialogListing();
    } else {
      console.log("unable to connect");
      store.dispatch(GET("usersession", ApiURL.USER_MESSAGE_SESSION)());
    }
  });
};

export default QB_connect;
