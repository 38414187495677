const QB_prepareMessageFormat = ({
  text = "",
  dailog_id = "",
  type = 2,
  sendasclient = 0,
  questions_option = {},
  msg_style = "",
  mys_type = "",
  mys_count = "",
  mys_grade_name = "",
}: {
  text: string;
  dailog_id: string | number;
  type: number;
  sendasclient?: number;
  questions_option?: any;
  msg_style?: string;
  mys_count?: string;
  mys_type?: string;
  mys_grade_name?: string;
}) => {
  return {
    type: type === 2 ? "groupchat" : "chat",
    body: text,
    extension: {
      save_to_history: 1,
      dialog_id: dailog_id,
      sender_name: "",
      sendasclient: sendasclient,
      questions_id: questions_option?.questions_id
        ? questions_option?.questions_id
        : "",
      questions_type: questions_option?.questions_type
        ? questions_option?.questions_type
        : "",
      msg_style: msg_style,
      mys_count: mys_count,
      mys_type: mys_type,
      mys_grade_name: mys_grade_name,
    },
    markable: 1,
  };
};

export default QB_prepareMessageFormat;
