import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import {
  IAuthState,
  ICognitoAttributes,
  StoreStatus,
} from "../../interfaces/authentication";
import {
  forgotPassword,
  signIn,
  forgotPasswordSubmit,
  completePassword,
} from "./Auth.actions";
import { GET, POST } from "../../services/api";
import { ApiURL } from "../../utils/constant";
import { hasNonPermissibleRoles } from "../../utils/utils";

const initialState: IAuthState = {
  isAuthenticated: false,
  message: "",
  status: StoreStatus.Loading,
  user: {
    email: "",
    email_verified: false,
    given_name: "",
    family_name: "",
    sub: "",
    "custom:user_role": "",
    phone_number: "",
  },
  roles: [],
  token: "",
  cognitoUser: null,
  signupFormCompletionPercentage: 20,
  personalDetailsFormData: {},
  createAccFormSubmitted: false,
  companyDetailsFormData: {},
  user_phone_number: "",
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginStatus: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setStatus: (state, action: PayloadAction<StoreStatus>) => {
      state.status = action.payload;
    },
    setLoginUserDetails: (
      state,
      action: PayloadAction<{
        isAuthenticated: boolean;
        status: StoreStatus;
        user: ICognitoAttributes;
        token: string;
      }>
    ) => {
      state.status = action.payload.status;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setSignupFormCompletionPercentage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.signupFormCompletionPercentage = action.payload;
    },
    setPersonalDetailsFormData: (state, action: PayloadAction<any>) => {
      state.personalDetailsFormData = action.payload;
    },
    setCompanyDetailsFormData: (state, action: PayloadAction<any>) => {
      state.companyDetailsFormData = action.payload;
    },
    setCreateAccFormSubmitted: (state, action: PayloadAction<boolean>) => {
      state.createAccFormSubmitted = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    resetAuthReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.status = StoreStatus.Succeeded;
      state.token = action.payload.signInUserSession.accessToken.jwtToken;
      state.cognitoUser = action.payload.challengeName ? action.payload : null;
      state.user = action.payload.challengeName
        ? action.payload.challengeParam?.userAttributes
        : action.payload.attributes;
      let hasOtherRole: boolean = hasNonPermissibleRoles(state.user);
      state.isAuthenticated =
        action.payload.challengeName || !hasOtherRole ? false : true;
    });
    builder.addCase(
      POST("userAccountVerify", `${ApiURL.USER_ACCOUNT_VERIFY}`).fulfilled,
      () => {}
    );
    builder.addCase(
      GET("profileDetails", ApiURL.PROFILE_DETAILS).fulfilled,
      (state, action) => {
        state.user_phone_number = action.payload.data.phone || "";
      }
    );
    builder.addMatcher(
      isAnyOf(
        forgotPassword.fulfilled,
        forgotPasswordSubmit.fulfilled,
        completePassword.fulfilled
      ),
      () => ({
        ...initialState,
        status: StoreStatus.Succeeded,
      })
    );

    // Handle pending & rejected requests
    builder.addMatcher(
      isAnyOf(
        signIn.pending,
        forgotPassword.pending,
        forgotPasswordSubmit.pending
      ),
      () => ({
        ...initialState,
        status: StoreStatus.Loading,
      })
    );
    builder.addMatcher(
      isAnyOf(
        signIn.rejected,
        forgotPassword.rejected,
        forgotPasswordSubmit.rejected,
        completePassword.rejected
      ),
      (state, { error }) => ({
        ...state,
        status: StoreStatus.Failed,
        message: error.message || "",
      })
    );
  },
});

export const {
  setLoginStatus,
  setStatus,
  setLoginUserDetails,
  setSignupFormCompletionPercentage,
  setPersonalDetailsFormData,
  setCompanyDetailsFormData,
  setCreateAccFormSubmitted,
  setToken,
  resetAuthReducer,
} = AuthSlice.actions;
export default AuthSlice.reducer;
