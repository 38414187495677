import { ChatInterface } from "../../interfaces/MessagesInterface";
import { sendChatBotMessage } from "../../utils/utils";

export const staticStrings = {
  heading: "hubbIT Assistant",
  input_placeholder: "Type your question here",
};

export const chatbot_const_text = {
  intro:
    "Hi , I'm the HubbIT Assistant, here to help you with all things HubbIT.Ask me a question or select one of the following options.",

  category_option: {
    line_1:
      'You can ask me a question about "suggestion picked" or select from the following suggestions',
    line_2: "Ask another question or Select one of the following",
  },
  helpful_note: "Was this helpful?",
  thank_note: "Thank you for your question.",
  info_note: "Here is some information that might help",
  unknown_note:
    "I'm sorry , I did not quite get that. Can you rephrase that?. You can also choose from one of the following suggestions or I can connect you to our trader",
  yes: {
    line_1: "Happy to be of assitance",
    line_2: "Would you like to explore more suggestions?",
  },
  no: {
    line_1:
      "I'm sorry  my response was not helpful. You can explore suggestions below or I can connect you to our trader?",
  },
  contact_trader: "Connecting to a Trader who can assist you further..",
  explore_note: "Would you like to explore more categories?",
};

export const suggestion_replacement = "suggestion picked";
export const chatbotactivationkey = "/activate_GCSQDURYECECSDBXBUAX ";

export const nextStepQuestion = {
  answers: "",
  categories: "questions",
  questions: "answers",
  default: "",
};
export const unknownAnswerChatbot =
  "I'm sorry, I don't know the answer to that question. Please try rephrasing your question. You can also connect with our platform expert or explore more categories.";
export const actionbtntype = [
  "categories",
  "questions",
  "helpful",
  "contact_trader",
];

export const chatbot_helpfulMessage = (
  state: {
    chatbot_id: number | null;
    chatbot_dialog_id: string;
  },
  chatbot_const_text: any
) => {
  const d = new Date().toJSON();
  const helfulData: ChatInterface = {
    _id: Math.random() * 100 + "",
    message: chatbot_const_text.helpful_note,
    sender_id: state.chatbot_id || 0,
    updated_at: d,
    created_at: d,
    read: 1,
    dialog_id: state.chatbot_dialog_id,
    sendasclient: 1,
    questions_type: "helpful",
  };
  return helfulData;
};

export const chatbot_relatedMessage = (
  state: {
    chatbot_id: number | null;
    chatbot_dialog_id: string;
  },
  chatbot_const_text: any
) => {
  const d = new Date().toJSON();
  const helfulData: ChatInterface = {
    _id: Math.random() * 100 + "",
    message: chatbot_const_text.info_note,
    sender_id: state.chatbot_id || 0,
    updated_at: d,
    created_at: d,
    read: 1,
    dialog_id: state.chatbot_dialog_id,
    sendasclient: 1,
  };
  return helfulData;
};

export const chatbot_exploreMessage = (
  state: {
    chatbot_id: number | null;
    chatbot_dialog_id: string;
  },
  chatbot_const_text: any
) => {
  const d = new Date().toJSON();
  const helfulData: ChatInterface = {
    _id: Math.random() * 100 + "",
    message: chatbot_const_text.explore_note,
    sender_id: state.chatbot_id || 0,
    updated_at: d,
    created_at: d,
    read: 1,
    dialog_id: state.chatbot_dialog_id,
    sendasclient: 1,
    questions_type: "contact_trader",
  };
  return helfulData;
};

export const chatbot_yesfinder = (text: string) => {
  sendChatBotMessage({
    type: "send_as_user_message",
    text: text,
  });
  sendChatBotMessage({ type: "yes" });
  sendChatBotMessage({ type: "more_option_satisfied" });
};

export const chatbot_nofinder = (text: string) => {
  sendChatBotMessage({
    type: "send_as_user_message",
    text: text,
  });
  sendChatBotMessage({ type: "contacttrader" });
};
export const no_finder = [
  "no",
  "nope",
  "nah",
  "nada",
  "nep",
  "noo",
  "nop",
  "nooo",
  "nap",
  "not sure",
  "not sre",
  "no sure",
  "no sre",
  "no sue",
  "no indeed",
  "absolutely not",
  "most certainly not",
  "of course not",
  "under no circumstances",
  "by no means",
  "not at all",
  "negative",
  "never",
  "not really",
  "no thanks",
  "nae",
  "nope",
  "nah",
  "not on your life",
  "no way",
  "no fear",
  "not on your nelly",
  "no siree",
  "naw",
  "nay",
  "naay",
  "na",
  "naye",
  "nayy",
  "naaw",
  "naww",
  "nnaw",
  "naww",
  "no sireee",
  "no seree",
  "no siree",
  "no sirree",
  "not on your nelley",
  "not on your neely",
  "not on your nely",
  "not on your nellly",
  "noo fear",
  "no feer",
  "no fea",
  "noo fearr",
  "nowaynevver",
  "nnever",
  "neve",
  "nevr",
  "no waay",
  "noo way",
  "nowaay",
  "not on your liffe",
  "not on your lif",
  "not on your liffe",
  "not on your liife",
  "naah",
  "nha",
  "naha",
  "nahh",
  "nopp",
  "nopee",
  "nopep",
  "noop",
  "nee",
  "nay",
  "na",
  "naae",
  "no thankks",
  "no thanksk",
  "no thank",
  "no thanky",
  "not reallly",
  "nott really",
  "not realy",
  "noot really",
  "negtive",
  "neagtive",
  "negativ",
  "negaative",
  "by no mean",
  "by no meaans",
  "by noo means",
  "byy no means",
  "under no circumstanses",
  "under no cirumstances",
  "under no circu mstances",
  "under no circumstanceses",
  "off course not",
  "ofcoursee not",
  "of coursenot",
  "of coursee not",
  "most certainlyy not",
  "mostt certainly not",
  "most ceretainly not",
  "most certainnly not",
  "absolutelyy not",
  "absoluteely not",
  "absolutelly not",
  "absolutly not",
  "noo indeed",
  "no indeeed",
  "no inded",
  "no indedd",
];
export const yes_finder = [
  "yay",
  "yes",
  "yap",
  "yeah",
  "yep",
  "yesh",
  "yolo",
  "yea",
  "all right",
  "alright",
  "very well",
  "of course",
  "by all means",
  "sure",
  "certainly",
  "absolutely",
  "indeed",
  "affirmative",
  "in the affirmative",
  "agreed",
  "roger",
  "aye",
  "aye aye",
  "yeah",
  "yah",
  "yep",
  "yup",
  "uh-huh",
  "okay",
  "OK",
  "okey-dokey",
  "okey-doke",
  "achcha",
  "righto",
  "righty-ho",
  "surely",
  "yea",
  "allright",
  "aalright",
  "alrigth",
  "alrightt",
  "alriight",
  "off course",
  "ofcoursee",
  "of corse",
  "of coursse",
  "by all meaans",
  "by all meanss",
  "by all mean",
  "byy all means",
  "shure",
  "surr",
  "suer",
  "suure",
  "certaintly",
  "cerainly",
  "certainlly",
  "certanly",
  "abslutely",
  "absolutelly",
  "absulutely",
  "absolutey",
  "inded",
  "ineed",
  "indeeed",
  "inded",
  "affirrmative",
  "afirmative",
  "affirmitive",
  "affiramtive",
  "in the afirmative",
  "in the affirmattive",
  "in the affirmmative",
  "in the affiramtive",
  "agreedd",
  "aggred",
  "agred",
  "agread",
  "rogger",
  "rogr",
  "rogerr",
  "rogerer",
  "ayee",
  "ay",
  "ayye",
  "ayee",
  "ay aye",
  "ayee aye",
  "aye ay",
  "ayee ayee",
  "yeaah",
  "yahh",
  "yeaa",
  "yeh",
  "yaah",
  "yeh",
  "yaaah",
  "yash",
  "yepp",
  "yep",
  "yyep",
  "yeppe",
  "uhhuh",
  "uh huh",
  "u-huh",
  "uhuh",
  "okaay",
  "ok",
  "okey",
  "oka",
  "okaay",
  "okee",
  "okee",
  "oka",
  "okey dokey",
  "okey-dokeyy",
  "okey-dokey",
  "okee-dokee",
  "okee-doke",
  "okey doke",
  "okey-dokee",
  "okeyy-doke",
  "achha",
  "accha",
  "achhca",
  "achca",
  "righo",
  "rightoo",
  "rigto",
  "rightoo",
  "righy-ho",
  "rightyho",
  "righty-ho",
  "righyy-ho",
  "righy ho",
  "rightyho",
  "righty ho",
  "righyy ho",
  "sureely",
  "shurely",
  "surelly",
  "surly",
  "yeea",
  "yae",
  "ya",
  "ye",
];

export const categories_finder = [
  "catagory",
  "catagroy",
  "categiry",
  "categor",
  "categoory",
  "categpry",
  "categort",
  "categoty",
  "categoy",
  "catergory",
  "category",
  "catagories",
  "categoris",
  "categoreis",
  "categroies",
  "catergories",
  "catergorys",
  "catgories",
  "catigories",
  "catogories",
  "cattegories",
];

export const chatbotConfigs = {
  textarea_category: false,
  thank_note: false,
  helpful_note: true,
};
