import notification from "antd/es/notification";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setToastMessage } from "../../store/features/Common.slice";
import { ErrorCrossIcon, SuccessTickIcon } from "../Icons/Icons";
import { SCREEN_TYPE, ToastTypes } from "../../utils/constant";
import useScreenType from "../../hooks/useScreenType";

export const ToastMessage = () => {
  const [api, contextHolder] = notification.useNotification({ maxCount: 1 });
  const { showToast, toastMessage, toastType } = useAppSelector(
    (state) => state.CommonReducer
  );
  const dispatch = useAppDispatch();
  const screenType = useScreenType();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (
      screenType === SCREEN_TYPE.MOBILE ||
      screenType === SCREEN_TYPE.TABLET
    ) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }

    if (showToast) {
      api.open({
        message: toastMessage,
        className: "toast-message",
        style: {
          width: 400,
        },
        placement: mobileView ? "bottom" : "topRight",
        duration: 2,
        icon:
          toastType === ToastTypes.SUCCESS ? (
            <SuccessTickIcon />
          ) : (
            <ErrorCrossIcon />
          ),
      });
      dispatch(
        setToastMessage({ showToast: false, toastMessage: "", toastType: "" })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast, api, dispatch, toastMessage, toastType, screenType]);
  return <>{contextHolder}</>;
};
