import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setCancelOfferModalSt } from "../../store/features/OffersActions.action";
import { CancelOfferModal } from "../../pages/OfferDetails/CancelOfferModal/CancelOfferModal";
import OfferActions from "./OfferActions";
import OrderActions from "./OrderActions";
import SaleDuplicationPopup from "./SaleDuplicationPopup";

const ActionWrapper = styled.div`
  h4 {
    font-size: 1.6rem;
    font-family: "GothamMedium";
    font-weight: 350;
  }
  .action-text {
    font-size: 1.4rem;
    overflow-wrap: anywhere;
    .light-text {
      color: var(--NEUTRAL-3);
    }
    .reason-text {
      margin-top: 1.2rem;
      margin-bottom: 2rem;
      color: var(--NEUTRAL-4);
    }
  }
  .action_btn {
    .button {
      box-shadow: none;
      margin-top: 1.2rem;
      font-size: 14px;
      font-family: GothamMedium;
    }
    .action-button-wrapper {
      button {
        min-height: 4.4rem;
      }
      @media (max-width: 820px) {
        z-index: 10;
        background-color: var(--WHITE);
        padding: 1.2rem 1.6rem;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        button {
          height: 4.8rem;
          font-size: 1.6rem !important;
          font-weight: 350;
        }
      }
    }
  }
`;
const OfferOrderAction = (props: { Type: string | undefined }) => {
  const { Type = "order" } = props;
  const isOrder = Type === "order";
  const dispatch = useAppDispatch();
  const { open_duplicate_sale } = useAppSelector(
    (state) => state.OffersActionsReducer
  );
  const { offer_details_data } = useAppSelector((state) => state.OffersReducer);
  const { selected_order_details } = useAppSelector(
    (state) => state.OrderReducer
  );
  const grade_id = isOrder
    ? "" + selected_order_details.grade_id
    : "" + offer_details_data?.grade_id;
  const closeModal = () => {
    dispatch(setCancelOfferModalSt());
  };

  return (
    <ActionWrapper>
      <h4>Actions</h4>
      <div className="action_btn">
        {isOrder ? (
          <OrderActions details={selected_order_details} />
        ) : (
          <OfferActions details={offer_details_data} />
        )}
      </div>
      <CancelOfferModal
        closeModal={closeModal}
        offer_id={offer_details_data?.id}
      />
      <SaleDuplicationPopup
        open={open_duplicate_sale}
        title={isOrder ? "Sell Again" : "Duplicate Price Request"}
        grade_id={grade_id}
        offer_details={offer_details_data}
        order_details={selected_order_details}
        isOrder={isOrder}
      />
    </ActionWrapper>
  );
};

export default OfferOrderAction;
