export const SplashScreenText = {
  page_first: {
    title: "Recycling Made Easy",
    content:
      "Transform your recyclables into cash with Hubbit! Earn competitive prices from GP Recylcing for your materials and help create a greener world. Sell effortlessly and profitably now!",
  },
  page_second: {
    title: "Upfront Pricing",
    content:
      "Find out how much your recyclable material is worth and sell instantly with our upfront pricing. If you don't see the pricing you're looking for, simply request it!",
  },
  page_third: {
    title: "Sales Simplified",
    content: `We'll ask you a few questions about the material, the quantity you're selling and how you want to ship the material. Guiding you through the process step by step.`,
  },

  page_fourth: {
    title: "Direct Access",
    content:
      "Track your sale status and communicate directly with your GPR Trader within the hubbIT app.",
  },
};
