import styled from "styled-components";
import { fontMedium14, fontNormal14 } from "../../utils/styleConsts";

export const MakeYourSaleWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 840px) {
    margin-top: 1rem;
  }
  .content {
    max-width: 100rem;
    width: 100%;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 1280px) {
      // flex-wrap: wrap-reverse;
      overflow-y: auto;
      padding-bottom: 12.6rem;
    }

    .summary-box {
      max-width: 40rem;
      width: 100%;
      display: flex;
      flex-direction: column;

      .summary-floater-contact {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5rem;
        padding: 1.6rem;
        padding-top: 0.2rem;
        place-items: center;
        background-color: var(--WHITE);
        z-index: 3;
      }
      @media (max-width: 1280px) {
        max-width: 58rem;

        .summary-contact-box {
          display: none;
        }
        .summary-floater-contact {
          display: grid;
        }
      }

      @media (max-width: 580px) {
        max-width: 100%;
      }
    }
  }

  .option-dropdown {
    min-width: 22rem;
    height: 4.4rem;
    @media (max-width: 840px) {
      height: 4.8rem;
    }
    .ant-select-selector {
      height: 4.4rem;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      ${fontNormal14}
      color:var(--NEUTRAL-4);
    }
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }
`;

export const MYSButtonWrapper = styled.button`
  height: 4.4rem;
  ${fontNormal14};
  padding: 1.2rem 2rem;
  color: var(--NEUTRAL-4);
  border-radius: 1rem;
  border: 0.1rem solid #62707880;
  background: var(--WHITE);
  cursor: pointer;
  white-space: nowrap;
  transition: 0.3s;
  &.active,
  &:hover {
    background: linear-gradient(130.69deg, #283238 -10.43%, #285e5f 150.49%);

    border: 0.1rem solid var(--PRIMARY);
    color: var(--WHITE);
  }
  &.disabled {
    pointer-event: none;
    cursor: not-allowed;
  }
`;

export const MYSPickupAddressLabelWrapper = styled.div`
  color: var(--NEUTRAL-4);
  display: flex;
  gap: 0.6rem;
  h3 {
    ${fontMedium14}
  }
  p {
    ${fontNormal14}
  }
`;
