import { Button } from "antd";
import React, { useState } from "react";
import { IOfferDetailsData } from "../../interfaces/IOfferInterface";
import { ApiURL, roles, status } from "../../utils/constant";
import { useAppDispatch } from "../../hooks/reduxHooks";
import {
  setCancelOfferModalSt,
  updateShowApprovedDetails,
  updateShowDuplicateSale,
} from "../../store/features/OffersActions.action";
import { GTM_acceptcounteroffer } from "../../utils/GTM";
import { updateToggleLoader } from "../../store/features/Common.slice";
import { setCounterOfferChanges } from "../../store/features/CreateOfferOrderFormReducer.slice";
import { GET, PUT } from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";

import ApprovePricePopup from "./ApprovePricePopup";
import { updateOfferDetailsLoader } from "../../store/features/Offers.slice";

const OfferActions = (props: { details: IOfferDetailsData | null }) => {
  const { details } = props;
  const { id } = useParams();

  const [showApprove, setShowApprove] = useState(false);
  const isSupplier = details?.offer_details?.owner_role === roles.SUPPLIER;
  const isAdmin =
    details?.trade_history?.[0].action_initiated_by === roles.ADMIN;
  const isTrader =
    details?.trade_history?.[0].action_initiated_by === roles.TRADER;

  const isCancelled = details?.status === status.canceled;
  const isDeclined = details?.status === status.declined;
  const isExpired = details?.status === status.expired;
  const isAccepted = details?.status === status.accepted;
  const isWaitingSupplier = details?.status === status.waiting_for_supplier;
  const isWaitingTrader = details?.status === status.waiting_for_trader;

  const showReasonText = isCancelled || isDeclined;
  const showSellAgain = isCancelled || isDeclined || isExpired || isAccepted;

  const traderName = details?.trader?.full_name;
  const endNote = details?.end_note;

  const actionMessageType = isCancelled
    ? endNote !== null
      ? "cancelled"
      : "cancelledWithoutReason"
    : isDeclined
    ? "declined"
    : "default";

  const actionMessage = {
    cancelled: `This Price Request was canceled by ${
      isSupplier ? "you" : "Trader " + traderName
    } with following
        reason:`,
    declined: `This Price Request was declined by ${
      isAdmin ? "Admin" : isTrader ? traderName : "you"
    }${endNote ? " with following reason:" : "."} `,
    default: ``,
    cancelledWithoutReason: `This offer was canceled by ${
      isSupplier ? "you" : "Trader " + traderName
    }.`,
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onCancel = () => {
    dispatch(setCancelOfferModalSt());
  };

  const openApprovePricePopup = () => {
    setShowApprove(true);
  };

  const onApprovePrice = () => {
    setShowApprove(false);

    dispatch(updateToggleLoader(true));
    dispatch(
      PUT("acceptOffer", `${ApiURL.OFFERS_LIST}/${details?.id}/accepted`, {})()
    ).then((res) => {
      const values = {
        proposed_price: details?.offer_details.price
          ? details?.offer_details.price
          : 0,
        notes: details?.offer_details.notes ? details?.offer_details.notes : "",
      };
      dispatch(setCounterOfferChanges(values));
      dispatch(updateToggleLoader(false));

      GTM_acceptcounteroffer({
        grade_name: res?.payload?.data.grade.name,
        grade_type: res?.payload?.data.grade.category_name,
        ship_type: res?.payload?.data.shipment_type,
        order_id: res?.payload?.data.id,
        price: res?.payload?.data.total_price,
        loads: res?.payload?.data.loads,
        min_weight: res?.payload?.data.min_weight,
        total_weight: res?.payload?.data.total_weight,
        unit_price: res?.payload?.data.unit_price,
      });
      dispatch(GET("offerDetailsData", `${ApiURL.OFFERS_LIST}/${id}`)());
      dispatch(updateShowApprovedDetails(true));
    });
  };

  const onCounterOffer = () => {
    dispatch(updateOfferDetailsLoader(true));
    navigate(`/home/offers/counter-offer/${details?.id}`);
  };

  const onDuplicateOfferPopup = () => {
    dispatch(updateShowDuplicateSale(true));
  };

  return (
    <>
      {showReasonText && (
        <div className="action-text">
          <p className="light-text">{actionMessage[actionMessageType]}</p>
          {details?.end_note !== null && (
            <p className="reason-text">{details?.end_note}</p>
          )}
        </div>
      )}

      <div className="action-button-wrapper">
        {(isWaitingTrader || showSellAgain) && (
          <Button
            onClick={onDuplicateOfferPopup}
            className="button"
            size="large"
            type="primary"
            block
          >
            Duplicate Price Request
          </Button>
        )}

        {isWaitingTrader && (
          <Button
            onClick={onCancel}
            className="button"
            size="large"
            type="default"
            block
          >
            Cancel Price Request
          </Button>
        )}

        {isWaitingSupplier && (
          <>
            <Button
              onClick={openApprovePricePopup}
              className="button"
              size="large"
              type="primary"
              block
            >
              Approve Price
            </Button>

            <Button
              onClick={onCounterOffer}
              className="button"
              size="large"
              type="default"
              block
            >
              Counteroffer
            </Button>
          </>
        )}

        <ApprovePricePopup
          onApprove={onApprovePrice}
          open={showApprove}
          onPopupClose={() => setShowApprove(false)}
          title="Approve Price"
        />
      </div>
    </>
  );
};

export default OfferActions;
