import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GET } from "../../services/api";
import { IDraftReducer } from "../../interfaces/IDraft";

const meta = {
  count: 0,
  page: 0,
  size: 0,
  total_count: 0,
  total_pages: 0,
};
const initialState: IDraftReducer = {
  page: 1,
  search: "",
  seleted_img: "",
  show_preview: false,
  loader: false,
  draft_list: {
    data: [],
    meta: meta,
  },
};

export const draftSlice = createSlice({
  name: "draft_reducer",
  initialState,
  reducers: {
    updateDraftPagination: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    updateDraftSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateDraftLoader: (state, action) => {
      state.loader = action.payload;
    },
    showDraftImgPreview: (state, action) => {
      state.seleted_img = action.payload;
      state.show_preview = true;
    },
    hideDraftImg: (state) => {
      state.seleted_img = "";
      state.show_preview = false;
    },
    resetDraftReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GET("draft/listing", "").pending, (state) => {
      state.loader = true;
    });
    builder.addCase(GET("draft/listing", "").fulfilled, (state, action) => {
      state.loader = false;
      state.draft_list = action.payload;
    });
    builder.addCase(GET("draft/listing", "").rejected, (state) => {
      state.loader = false;
      state.draft_list.data = [];
    });

    builder.addCase(GET("mysDraft/details", "").pending, (state) => {
      state.loader = true;
    });

    builder.addCase(GET("mysDraft/details", "").rejected, (state) => {
      state.loader = false;
    });
  },
});

export default draftSlice.reducer;
export const {
  updateDraftPagination,
  updateDraftSearch,
  resetDraftReducer,
  showDraftImgPreview,
  hideDraftImg,
  updateDraftLoader,
} = draftSlice.actions;
