import { chatbot_events } from "../../utils/eventTrackingConstants";

export const heap_viewchatbot = ({ screen = "" }) => {
  const location = screen.split("/");
  const namereplace: { [id: string]: string } = {
    grades: "pricing",
    index: "home",
  };
  let screenName = namereplace[location[2]] || location[2];

  if (location.length === 4) {
    screenName += "details";
  }
  if (location.length > 4) {
    screenName += location[location.length - 2] + location[location.length - 1];
  }
  heap.track(chatbot_events.VIEW_CHATBOT, {
    screen: screenName,
  });
};

export const heap_chatbotcontacttrader = () => {
  heap.track(chatbot_events.CHATBOT_CONTACT_TRADER);
};

export const heap_chatbotfeedback = ({ helpful = "" }) => {
  heap.track(chatbot_events.CHATBOT_FEEDBACK, {
    helpful: helpful,
  });
};

export const heap_chatbotcategory = ({
  category_id = "",
  category_label = "",
}) => {
  heap.track(chatbot_events.CHATBOT_CATEGORY, {
    category_id: category_id,
    category_label: category_label,
  });
};
export const heap_chatbotquestion = ({
  question_id = "",
  question_label = "",
}) => {
  heap.track(chatbot_events.CHATBOT_QUESTION, {
    question_id: question_id,
    question_label: question_label,
  });
};

export const heap_chatbotquery = () => {
  heap.track(chatbot_events.CHATBOT_QUERY);
};
