import { Auth } from "@aws-amplify/auth";
import { Storage } from "aws-amplify";

Auth.configure({
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  mandatorySignIn: true,
  authenticationFlowType: "USER_SRP_AUTH",
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
});

Storage.configure({
  AWSS3: {
    bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
  }
});