import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CompanyInterface,
  IUserSalesData,
  SettingsDataInterface,
  UserDataInterface,
  UserQuickbloxConfigInterface,
} from "../../interfaces/ProfileInterface";
import { GET, PUT } from "../../services/api";
import { ApiURL } from "../../utils/constant";
import { IPickupLocationData } from "../../interfaces/User";

export const defaultLoc: IPickupLocationData = {
  address: "",
  city: "",
  state: "",
  state_id: "",
  zip_code: "",
  id: 0,
  name: "",
  is_editable: false,
  is_default: false,
  supplier_id: 0,
  address2: "",
  address3: "",
  region: "",
};
export const defaultsalesdetails: IUserSalesData = {
  total_sales: 0,
  total_weight: 0,
  offer_activity: { active: 0, total: 0 },
  order_activity: { active: 0, total: 0 },
};

const initialState: {
  changePasswordModalSt: boolean;
  profileLoader: boolean;
  companyData: CompanyInterface | null;
  defaultLocation: IPickupLocationData;
  userData: UserDataInterface | null;
  settingsData: SettingsDataInterface | null;
  quickbloxData: UserQuickbloxConfigInterface | null;
  salesData: IUserSalesData;
  chat_media_config: {
    chat_image_attachment_count: number;
    chat_image_attachment_size: number;
    chat_video_attachment_count: number;
    chat_video_attachment_size: number;
  };
  trade_media_config: {
    trade_image_attachment_count: number;
    trade_image_attachment_size: number;
  };
  web_chatbot_enabled: boolean;
  feedback_options: string[];
} = {
  changePasswordModalSt: false,
  profileLoader: true,
  companyData: null,
  defaultLocation: defaultLoc,
  userData: null,
  settingsData: null,
  quickbloxData: null,
  salesData: defaultsalesdetails,
  chat_media_config: {
    chat_image_attachment_count: 10,
    chat_image_attachment_size: 50,
    chat_video_attachment_count: 10,
    chat_video_attachment_size: 50,
  },
  trade_media_config: {
    trade_image_attachment_count: 0,
    trade_image_attachment_size: 0,
  },
  web_chatbot_enabled: false,
  feedback_options: [],
};

export const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileModalSt: (state, action: PayloadAction<boolean>) => {
      state.changePasswordModalSt = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserDataInterface | null>) => {
      state.userData = action.payload;
    },
    setCompanyData: (state, action: PayloadAction<CompanyInterface | null>) => {
      state.companyData = action.payload;
    },
    updateDefaultLocation: (
      state,
      action: PayloadAction<IPickupLocationData>
    ) => {
      state.defaultLocation = action.payload;
    },

    resetProfileReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("profileDetails", `${ApiURL.PROFILE_DETAILS}`).pending,
      (state, action) => {
        state.profileLoader = true;
        state.defaultLocation = defaultLoc;
      }
    );
    builder.addCase(
      GET("profileDetails", `${ApiURL.PROFILE_DETAILS}`).fulfilled,
      (state, action) => {
        const result = action.payload.data;
        state.profileLoader = false;
        state.userData = {
          first_name: result?.first_name.trim(),
          last_name: result?.last_name.trim(),
          email: result?.email,
          phone: result?.phone,
          id: result?.id,
          name: result?.name.trim(),
          firstLastChar:
            result.first_name?.trim().charAt(0) +
            result.last_name?.trim().charAt(0),
        };
        if (action.payload?.data?.global_config?.mys_exit_feedback_options)
          state.feedback_options =
            action.payload.data.global_config.mys_exit_feedback_options;
        state.web_chatbot_enabled =
          action.payload.data.global_config.web_chatbot_enabled;
        state.chat_media_config = {
          chat_image_attachment_count:
            result.global_config.media_config.chat_image_attachment_count,
          chat_image_attachment_size:
            result.global_config.media_config.chat_image_attachment_size,
          chat_video_attachment_count:
            result.global_config.media_config.chat_video_attachment_count,
          chat_video_attachment_size:
            result.global_config.media_config.chat_video_attachment_size,
        };
        state.trade_media_config = {
          trade_image_attachment_count:
            result.global_config.media_config.trade_image_attachment_count,
          trade_image_attachment_size:
            result.global_config.media_config.trade_image_attachment_size,
        };
        heap.identify(result.email);

        heap.addUserProperties({
          app_type: "Web",
          platform_name: "Web",
          supplier_name: result?.name,
          email: result?.email,
          mobile_number: result?.phone,
          supplier_id: result?.id,
          company_name: result.company?.name,
          company_address:
            result.company?.address +
            result.company?.address_2 +
            result.company?.address_3,
          company_city: result.company?.city,
          company_state: result.company?.state,
          company_zip_code: result.company?.zip_code,
          default_location_name: result.default_location?.name,
          default_location_address:
            result.default_location?.address +
            result.default_location?.address_2 +
            result.default_location?.address_3,
          default_location_city: result.default_location?.city,
          default_location_state: result.default_location?.state,
          default_location_zip_code: result.company?.zip_code,
          trader_id: result.trader_details.id,
          trader_name: result.trader_details.name,
          no_of_locations: result.location_count,
          company_location_default: result.is_company_default ? "Yes" : "No",
        });

        state.companyData = result.company;
        state.defaultLocation = action.payload.data.default_location;

        state.settingsData = {
          id: result.id,
          email_pricing_alerts: result.email_pricing_alerts,
          email_trade_notifications: result.email_trade_notifications,
          email_trade_reminders: result.email_trade_reminders,
          push_pricing_alerts: result.push_pricing_alerts,
          push_trade_notifications: result.push_trade_notifications,
          push_trade_reminders: result.push_trade_reminders,
        };
        state.quickbloxData = {
          backend_id: result.quickblox_config.backend_id,
          global_admin_id: result.quickblox_config.global_admin_id,
          trader_id: result.quickblox_config.trader_id,
        };
        state.salesData = {
          total_weight: result.total_weight || 0,
          offer_activity: result.offer_activity,
          order_activity: result.order_activity,
          total_sales: result.total_sales,
        };
      }
    );
    builder.addCase(
      GET("profileDetails", `${ApiURL.PROFILE_DETAILS}`).rejected,
      (state) => {
        state.profileLoader = false;
        state.userData = null;
        state.defaultLocation = defaultLoc;
        state.salesData = {
          total_weight: 0,
          offer_activity: { active: 0, total: 0 },
          order_activity: { active: 0, total: 0 },
          total_sales: 0,
        };
      }
    );
    builder.addCase(
      PUT("profileUpdate", `${ApiURL.PROFILE_DETAILS}`).pending,
      (state, action) => {
        state.profileLoader = true;
      }
    );
    builder.addCase(
      PUT("profileUpdate", `${ApiURL.PROFILE_DETAILS}`).fulfilled,
      (state, action) => {
        const result = action.payload.data;
        state.profileLoader = false;
        state.userData = {
          ...state.userData,
          first_name: result.first_name,
          last_name: result.last_name,
          name: `${result.first_name} ${result.last_name}`,
          firstLastChar:
            result.first_name?.charAt(0) + result.last_name?.charAt(0),
        };
        state.companyData = action.payload.data.company;
      }
    );
    builder.addCase(
      PUT("profileUpdate", `${ApiURL.PROFILE_DETAILS}`).rejected,
      (state) => {
        state.profileLoader = false;
        state.userData = null;
        state.companyData = null;
      }
    );

    builder.addCase(
      PUT("settingsUpdate", `${ApiURL.PROFILE_DETAILS}`).pending,
      (state, action) => {
        state.profileLoader = true;
      }
    );
    builder.addCase(
      PUT("settingsUpdate", `${ApiURL.PROFILE_DETAILS}`).fulfilled,
      (state, action) => {
        const result = action.payload.data;
        state.settingsData = {
          ...state.settingsData,
          email_pricing_alerts: result.email_pricing_alerts,
          email_trade_notifications: result.email_trade_notifications,
          email_trade_reminders: result.email_trade_reminders,
          push_pricing_alerts: result.push_pricing_alerts,
          push_trade_notifications: result.push_trade_notifications,
          push_trade_reminders: result.push_trade_reminders,
        };
      }
    );
    builder.addCase(
      PUT("settingsUpdate", `${ApiURL.PROFILE_DETAILS}`).rejected,
      (state) => {
        state.settingsData = null;
      }
    );
  },
});

export default ProfileSlice.reducer;
export const {
  setProfileModalSt,
  setUserData,
  setCompanyData,
  updateDefaultLocation,
  resetProfileReducer,
} = ProfileSlice.actions;
