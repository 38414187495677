import styled from "styled-components";
import { MessagesIcon } from "../Icons/MenuIcons";
import { useNavigate } from "react-router-dom";
import bgImg from "../../assets/images/home/bg-img.png";
import {
  updateActiveDialogDetails,
  updateResponsiveChat,
} from "../../store/features/Messages.slice";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { MessageDialogListInterface } from "../../interfaces/MessagesInterface";
import { useEffect, useState } from "react";
import QB_retriveChatHistory from "../../utils/QuickBlox/QB_retriveChatHistory";
import QB_onCreateDialog from "../../utils/QuickBlox/QB_onCreateDialog";

const ContactTextBoxWrapper = styled.p`
  font-size: 1.4rem;
  font-family: "Gotham";
  font-weight: 325;
  color: var(--NEUTRAL-4);
  span {
    cursor: pointer;
    font-family: "GothamMedium";
    color: var(--PRIMARY);
    font-weight: 350;
  }
`;

const DarkGreyBox = styled.div<{ theme: string }>`
  background: var(
    --${(p) => (p.theme === "dark" ? "NEUTRAL-4" : "WHITE")}
  ) !important;
  border-radius: 1rem;
  padding: 1.6rem;
  display: flex;
  height: 8.2rem;
  box-shadow: ${(p) =>
    p.theme === "dark" ? "none" : "0px 4px 40px 0px #0000001A"};

  background-image: url(${(p) => (p.theme === "dark" ? bgImg : "")});
  background-position-y: bottom;
  background-position-x: right;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  margin-top: 1.6rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  @media (min-width: 1024px) and (max-width: 1100px) {
    height: auto;
  }
  @media (min-width: 787px) and (max-width: 840px) {
    height: auto;
  }
  .text {
    display: grid;
    gap: 0.6rem;
    color: var(--WHITE);
    font-size: 1.6rem;
    span:first-child {
      opacity: ${(p) => (p.theme === "dark" ? "0.7" : "1")};
      color: var(--${(p) => (p.theme === "dark" ? "WHITE" : "NEUTRAL-3")});

      font-size: 1.4rem;
      font-weight: 325;
    }
    span: last-child {
      font-family: "GothamMedium";
      color: var(--${(p) => (p.theme === "dark" ? "WHITE" : "NEUTRAL-4")});

      font-weight: 350;
    }
  }
  .contact_icon {
    background: var(--SECONDARY);
    border-radius: 1rem;
    width: 5.2rem;
    height: 5.2rem;
    display: flex;
    place-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      height: 2.1rem;
      width: 2.1rem;
    }
  }
`;
type Props = {
  detail_type: string;
  relavant_id: number | undefined;
  message_topic?: string | undefined;
  dialog_id?: string | undefined;
  theme?: string;
  from?: string;
  type?: "box" | "text";
  className?: string;
};

const textContent: any = {
  default: "Need more information?",
  order: "Have any questions?",
};
const ContactTrader = ({
  detail_type = "",
  relavant_id = 0,
  message_topic = "",
  dialog_id = "",
  theme = "dark",
  from = "default",
  type = "box",
  className = "",
}: Props) => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const {
    global_admin_details,
    backend_details,
    user_id,
    dialog_list,
    chat_history,
  } = useAppSelector((state) => state.MessagesReducer);
  const { quickbloxData } = useAppSelector((state) => state.ProfileReducer);
  const [traderId, setTraderId] = useState<number>(0);

  const occupants_id = [
    global_admin_details.id,
    backend_details.id,
    user_id,
    traderId,
  ];
  const permitted_users = [user_id, traderId].sort();
  const chat_name = `${message_topic}`;

  useEffect(() => {
    if (quickbloxData?.trader_id) {
      setTraderId(quickbloxData?.trader_id);
    }
  }, [quickbloxData?.trader_id]);
  const triggerChat = () => {
    // dispatch(updateMYSLoader(true));
    const unique_id = `${permitted_users.join(
      "#"
    )}#${relavant_id}#${detail_type.toUpperCase()}`;
    let dailog = {} as MessageDialogListInterface;
    if (dialog_id) {
      if (dialog_list[dialog_id]) {
        dailog = dialog_list[dialog_id];
      }
    } else {
      for (let element of Object.values(dialog_list)) {
        if (element?.data?.unique_id) {
          if (element.data.unique_id === unique_id) {
            dailog = element;
            break;
          }
        }
      }
    }
    if (dailog._id) {
      if (!chat_history[dailog._id]) QB_retriveChatHistory(dailog);
      dispatch(updateActiveDialogDetails(dailog));
      dispatch(updateResponsiveChat(true));
    } else {
      QB_onCreateDialog({
        name: chat_name,
        occupants_ids: occupants_id,
        permitted_user: permitted_users,
        groupType: detail_type,
        unique_id: unique_id,
        message: "",
      });
    }
    nav("/home/messages");
  };

  const boxType = (
    <DarkGreyBox className={className} theme={theme}>
      <span className="text">
        <span>
          {textContent[from] ? textContent[from] : textContent["default"]}
        </span>
        <span>Chat your Trader</span>
      </span>
      <span className="contact_icon" onClick={triggerChat}>
        <MessagesIcon />
      </span>
    </DarkGreyBox>
  );

  const textType = (
    <ContactTextBoxWrapper>
      Have any questions? <span onClick={triggerChat}>Chat with Trader</span>
    </ContactTextBoxWrapper>
  );
  return type === "box" ? boxType : textType;
};

export default ContactTrader;
