import { QB } from "../QuickBlox_initiation";

const QB_sendSystemMessage = (opponentId: number, id: string) => {
  opponentId = +opponentId;
  let message = {
    body: "New Group Created",
    extension: {
      new_dailog_id: id,
    },
  };
  try {
    QB.chat.sendSystemMessage(opponentId, message);
  } catch (e: any) {
    if (e.name === "ChatNotConnectedError") {
      // not connected to chat
    }
  }
};

export default QB_sendSystemMessage;
