import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import PrivateRoute from "../utils/PrivateRoute";
import OrderDetails from "../pages/OrderDetails/OrderDetails";
import { SplashScreen } from "../pages/SplashScreen/SplashScreen";
import Error from "../components/Error/Error";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import IndexPage from "./IndexPage";

const LandingLayout = React.lazy(() => import("../layout/LandingLayout"));
const HomeLayout = React.lazy(() => import("../layout/HomeLayout"));
const SigninLayout = React.lazy(() => import("../layout/SigninLayout"));
const LoginForm = React.lazy(() => import("../pages/LoginForm/LoginForm"));
const ForgotPassword = React.lazy(
  () => import("../pages/ForgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(
  () => import("../pages/ResetPassword/ResetPassword")
);
const Home = React.lazy(() => import("../pages/Home/Home"));
const Pricing = React.lazy(() => import("../pages/Pricing/Pricing"));
const Legal = React.lazy(() => import("../pages/Legal/Legal"));
const Help = React.lazy(() => import("../pages/Help/Help"));
const Message = React.lazy(() => import("../pages/Messages/Messages"));
const Offers = React.lazy(() => import("../pages/Offers/Offers"));
const OfferDetails = React.lazy(
  () => import("../pages/OfferDetails/OfferDetails")
);
const CounterOffer = React.lazy(
  () => import("../pages/OfferDetails/CounterOffer/CounterOffer")
);
const Orders = React.lazy(() => import("../pages/Orders/Orders"));
const Drafts = React.lazy(() => import("../pages/Drafts/Drafts"));
const Profile = React.lazy(() => import("../pages/Profile/Profile"));
const SignupForm = React.lazy(() => import("../pages/SignupForm/SignupForm"));
const MakeYourSale = React.lazy(
  () => import("../pages/MakeYourSale/MakeYourSale")
);
const MYSsaleConfirmation = React.lazy(
  () => import("../pages/MakeYourSale/MYSsaleConfirmation/MYSsaleConfirmation")
);

const MyNotifications = React.lazy(
  () => import("../pages/MyNotifications/MyNotifications")
);
const OutOfListGrade = React.lazy(
  () => import("../pages/OutOfListGrade/OutOfListGrade")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <SigninLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <LoginForm />,
      },
      {
        path: "sign-up",
        element: (
          <Suspense>
            <SignupForm />
          </Suspense>
        ),
      },
      {
        path: "forgotPassword",
        element: <ForgotPassword />,
      },
      {
        path: "resetPassword",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/welcome",
    element: (
      <Suspense>
        <LandingLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <SplashScreen />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
    ],
  },
  {
    path: "/home",
    element: (
      <Suspense fallback={<Loader />}>
        <HomeLayout />
      </Suspense>
    ),
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <IndexPage />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "index",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Home />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },

      {
        path: "grades",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Pricing />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },

      {
        path: "grades/:id/:zipcode/:step_id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <MakeYourSale />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "draft/:draft_id/:id/:zipcode/:step_id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <MakeYourSale />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "sale-confirmation/:id/:zipcode/:sale_id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <MYSsaleConfirmation />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "messages",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Message />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "legal",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Legal />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "help",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Help />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "offers",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Offers />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "offers/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <OfferDetails />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "offers/counter-offer/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <CounterOffer />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "offer/out-of-list",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <OutOfListGrade />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "orders",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Orders />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "orders/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <OrderDetails />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "drafts",
        element: (
          <Suspense fallback={<Loader />}>
            <Drafts />
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "my-profile/details",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Profile />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "my-profile/grades",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Profile />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "my-profile/locations",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Profile />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "my-profile/settings",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <Profile />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "notifications",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={["supplier"]}>
              <MyNotifications />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      { path: "*", element: <PageNotFound isInnerRoute={true} /> },
    ],
  },
  { path: "*", element: <PageNotFound isInnerRoute={false} /> },
]);

export default router;
