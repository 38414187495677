export const signin_events = {
  SIGNIN: "Sign In Success",
};
export const signup_events = {
  PERSONAL_DETAILS: "Personal Details",
  COMPANY_INFO: "Company Information",
  SIGNUP: "Sign up",
  SIGNUP_FAIL: "Fail Signup",
  SIGNUP_VIEW: "Signup Pageview",
  VERIFY_EMAIL: "Verify Email",
  GET_STARTED: "Get Started",
};

export const profile_events = {
  PREFERRED_GRADE: "Add Preferred Grade",
  UPDATE_DEFAULT_LOCATION: "Update Default Location",
  DELETE_PICKUP_LOCATION: "Delete Pickup Location",
  ADD_PICKUP_LOCATION: "Add Pickup Location",
  UPDATE_ACCOUNT: "Update Account",
  UPDATE_NOTIFICATIONS: "Update notifications",
};

export const messages_events = {
  SEND_MESSAGE: "Send Message",
};

export const grade_events = {
  VIEW_GRADE_DETAILS: "View Pricing Details",
  ACTION_IMPORTANT_POPUP: "Action Important Popup",
  SEARCH_GRADES: "Search Grade",
  CHANGE_MIN_WEIGHT: "Change Min Weight",
  CHANGE_SHIP_TYPE: "Change Ship Type",
  CHANGE_LOCATION: "Change Location",
};

export const deals_events = {
  VIEW_DEALS_DETAILS: "View Pricing Details",
};

export const page_view_events = {
  VIEW_HOME: "View Home",
  VIEW_DEALS: "View Deals",
  VIEW_GRADES: "View Grades",
};

export const offer_order_events = {
  START_OFFER: "Click Start Offer",
  LOAD_TERMS: "Enter Load Details",
  START_ORDER: "Click Start Order",
  PRICING_PARAMETERS: "Enter Pricing Parameters",
  DESTINATION_PICKUP: "Enter Destination or Pickup",
  REVIEW_ORDER: "Click Review Order",
  REVIEW_OFFER: "Click Review Offer",
  SUBMIT_ORDER: "Submit Order",
  SUBMIT_OFFER: "Submit Offer",
  SAVE_ORDER: "Save Order Draft",
  SAVE_OFFER: "Save Offer Draft",
  SELECT_SHIP_TYPE: "Select Ship Type",
};

export const chatbot_events = {
  VIEW_CHATBOT: "View Chatbot",
  CHATBOT_CONTACT_TRADER: "Chatbot Contact Trader",
  CHATBOT_FEEDBACK: "Chatbot Feedback",
  CHATBOT_CATEGORY: "Chatbot Category",
  CHATBOT_QUESTION: "Chatbot Question",
  CHATBOT_QUERY: "Chatbot Query",
};
