import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  code: string;
  isOtpScreen: boolean;
  showSignupSuccessScreen: boolean;
} = {
  code: "",
  isOtpScreen: false,
  showSignupSuccessScreen: false,
};

export const OtpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    setOtpCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    showSignupSuccessScreen: (state, action: PayloadAction<any>) => {
      state.showSignupSuccessScreen = action.payload;
    },
    resetOtpReducer: () => initialState,
  },
});

export default OtpSlice.reducer;
export const { setOtpCode, resetOtpReducer, showSignupSuccessScreen } =
  OtpSlice.actions;
