import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxHooks";
import { StoreStatus } from "../interfaces/authentication";

const PrivateRoute = ({
  roles,
  children
}: {
  roles: string[];
  children: any
}) => {
  const { isAuthenticated, user, status } = useAppSelector(
    (state) => state.AuthReducer
  );
  if (!isAuthenticated && status !== StoreStatus.Loading) {
    return <Navigate to="/" />;
  }
  if (isAuthenticated && user) {
    let custom_user_role = JSON.parse(user["custom:user_role"]).map(
      (el: string) => {
        return el.toLowerCase();
      }
    );
    if (
      roles.some((role) => {
        return custom_user_role.includes(role.toLowerCase());
      })
    ) {
      //code to handle supplier and admin roles
      return children;
    }
    return <Navigate to="/" />; /// needs to be chaned with no access page route
  }
};

export default PrivateRoute;
