import { ISummaryDetails } from "../../interfaces/ICommonData";
import { Price, TextBold } from "../OfferOrderSummary/OfferOrderSummaryStyled";
export const Details = ({ label = "", value = "" }: ISummaryDetails) => {
  return (
    <div className="details">
      <span className="label">{label}:</span>
      <span className="value">{value}</span>
    </div>
  );
};

export const DetailsPricing = ({
  label = "",
  value = "",
  changed_value = "",
  type = "",
}: ISummaryDetails) => {
  return (
    <div className="details">
      <span className="label">{label}:</span>
      <Price>
        <span>{value}</span>
        {type !== "order" && (
          <span className="price_change_text">
            Changed from: {changed_value}
          </span>
        )}
      </Price>
    </div>
  );
};

export const BoldDetails = ({ label = "", value = "" }: ISummaryDetails) => {
  return (
    <TextBold className="details text_bold">
      <span>{label}:</span>
      <span className="value">{value}</span>
    </TextBold>
  );
};
