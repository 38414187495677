import { useState } from "react";
import useScreenType from "../../hooks/useScreenType";
import { ISummaryDetails } from "../../interfaces/ICommonData";
import {
  BoldDetails,
  Details,
  DetailsPricing,
} from "../OfferOrderDetailsComponents/OfferOrderDetailsComponents";
import { SCREEN_TYPE } from "../../utils/constant";
import {
  DetailsSummarySeperationBorderWrapper,
  LoadTermsBoxWrapper,
  Summary,
} from "./OfferOrderSummaryStyled";
import OfferOrderSubHeading from "../OfferOrderSubHeading/OfferOrderSubHeading";
import { status } from "../../utils/constant";
import { MYSLoadTermsMap } from "../../pages/MakeYourSale/MYSconst";

const LoadTermsBox = (props: ISummaryDetails) => {
  const { label, value } = props;
  const img = MYSLoadTermsMap["" + value?.toString().toLowerCase()];
  return (
    <LoadTermsBoxWrapper>
      <div className="text-content">
        <p className="label">{label}</p>
        <p className="value">{value}</p>
      </div>
      <div className="img-holder">
        <img src={img} alt="load" />
      </div>
    </LoadTermsBoxWrapper>
  );
};

const OfferOrderSummary = ({
  type = "",
  details = [],
  offer_status = "",
}: {
  type: string;
  details: ISummaryDetails[];
  offer_status?: string;
}) => {
  const screenType = useScreenType();
  const desktopView =
    screenType === SCREEN_TYPE.DESKTOP || screenType === SCREEN_TYPE.LAPTOP;
  const [openCarosal, setOpenCarosal] = useState(true);

  const handleSetOpenCarosal = () => {
    setOpenCarosal(!openCarosal);
  };

  const summaryDetails = details.map((el, index) => {
    if (el.label.toLowerCase() === "load terms") {
      return <LoadTermsBox {...el} />;
    }
    if (el.pricing) {
      return <DetailsPricing key={index} {...el} />;
    }

    if (el.bold) {
      return <BoldDetails key={index} {...el} />;
    }
    let addBorder = false;
    if (el.label.toLowerCase() === "unit price") addBorder = true;

    return (
      <>
        {addBorder && <DetailsSummarySeperationBorderWrapper />}
        <Details key={index} {...el} />
      </>
    );
  });
  const detailstypeCopy =
    type.toLowerCase() === "offer" ? "Price Request" : "Sale";
  return (
    <Summary desktop={desktopView} openCarosal={openCarosal}>
      <OfferOrderSubHeading
        heading={`${detailstypeCopy} Summary`}
        setOpenCarosal={handleSetOpenCarosal}
        showIcon={false}
      />
      {type === "Offer" && offer_status === status.waiting_for_supplier ? (
        <div className="action-needed-green-banner">
          Counteroffer is received from Trader
        </div>
      ) : (
        ""
      )}
      <div className="info">{summaryDetails}</div>
    </Summary>
  );
};

export default OfferOrderSummary;
