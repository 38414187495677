import { useEffect } from "react";
import { updateHeaderName } from "../store/features/Common.slice";
import { capitalizeFirstLetter } from "../utils/utils";
import { useAppDispatch } from "./reduxHooks";

const useUpdateHeaderName = (headername: string) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(updateHeaderName(capitalizeFirstLetter(headername)));
  }, [dispatch, headername]);
};

export default useUpdateHeaderName;
