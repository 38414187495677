import styled from "styled-components";
import {
  IShipmentInfo,
  IShippingSchedule,
} from "../../interfaces/IOfferInterface";
import React from "react";
import dayjs from "dayjs";
import {
  IOrderShippingLocation,
  ToAddress,
} from "../../interfaces/OrdersInterface";
import { getLocalTimeDate } from "../../utils/DateNTimeConvertor";
import { SHIPMENT_TYPE } from "../../utils/constant";

const OfferDeliveryInfo = styled.div`
  .deliveryinfotitle {
    font-family: "GothamMedium";
    font-style: normal;
    font-weight: 350;
    font-size: 1.6rem;
    line-height: 150%;
    color: var(--NEUTRAL-4);
  }
  .details,
  .additonal_details {
    grid-template-columns: 50% auto;
    display: grid;
    color: var(--NEUTRAL-4);
    margin: 1.6rem 0 0 0;
    .label {
      color: var(--NEUTRAL-3);
      font-family: "GothamMedium";
    }
  }
  .label-padding {
    padding: 2rem 0 0 0;
    .schedule-dates {
      padding-bottom: 2rem;
    }
  }
  .listingshippingschedule {
    display: flex;
    flex-direction: column;
  }
`;

const OfferDeliveryInformation = (props: {
  shipment_info: IShipmentInfo | IOrderShippingLocation | undefined;
  shipment_schedule: IShippingSchedule[] | undefined;
  shipment_type?: string;
  toAddress?: ToAddress;
}) => {
  return (
    <OfferDeliveryInfo>
      <div className="deliveryinfotitle">
        {props?.shipment_type?.includes("FOB")
          ? "Pickup Information"
          : "Delivery Information"}
      </div>
      <div className="label-padding">
        <div className="details">
          <span className="label">
            {props?.shipment_type?.includes("FAS")
              ? "Destination"
              : "Pickup address"}
            :
          </span>

          {props?.shipment_type === SHIPMENT_TYPE.FAS ? (
            <>
              <span className="value">{props.shipment_info?.city}</span>
            </>
          ) : (
            <span className="value">
              {props.shipment_info?.address +
                ", " +
                props.shipment_info?.city +
                ", " +
                props.shipment_info?.state +
                ", " +
                props.shipment_info?.zip_code}
            </span>
          )}
        </div>
        <div className="details">
          <span className="label">
            {" "}
            {props?.shipment_type?.includes("FAS")
              ? "Delivery schedule"
              : "Pickup schedule"}
            :
          </span>
          <div className="listingshippingschedule">
            {(props.shipment_schedule || []).length > 0
              ? props.shipment_schedule?.map((item) => (
                  <React.Fragment key={item.id}>
                    <span className="value schedule-dates">
                      {dayjs(getLocalTimeDate(item.shipping_day)).format(
                        "MM/DD/YYYY"
                      )}{" "}
                      - {item.loads} loads
                    </span>
                  </React.Fragment>
                ))
              : "N/A"}
          </div>
        </div>
      </div>
    </OfferDeliveryInfo>
  );
};

export default OfferDeliveryInformation;
