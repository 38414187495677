import { Auth } from "@aws-amplify/auth";
import { useEffect } from "react";
import { StoreStatus } from "../interfaces/authentication";
import { setLoginUserDetails } from "../store/features/Auth.slice";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { GET } from "../services/api";
import { ApiURL } from "../utils/constant";
import { hasNonPermissibleRoles } from "../utils/utils";
import { signin_events } from "../utils/eventTrackingConstants";
import { updateOneSignalInit } from "../store/features/Notification.slice";
import { updateUserData } from "../store/features/Messages.slice";
import { GTM_login } from "../utils/GTM";

const useAuth = () => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.ProfileReducer);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        const { attributes } = data;
        const payload = {
          isAuthenticated: hasNonPermissibleRoles(attributes),
          status: StoreStatus.Succeeded,
          user: attributes,
          token: data.signInUserSession.accessToken.jwtToken,
        };
        if (!userData) {
          dispatch(GET("profileDetails", `${ApiURL.PROFILE_DETAILS}`)()).then(
            () => {
              dispatch(updateOneSignalInit(true));
              heap.track(signin_events.SIGNIN);
              GTM_login(data.username);
            }
          );
        }
        dispatch(updateUserData(payload.user));

        dispatch(setLoginUserDetails(payload));
      })
      .catch(() => {
        const payload = {
          isAuthenticated: false,
          status: StoreStatus.Failed,
          user: {
            email: "",
            email_verified: false,
            given_name: "",
            family_name: "",
            sub: "",
            "custom:user_role": "",
            phone_number: "",
          },
          token: "",
        };
        dispatch(setLoginUserDetails(payload));
      });
  }, [dispatch, userData]);
};

export default useAuth;
