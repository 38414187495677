import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/api";
import { ApiURL, emptyTableText } from "../../utils/constant";
import {
  IGradeList,
  ILocationList,
  IOffersDetails,
} from "../../interfaces/MyTradeInterface";
import {
  ICity,
  IDestinations,
  IOfferDetailsData,
  IOfferFilterSelected,
  IOfferSort,
} from "../../interfaces/IOfferInterface";

const initialState: {
  offers_details: IOffersDetails[] | [];
  offers_list_home: IOffersDetails[];
  offers_list_home_loaded: boolean;
  filterBtnActiveSt: boolean;
  filterIconActiveSt: boolean;
  page_length: number;
  total_items: number;
  offer_current_page: number;
  gradesList: IGradeList[];
  locationList: ILocationList[];
  cityList: ICity[];
  destinationList: IDestinations[];
  filtersModalSt: boolean;
  offersFilterCount: string[];
  search: string;
  order_current_page: number;
  order_table_loader: boolean;
  offer_details_data: IOfferDetailsData | null;
  unit: string;
  duplicateOfferSt: boolean;
  offerFilterQuery: string;
  offerFilter: IOfferFilterSelected;
  sortOptions: IOfferSort;
  offer_details_loader: boolean;
  offerSubmitted: boolean;
  imageList: string[];
  offerFilterCount: number;
  emptyText: string;
  offer_status_options: [];
} = {
  offers_details: [],
  offers_list_home: [],
  offers_list_home_loaded: false,
  filterBtnActiveSt: false,
  filterIconActiveSt: false,
  page_length: 0,
  total_items: 0,
  offer_current_page: 1,
  gradesList: [],
  locationList: [],
  cityList: [],
  destinationList: [],
  filtersModalSt: false,
  offersFilterCount: [],
  search: "",
  order_current_page: 1,
  order_table_loader: false,
  offer_details_data: null,
  unit: "",
  duplicateOfferSt: false,
  offerFilterQuery: "",
  offerFilter: {
    locationdropdown: undefined,
    gradelistDropdown: undefined,
    statusDropDown: undefined,
  },
  sortOptions: {
    sort: undefined,
    order: undefined,
  },
  offer_details_loader: false,
  offerSubmitted: false,
  imageList: [],
  offerFilterCount: 0,
  emptyText: emptyTableText,
  offer_status_options: [],
};

export const OffersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setFilterBtnActiveSt: (state, action: PayloadAction<boolean>) => {
      state.filterBtnActiveSt = action.payload;
    },
    setFilterIconActiveSt: (state, action: PayloadAction<boolean>) => {
      state.filterIconActiveSt = action.payload;
    },
    setFiltersModalSt: (state, action: PayloadAction<boolean>) => {
      state.filtersModalSt = action.payload;
    },
    updateOrderFilterQuery: (state, action: PayloadAction<string>) => {
      state.offerFilterQuery = action.payload;
    },
    updateOfferDetailsLoader: (state, action) => {
      state.offer_details_loader = action.payload;
    },
    updateOfferFilterSelection: (
      state,
      action: PayloadAction<IOfferFilterSelected>
    ) => {
      state.offerFilter = action.payload;
    },
    setSortOptions: (
      state,
      action: PayloadAction<{ sort: string; order: string }>
    ) => {
      state.sortOptions = action.payload;
    },
    setSearchKeyword: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateOfferSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateOfferPagination: (state, action: PayloadAction<number>) => {
      state.offer_current_page = action.payload;
    },
    setDuplicateOfferSt: (state, action: PayloadAction<boolean>) => {
      state.duplicateOfferSt = action.payload;
    },
    updateOfferFilterQuery: (state, action: PayloadAction<string>) => {
      state.offerFilterQuery = action.payload;
    },
    setOfferSubmitted: (state, action: PayloadAction<boolean>) => {
      state.offerSubmitted = action.payload;
    },
    setUploadedImageList: (state, action: PayloadAction<string[]>) => {
      state.imageList = action.payload;
    },
    setOfferFilterCount: (state, action: PayloadAction<number>) => {
      state.offerFilterCount = action.payload;
    },
    setEmptyText: (state, action: PayloadAction<string>) => {
      state.emptyText = action.payload;
    },
    resetOfferDetailsData: (state) => {
      state.offer_details_data = null;
    },
    resetOffersReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("offersListHome", `${ApiURL.OFFERS_LIST}`).fulfilled,
      (state, action) => {
        state.offers_list_home = action.payload.data;
        state.offers_list_home_loaded = true;
      }
    );
    builder.addCase(
      GET("offersListHome", `${ApiURL.OFFERS_LIST}`).rejected,
      (state) => {
        state.offers_list_home = [];
        state.offers_list_home_loaded = false;
      }
    );
    builder.addCase(
      GET("offersList", `${ApiURL.OFFERS_LIST}`).pending,
      (state, action) => {
        state.order_table_loader = true;
      }
    );
    builder.addCase(
      GET("offersList", `${ApiURL.OFFERS_LIST}`).fulfilled,
      (state, action) => {
        state.offers_details = action.payload.data;
        state.page_length = action.payload?.meta?.total_pages;
        state.total_items = action.payload?.meta?.total_count;
        state.order_table_loader = false;
      }
    );
    builder.addCase(
      GET("offersList", `${ApiURL.OFFERS_LIST}`).rejected,
      (state) => {
        state.order_table_loader = false;
        state.offers_details = [];
      }
    );
    builder.addCase(
      GET("gradesList", `${ApiURL.GRADE_FILTER_LIST}`).pending,
      (state, action) => {}
    );
    builder.addCase(
      GET("gradesList", `${ApiURL.GRADE_FILTER_LIST}`).fulfilled,
      (state, action) => {
        state.gradesList = action.payload.data;
      }
    );
    builder.addCase(
      GET("gradesList", `${ApiURL.GRADE_FILTER_LIST}`).rejected,
      (state) => {
        state.gradesList = [];
      }
    );
    builder.addCase(
      GET("locationList", `${ApiURL.LOCATION_FILTER_LIST}`).pending,
      (state, action) => {}
    );
    builder.addCase(
      GET("locationList", `${ApiURL.LOCATION_FILTER_LIST}`).fulfilled,
      (state, action) => {
        state.locationList = action.payload.data.map((el: string) => {
          return { location_id: el, location_name: el };
        });
      }
    );
    builder.addCase(
      GET("locationList", `${ApiURL.LOCATION_FILTER_LIST}`).rejected,
      (state) => {
        state.locationList = [];
      }
    );
    builder.addCase(
      GET("offerDetailsData", `${ApiURL.OFFERS_LIST}`).pending,
      (state, action) => {
        state.offer_details_loader = true;
      }
    );
    builder.addCase(
      GET("offerDetailsData", `${ApiURL.OFFERS_LIST}`).fulfilled,
      (state, action) => {
        state.offer_details_data = action.payload.data;
        state.unit = action.payload.data?.grade?.unit;
        state.offer_details_loader = false;
      }
    );
    builder.addCase(
      GET("offerDetailsData", `${ApiURL.OFFERS_LIST}`).rejected,
      (state, action) => {
        state.offer_details_data = null;
        state.offer_details_loader = false;
      }
    );
    builder.addCase(
      GET("cityList", `${ApiURL.PORTS_LIST}`).pending,
      (state, action) => {}
    );
    builder.addCase(
      GET("cityList", `${ApiURL.PORTS_LIST}`).fulfilled,
      (state, action) => {
        state.cityList = action.payload.data;
      }
    );
    builder.addCase(
      GET("cityList", `${ApiURL.PORTS_LIST}`).rejected,
      (state) => {
        state.cityList = [];
      }
    );
    builder.addCase(
      GET("destinationList", `${ApiURL.DESTINATION_LIST}`).pending,
      (state, action) => {}
    );
    builder.addCase(
      GET("destinationList", `${ApiURL.DESTINATION_LIST}`).fulfilled,
      (state, action) => {
        state.destinationList = action.payload.data;
      }
    );
    builder.addCase(
      GET("destinationList", `${ApiURL.DESTINATION_LIST}`).rejected,
      (state) => {
        state.destinationList = [];
      }
    );
    builder.addCase(GET("offerstatusFilter", "").fulfilled, (state, action) => {
      state.offer_status_options = action.payload.data;
    });
    builder.addCase(GET("offerstatusFilter", "").rejected, (state) => {
      state.offer_status_options = [];
    });

    builder.addCase(GET("makeyoursale/final-submit", "").pending, (state) => {
      state.offer_details_loader = true;
    });
    builder.addCase(GET("makeyoursale/final-submit", "").rejected, (state) => {
      state.offer_details_loader = false;
    });
  },
});

export default OffersSlice.reducer;
export const {
  setFilterBtnActiveSt,
  setFilterIconActiveSt,
  setFiltersModalSt,
  updateOrderFilterQuery,
  updateOfferFilterSelection,
  setSearchKeyword,
  updateOfferSearch,
  updateOfferPagination,
  setDuplicateOfferSt,
  updateOfferFilterQuery,
  setOfferSubmitted,
  setUploadedImageList,
  setSortOptions,
  setOfferFilterCount,
  setEmptyText,
  resetOfferDetailsData,
  resetOffersReducer,
  updateOfferDetailsLoader,
} = OffersSlice.actions;
