import { Button, Input } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { setCancelOfferModalSt } from "../../../../store/features/OffersActions.action";
import { useAppDispatch } from "../../../../hooks/reduxHooks";
import { GET, PUT } from "../../../../services/api";
import { ApiURL } from "../../../../utils/constant";
import { updateToggleLoader } from "../../../../store/features/Common.slice";

const CancelOfferFormWrapper = styled.div`
  .cancel-offer-description {
    font-size: 1.4rem;
    margin-top: 1.2rem;
  }
  .cancel-offer-textarea {
    margin: 1.5rem 0;
    padding: 1.5rem;
  }
  .primary-button {
    box-shadow: none;
    font-family: GothamMedium;
    width: 18rem;
    height: 4.4rem;
    float: right;
    margin-top: 0.5rem;
    @media (max-width: 769px) {
      width: 100%;
    }
  }
`;

const CancelOfferModalForm = (props: { offer_id: number | undefined }) => {
  const dispatch = useAppDispatch();
  const { TextArea } = Input;
  const [cancelMessage, setCancelMessage] = useState("");

  const cancelOffer = () => {
    dispatch(updateToggleLoader(true));
    dispatch(setCancelOfferModalSt());
    dispatch(
      PUT("cancelOffer", `${ApiURL.OFFERS_LIST}/${props.offer_id}/canceled`, {
        end_note: cancelMessage,
      })()
    ).then(() => {
      dispatch(
        GET("offerDetailsData", `${ApiURL.OFFERS_LIST}/${props.offer_id}`)()
      ).then(() => {
        dispatch(updateToggleLoader(false));
      });
    });
  };

  return (
    <CancelOfferFormWrapper>
      <div className="cancel-offer-description">
        Are you sure that you want to cancel Price Request? You will not be able
        to make it active again. Please clarify the reason of cancellation
      </div>
      <TextArea
        className="cancel-offer-textarea"
        rows={4}
        placeholder="Please enter your message"
        onChange={(e) => setCancelMessage(e.target.value)}
      />
      <Button
        type="primary"
        className="primary-button"
        htmlType="submit"
        onClick={cancelOffer}
        disabled={!!!cancelMessage}
      >
        Cancel Price Request
      </Button>
    </CancelOfferFormWrapper>
  );
};

export default CancelOfferModalForm;
