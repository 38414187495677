import styled from "styled-components";

const NotesContainer = styled.div`
  p {
    color: var(--NEUTRAL-4);
  }
  h4 {
    font-size: 1.6rem;
    padding: 2rem 0 1rem 0;
    font-family: "GothamMedium";
    font-style: normal;
    font-weight: 350;
    line-height: 150%;
    color: var(--NEUTRAL-4);
  }
  hr {
    background-color: var(--NEUTRAL-2);
    height: 1px;
    border: none;
  }
  .notes {
    padding: 1.6rem 0;
    font-size: 1.4rem;
  }
`;

const OffersNotes = (props: { notes: string | null | undefined }) => {
  return (
    <NotesContainer>
      <h4>Notes</h4>
      <hr />
      <p className="notes">{props.notes || "N/A"}</p>
    </NotesContainer>
  );
};

export default OffersNotes;
