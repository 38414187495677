import { useState } from "react";

export const useLocalStorage = () => {
  const [value, setValue] = useState<any>(null);

  const setItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
    setValue(value);
  };

  const getItem = (key: string) => {
    const value = localStorage.getItem(key);
    setValue(value ? JSON.parse(value) : null);
    return value;
  };

  const removeItem = (key: string) => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return { value, setItem, getItem, removeItem };
};
