import React from "react";
import Modal from "antd/es/modal";
import { CrossIcon, LeftArrowIcon, RightArrowIcon } from "../Icons/Icons";
import styled from "styled-components";
import { allowedVideoFormat } from "../../utils/constant";
import { forwardRef, useEffect, useRef, useState } from "react";
import "../../pages/Messages/MessagesChat/MessageChat.css";

export const AttachmentModalWrapper = styled(Modal)`
  max-width: 75% !important;
  .attachment-placeholder {
    overflow-y: hidden;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  img.attachment-placeholder {
    min-width: 20rem;
    min-height: 10rem;
    width: max-content !important;
    max-height: 80vh;
  }
  .ant-modal {
    width: max-content !important;
  }
  .ant-modal-body {
    padding: 0;
  }
  .modal-close {
    position: absolute;
    top: -3rem;
    right: 0;
  }
  .modal-close svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .modal-close path {
    stroke: var(--WHITE);
  }

  .ant-modal-content {
    padding: 0;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
  video {
    width: 100%;
    object-fit: contain;
  }

  .ant-modal-body {
    background-color: transparent;
    line-height: 0;
    overflow: hidden;
  }
  .prev,
  .next {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--WHITE);
    display: grid;
    place-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    svg {
      path {
        stroke: var(--NEUTRAL-4);
      }
    }
  }
  .prev {
    left: -4rem;
  }
  .next {
    right: -4rem;
  }
  .list-count {
    width: 10rem;
    height: 3rem;
    color: var(--WHITE);
    position: absolute;
    bottom: -5rem;
    left: 50%;
    transform: translateX(-50%);
    font-family: Gotham;
    font-weight: 325;
    font-size: 1.4rem;
    span {
      font-family: GothamMedium;
      font-weight: 350;
    }
  }
`;

type ViewModal = {
  src: string;
  showModal: boolean;
  setShowModal: Function;
  type?: string;
  srcList?: string[];
  defaultActive?: number;
};

const RenderVideo = React.memo(
  forwardRef((props: { selectSrc: string }, videoRef: any) => {
    const { selectSrc = "" } = props;
    return (
      <video
        ref={videoRef}
        autoPlay
        key={selectSrc}
        controls
        muted
        className="attachment-placeholder"
      >
        {allowedVideoFormat.map((el) => (
          <source src={selectSrc} key={el} type={el} />
        ))}
        Sorry, your browser doesn't support videos.
      </video>
    );
  })
);

export const AttachmentViewModal = ({
  src = "",
  showModal = false,
  setShowModal,
  type = "image",
  srcList = [],
  defaultActive = 0,
}: ViewModal) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [activeImage, setActiveImage] = useState(0);
  const [selectSrc, setSelectSrc] = useState("");
  const showPrev = srcList.length > 0 && activeImage !== 0;
  const showNext = srcList.length > 0 && activeImage !== srcList.length - 1;
  const showCount = srcList.length > 1;

  const onClose = () => {
    setActiveImage(0);
    setShowModal(false);
    videoRef.current && videoRef.current.pause();
    setSelectSrc("");
  };
  const onClicknext = () => {
    setActiveImage((prev) => prev + 1);
  };

  const onClickprev = () => {
    setActiveImage((prev) => prev - 1);
  };

  useEffect(() => {
    setActiveImage(defaultActive);
  }, [defaultActive]);
  useEffect(() => {
    let selectsrc = src;
    if (srcList.length > 0) selectsrc = srcList[activeImage];
    setSelectSrc(selectsrc);
  }, [activeImage, src, srcList]);
  return (
    <AttachmentModalWrapper
      closable={false}
      width={"1000"}
      className="chat-image-modal"
      open={showModal}
      footer={null}
    >
      <div onClick={onClose} className="modal-close">
        <CrossIcon />
      </div>
      {showPrev && (
        <div className="prev" onClick={onClickprev}>
          <LeftArrowIcon />
        </div>
      )}
      {showNext && (
        <div className="next" onClick={onClicknext}>
          <RightArrowIcon />
        </div>
      )}
      {showCount && (
        <p className="list-count">
          <span>{activeImage + 1}</span> out of <span>{srcList.length}</span>
        </p>
      )}

      {type === "image" && (
        <img
          key={selectSrc}
          className="attachment-placeholder"
          src={selectSrc}
          alt=""
        />
      )}
      {type === "video" && <RenderVideo ref={videoRef} selectSrc={selectSrc} />}
    </AttachmentModalWrapper>
  );
};
