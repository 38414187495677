import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  offer_id: number;
  cancelOfferModalSt: boolean;
  declineOfferModalSt: boolean;
  open_duplicate_sale: boolean;
  show_approved_popup_detail: boolean;
} = {
  offer_id: 0,
  cancelOfferModalSt: false,
  declineOfferModalSt: false,
  open_duplicate_sale: false,
  show_approved_popup_detail: false,
};

export const OffersActionsSlice = createSlice({
  name: "offers-actions",
  initialState,
  reducers: {
    setCancelOfferModalSt: (state) => {
      state.cancelOfferModalSt = !state.cancelOfferModalSt;
    },
    setDeclineOfferModalSt: (state) => {
      state.declineOfferModalSt = !state.declineOfferModalSt;
    },
    updateShowApprovedDetails: (state, action) => {
      state.show_approved_popup_detail = action.payload;
    },
    updateShowDuplicateSale: (state, action) => {
      state.open_duplicate_sale = action.payload;
    },
    resetOffersActionsReducer: () => initialState,
  },
});

export default OffersActionsSlice.reducer;
export const {
  setCancelOfferModalSt,
  setDeclineOfferModalSt,
  resetOffersActionsReducer,
  updateShowApprovedDetails,
  updateShowDuplicateSale,
} = OffersActionsSlice.actions;
