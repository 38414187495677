import { updateDialogList } from "../../store/features/Messages.slice";
import store from "../../store/store";
import { QB } from "../QuickBlox_initiation";

const QB_dialogListing = () => {
  let listparams = {
    sort_desc: "last_message_date_sent",
  };
  QB.chat.dialog.list(listparams, function (error: any, dialogs: any) {
    store.dispatch(updateDialogList(dialogs.items));
  });
};

export default QB_dialogListing;
